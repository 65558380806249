var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingTop: _vm.component.paddingTop + 'px',
    paddingLeft: _vm.component.paddingLeft + 'px',
    paddingRight: _vm.component.paddingRight + 'px',
    paddingBottom: _vm.component.paddingBottom + 'px',
    textAlign: _vm.component.alignment,
  })},[_c('div',{attrs:{"id":'cancel-subscription-component-' + _vm.component.id},domProps:{"innerHTML":_vm._s(_vm.component.tinymce)}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.component.company)}}),_c('p',{style:({ textAlign: _vm.component.alignment })},[_c('a',{style:({
        color: _vm.component.urlColor,
        class: 'temp-disable-link-action',
        href: _vm.component.redirectTo1,
        target: '_blank',
        contenteditable: 'false',
      })},[_vm._v(" "+_vm._s(_vm.component.text1)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }