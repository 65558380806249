import InlineEditor from '../../../inline-properties/InlineEditor.vue'

export default {
  props: {
    component: { type: Object },
    model: { type: String },
  },
  name: 'HtmlComponent',
  components: { InlineEditor },
}
