
export default {
  name: 'ContactsFileUploadResult',
  props: ['fileErrors', 'skipHeaderDisabled', 'campaign', 'preview', 'contactsFileUploadResponse'],
  data () {
    return {
      maxShownErrorLines: 0,
      errorTypesCounters: {},
    }
  },
  methods: {
    changeFileOptions (from) {
      this.$emit('changeFileOptions', from)
    },
    showMoreErrors () {
      this.maxShownErrorLines += 50
      if (this.maxShownErrorLines > this.preview.errors.length) {
        this.maxShownErrorLines = this.preview.errors.length
      }
    },
    getExternalIdColumnItems (length) {
      const items = []
      for (var i = 0; i < length; i++) {
        items.push({ text: '' + (i + 1), value: '' + i })
      }
      return items
    },
  },
  computed: {
    totalColumns: function () {
      let higher = this.contactsFileUploadResponse.filePreview[0].length
      this.contactsFileUploadResponse.filePreview.forEach(preview => {
        if (preview.length > higher) {
          higher = preview.length
        }
      })
      return higher
    },

    previewTotalRecipientsIsSet: function () {
      return typeof this.preview.totalRecipients === 'number'
    },
    dateColumns: function () {
      const fileColumns = []
      for (const index in this.contactsFileUploadResponse.guessedMap.date) {
        fileColumns.push({ value: '' + (this.contactsFileUploadResponse.guessedMap.date[index] - 1), text: this.contactsFileUploadResponse.guessedMap.date[index] })
      }
      return fileColumns
    },
    fileColumns: function () {
      const fileColumns = []
      for (let i = 0; i < this.totalColumns; i++) {
        fileColumns.push({ value: i, text: 'COL ' + (i + 1) })
      }
      return fileColumns
    },
    hasErrors: function () {
      return !!this.preview.errors?.length
    },
    errorsTxt: function () {
      let errors = ''
      for (let i = 0; i < this.maxShownErrorLines; i++) {
        errors += this.$t('Línea') + ' ' + this.preview.errors[i].line + ' - ' + this.$errorMessages.getMessage(this.preview.errors[i].code, this.preview.errors[i].value) + '<br>'
      }
      return errors
    },
    errorTotal: function () {
      return `Mostrando ${ this.maxShownErrorLines } de ${ this.preview.errors.length }`
    },
    canSkipHeaders: function () {
      const nlines = this.contactsFileUploadResponse.filePreview?.length || 0
      const nheaders = this.contactsFileUploadResponse.headers?.length || 0
      return nlines > 1 || (nlines === 1 && nheaders > 0)
    },
    hasDuplicatedPrimaryData: function () {
      return this.preview.errors.reduce((prev, nextError) => {
        return prev || ['parser.duplicatePhone'].includes(nextError.code)
      }, false)
    },
  },
  watch: {
    preview: function (newPreview, oldPreview) {
      if (this.preview) {
        this.maxShownErrorLines = this.preview.errors?.length <= 5 ? this.preview.errors.length : 5
      }
    },
  },
}
