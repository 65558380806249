import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent.js'
import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import { FontTypes } from '../enums/FontTypes.js'
import $t from '../../../../util/Translator.js'

export class Button extends GeneralComponent {
  backgroundColor = '#dadbdd';
  color = '#3F4254';
  text = 'Clic aquí';
  url = 'https://example.com';
  action = 'link';
  fileId = ''
  fileUrl = ''
  fileName = ''
  alignment = AlignmentComponent.Center;
  font = FontTypes.Helvetica;
  fontSize = 16;
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;
  fullWidth = false;
  tinymce = ''

  constructor () {
    super(TypeComponent.Button)
    this.text = $t('Clic aquí')
  }

  getStructure () {
    return this
  }
}
