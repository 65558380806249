import MenuOptions from './MenuOptions/MenuOptions.vue'
import SynthesisOptions from './SynthesisOptions/SynthesisOptions.vue'
import TextToSpeechSource from './TextToSpeechSource/TextToSpeechSource.vue'
import SaveBar from '@/components/Template/SaveBar/SaveBar.vue'
import HeaderBar from '@/components/Template/HeaderBar/HeaderBar.vue'
import VoicePreview from './VoicePreview/VoicePreview.vue'
import PhonesWithPrefix from '@/components/PhonesWithPrefix/PhonesWithPrefix.vue'
import { PhonesWithPrefix as PhonesWithPrefixClass } from '@/models/PhonesWithPrefix'
import { SynthesisOptions as SynthesisOptionsClass } from '@/models/voice/SynthesisOptions'
import { Menu } from '@/models/voice/Menu'
import { MenuOption } from '@/models/voice/MenuOption'
import { MenuOptionData } from '@/models/voice/MenuOptionData'
import CampaignVoiceService from '@/services/campaign-voice.service'
import EventBus from '@/util/EventBus.js'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import { MenuOptionType } from '../../../models/voice/MenuOptionType'
import AiVoiceAssistant from '@/components/AiAssistant/AiVoiceAssistant.vue'
import AiVoiceSmsAssistant from '@/components/AiAssistant/AiVoiceSmsAssistant.vue'

export default {
  name: 'VoiceTemplate',
  props: [
    'showSaveBar',
    'editorMode',
    'componentsData',
    'template',
    'saveBarProperties',
    'fields',
    'campaign',
    'isTemplate',
    'smsPreview',
    'menu',
  ],
  components: { SaveBar, HeaderBar, MenuOptions, TextToSpeechSource, PhonesWithPrefix, SynthesisOptions, VoicePreview, VideoPlayer, AiVoiceAssistant, AiVoiceSmsAssistant },
  // eslint-disable-next-line max-lines-per-function
  data: function () {
    return {
      subMenuHelper: new Menu(),
      hasMenuOption: false,
      speechRetries: 0,
      showSubMenuComponent: false,
      phonesWithPrefix: new PhonesWithPrefixClass(),
      synthesisOptions: new SynthesisOptionsClass(),
      initialLocution: new MenuOptionData(),
      initialLocutionSectionHelper: new MenuOptionData(),
      preview: '',
      renamedComponentsData: this.componentsData,
      loadingPreview: true,
      editorModeSaving: false,
      user: null,
      ready: false,
      panel: [],
      showPreviewDialog: false,
      fieldsIn: this.fields ? this.fields : {
        availableFields: {},
        currentFieldsMessage: [],
        currentUrlFieldsMessage: [],
      },
      panelErrorOrOmission: [0],
      selectedSection: this.menu,
      sectionItems: { options: [this.menu] },
      tempMenuOptions: undefined,
      sectionMenuHelper: new Menu(),
      sectionReady: true,
      dialogCreateSection: false,
      dialogConfirmRemoveSection: false,
      dialogLinkedSection: false,
      tempLinkedGoToOptions: [],
    }
  },
  validations: {
  },
  computed: {
    errorOrOmissionOptions: function () {
      return [
        { value: 'Play', text: this.$t('Reproducir audio').toString() },
        { value: 'CallTransfer', text: this.$t('Transferir llamada').toString() },
      ]
    },
  },
  methods: {
    preRemoveSection () {
      const sectionId = this.selectedSection.id
      const menuOptions = []
      this.tempLinkedGoToOptions = []
      this.sectionItems.options.forEach(section => {
        section.menuOptions.forEach(option => {
          if (option.goToSection === sectionId && option.typeId === 'GetDataWhilePlaying') menuOptions.push({ sectionName: section.name, ...option })
        })
      })

      this.tempLinkedGoToOptions = menuOptions
      if (menuOptions.length > 0) {
        this.dialogLinkedSection = true
      } else {
        this.dialogConfirmRemoveSection = true
      }
    },

    setSectionItemsOptions () {
      this.sectionItems.options = [this.menu, ...this.menu.sections]
    },

    setSectionMenuHelper () {
      const index = this.sectionItems.options.findIndex(item => item.id === this.selectedSection.id)
      this.sectionMenuHelper = this.sectionItems.options[index]
      this.initialLocutionSectionHelper.setData(this.sectionMenuHelper)
      this.setReadySection()
    },

    getNewSectionId () {
      const sectionsLenght = this.menu.sections.length
      return sectionsLenght ? 1001 + sectionsLenght : 1001
    },

    createNewSection (tempSectionName) {
      const sectionMenuHelper = new Menu()
      sectionMenuHelper.id = this.getNewSectionId()
      sectionMenuHelper.name = tempSectionName
      sectionMenuHelper.language = this.synthesisOptions.language
      sectionMenuHelper.gender = this.synthesisOptions.gender
      this.saveSection(sectionMenuHelper)
    },

    cancelNewSection () {
      this.sectionMenuHelper = this.selectedSection
      this.initialLocutionSectionHelper.setData(this.sectionMenuHelper)
      this.setReadySection()
    },

    removeSection () {
      const index = this.menu.sections.findIndex(item => item.id === this.sectionMenuHelper.id)
      this.menu.sections.splice(index, 1)
      this.sectionItems.options.splice(index + 1, 1)
      this.selectedSection = this.sectionItems.options[0]
      this.sectionMenuHelper = this.sectionItems.options[0]
      this.dialogConfirmRemoveSection = false
    },

    saveSection (sectionMenuHelper) {
      sectionMenuHelper = sectionMenuHelper || this.sectionMenuHelper
      const subMenuSectionHelperCopy = JSON.parse(JSON.stringify(sectionMenuHelper))
      const index = this.menu.sections.findIndex(item => item.id === sectionMenuHelper.id)
      if (index !== -1) {
        this.menu.sections[index] = subMenuSectionHelperCopy
        this.sectionItems.options[index + 1] = subMenuSectionHelperCopy
      } else {
        this.menu.sections.push(subMenuSectionHelperCopy)
        this.sectionItems.options.push(subMenuSectionHelperCopy)
      }
    },
    setReadySection () {
      this.sectionReady = false
      setTimeout(() => {
         this.sectionReady = true
      })
    },
    setInvalidElements (invalid) {
      this.saveBarProperties.invalid = invalid
    },
    setShowSubmenuComponent (value) {
      this.showSubMenuComponent = value
    },
    changeTemplateName () {
      this.template.name = this.saveBarProperties.templateName
    },
    updateErrorMenuOptionDataPhonesWithPrefix (phonesWithPrefix) {
      this.menu.menuOptions[0].data.setDataFromPhonesWithPrefix(phonesWithPrefix)
    },
    showPreview () {
      this.loadingPreview = true
      this.template.content = this.prepareMenu()
      this.showPreviewDialog = true
      setTimeout(() => {
        this.loadingPreview = false
      // eslint-disable-next-line no-magic-numbers
      }, 300)
    },
    async save () {
      if (this.saveBarProperties.invalid) {
        return
      }

      if (this.editorMode) {
        this.emitMenuToSave()
        return
      }
      const menuToSave = this.prepareMenu()
      this.template.content = JSON.stringify(menuToSave)
      CampaignVoiceService.saveTemplate({ template: this.template })
        .then((response) => {
          console.log(response)
          if (response.error === 1) {
            console.log(response.message)
            EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error en su solicitud') + '. ' + this.$t('Por favor , inténtelo más tarde'))
          } else {
            if (this.template.id) {
              EventBus.$emit('showAlert', 'success', this.$t('La plantilla se actualizó correctamente.'))
            } else {
              EventBus.$emit('showAlert', 'success', this.$t('La plantilla se creó correctamente.'))
            }
            this.template.id = response.id
          }
        })
      this.saveBarProperties.setSavedLoader()
    },
    prepareMenu () {
      const menuToSave = { ...this.menu }
      if (!this.hasMenuOption) menuToSave.menuOptions = []
      return menuToSave
    },
    emitMenuToSave () {
      this.$emit('menu-to-save', this.prepareMenu())
    },
    setMenuFromTemplate () {
      const content = this.template.content
      if (Object.keys(content).length !== 0) {
        this.menu.setData(content)
        this.initialLocution.setData(content)
        if (this.menu.menuOptions.length === 0) {
          this.menu.menuOptions.push(MenuOption.getErrorOption())
        }
        this.synthesisOptions.language = this.menu.language
        this.synthesisOptions.gender = this.menu.gender
        this.phonesWithPrefix.setDataFromMenuOptionData(this.menu.menuOptions[0].data)
      }
    },
    updateMenuData () {
      this.menu.message = this.initialLocution.message
      this.menu.url = this.initialLocution.url
      this.menu.type = this.initialLocution.type
    },

    updateSectionMenuData () {
      this.sectionMenuHelper.message = this.initialLocutionSectionHelper.message
      this.sectionMenuHelper.url = this.initialLocutionSectionHelper.url
      this.sectionMenuHelper.type = this.initialLocutionSectionHelper.type
      this.sectionMenuHelper.language = this.initialLocutionSectionHelper.language
      this.sectionMenuHelper.gender = this.initialLocutionSectionHelper.gender
    },

    updateLanguageOptions () {
      this.initialLocution.language = this.synthesisOptions.language
      this.initialLocution.gender = this.synthesisOptions.gender
      this.menu.language = this.synthesisOptions.language
      this.menu.gender = this.synthesisOptions.gender
      this.menu.languageWithGender = this.synthesisOptions.languageWithGender ? this.synthesisOptions.languageWithGender.text : ''
      this.menu.setLanguageToMenuOptions()
      this.menu.sections.forEach(section => {
        section.language = this.synthesisOptions.language
        section.gender = this.synthesisOptions.gender
        section.languageWithGender = this.synthesisOptions.languageWithGender ? this.synthesisOptions.languageWithGender.text : ''
        this.setLanguageToSectionsMenuOptions(section)
      })
      this.sectionMenuHelper.language = this.synthesisOptions.language
      this.sectionMenuHelper.gender = this.synthesisOptions.gender
      this.setLanguageToMenuOptions(this.sectionMenuHelper.menuOptions)
      this.sectionMenuHelper.sections.forEach(section => {
        section.language = this.synthesisOptions.language
        section.gender = this.synthesisOptions.gender
        section.languageWithGender = this.synthesisOptions.languageWithGender ? this.synthesisOptions.languageWithGender.text : ''
        this.setLanguageToSectionsMenuOptions(section)
      })
      this.$forceUpdate()
    },
    setLanguageToMenuOptions (menuOptions) {
      menuOptions.forEach(menuOption => {
        if (menuOption.typeId !== MenuOptionType.REPEAT_MESSAGE) {
          menuOption.data.language = this.synthesisOptions.language
          menuOption.data.gender = this.synthesisOptions.gender
        }

        if (menuOption.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
          menuOption.menu.language = this.synthesisOptions.language
          menuOption.menu.gender = this.synthesisOptions.gender
          menuOption.menu.setLanguageToMenuOptions()
        }
      })
    },
    setLanguageToSectionsMenuOptions (section) {
      section.menuOptions.forEach(menuOption => {
        if (menuOption.typeId !== MenuOptionType.REPEAT_MESSAGE) {
          menuOption.language = this.synthesisOptions.language
          menuOption.gender = this.synthesisOptions.gender
          menuOption.data.language = this.synthesisOptions.language
          menuOption.data.gender = this.synthesisOptions.gender
        }

        if (menuOption.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
          menuOption.menu.language = this.synthesisOptions.language
          menuOption.menu.gender = this.synthesisOptions.gender
          menuOption.menu.setLanguageToMenuOptions()
        }
      })
    },
    setCreationData () {
      this.user = this.$store.getters['auth/getUser']
      this.phonesWithPrefix.isoCode = this.user.isoCountry.toLowerCase() || this.user.isoTld.toLowerCase()
      this.saveBarProperties.templateName = this.template.name
      this.synthesisOptions.setLanguage(this.user.lang)
      this.setMenuFromTemplate()
      this.updateLanguageOptions()

      if (this.menu.menuOptions.length > 1) {
        this.panel = 0
      }

      this.setSectionItemsOptions()
      this.ready = true
    },
  },
  // eslint-disable-next-line max-lines-per-function
  created () {
    if (this.editorMode) {
      this.setCreationData()
      return
    }

    let data = {}
    if (this.$route.query.path) {
      data = { path: this.$route.query.path }
    }

    CampaignVoiceService.getCreateTemplateData(data).then(
      (response) => {
        this.renamedComponentsData = response.componentsData
        this.template.setData(response.template)
        this.template.content = JSON.parse(response.template.content)
        if (this.$route.query.duplicate) {
          this.template.id = null
          this.template.name += ' - ' + this.$t('Copia')
        }
        this.setCreationData()
      },
      () => { },
    )
  },
  watch: {
    panel: function () {
      this.hasMenuOption = this.panel === 0
    },
    initialLocution: {
      handler: function () {
        this.updateMenuData()
      },
      deep: true,
    },

    initialLocutionSectionHelper: {
      handler: function () {
        this.updateSectionMenuData()
      },
      deep: true,
    },

    synthesisOptions: {
      handler: function () {
        this.updateLanguageOptions()
      },
      deep: true,
    },
  },
  mounted () {
    const that = this
    EventBus.$on('emit-menu-to-save', function () {
      that.emitMenuToSave()
    })
  },
}
