import { ChannelType } from './ChannelType'
import { Channel } from './Channel'
import { AvailableTime } from './voice/AvailableTime'

export class ChannelVoice extends Channel {
  callRetries
  hasCostLimit
  costLimit
  amd
  availableTimes = []
  menu
  callers = {}
  bookmark = 0
  type = ChannelType.VOICE_INTERACTIVE_ID
  // eslint-disable-next-line no-magic-numbers
  static defaultCostLimit = -1;

  constructor () {
    super()
    this.callRetries = 0
    this.availableTimes = null
    this.menu = ''
    this.callers = null
    this.costLimit = ChannelVoice.defaultCostLimit
    this.hasCostLimit = false
    this.amd = false
  }

  setData (channel) {
    if (!channel) { return }
    this.id = channel.id ? channel.id : this.id
    this.callRetries = channel.call_retries
    this.costLimit = channel.cost_limit ? parseFloat(channel.cost_limit) : ChannelVoice.defaultCostLimit
    this.hasCostLimit = this.costLimit !== ChannelVoice.defaultCostLimit
    this.amd = channel.amd || false
    this.menu = channel.menu
    this.availableTimes = channel.availableTimes ? AvailableTime.parseAvailableTimes(JSON.parse(channel.availableTimes)) : null
    this.callers = channel.callers ? JSON.parse(channel.callers) : this.callers
    this.bookmark = parseInt(channel.bookmark)
  }
}
