import { Country } from '@/util/Country.js'
import AccountService from '@/services/account.service'

export default {
  name: 'ModalResumeSend',
  props: {
    toogle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      defaul: false,
    },
    channelType: {
      require: false,
      type: Number,
    },
    campaign: {
      require: false,
      type: Object,
    },
    preview: {
      require: false,
      type: Object,
    },
    currentSmsIn: {
      require: false,
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  data () {
    return {
      Country: Country,
      balanceAlert: {},
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    emitAccepted () {
      this.$emit('accepted')
    },
    emitSaveAndBalance () {
      this.$emit('saveAndBalance')
    },
    setBalanceAlert () {
      AccountService.getBalance().then((data) => {
        this.balanceAlert = data
      })
    },
  },
  computed: {
    hasCustomFields () {
      return this.currentSmsIn ? this.currentSmsIn.hasCustomFields : false
    },
    balance () {
      return this.$store.state.balance
    },
    calculatedBalance () {
      return this.balance.amount
    },
    hasNotEnoughBalance () {
      return this.campaign.campaignCosts.totalCost > this.calculatedBalance
    },
    hasNotEnoughBalanceClass () {
      return this.campaign.campaignCosts.totalCost > this.calculatedBalance ? 'not-enough-balance' : ''
    },
    getFragmentedRecipients () {
      if (this.campaign.sendings[0].batchParts && this.campaign.sendings[0].totalRecipients) {
        return Math.ceil(this.campaign.sendings[0].totalRecipients / this.campaign.sendings[0].batchParts).toFixed(0)
      }
      return null
    },
    getShippingTotalHours () {
      const batchParts = this.campaign.sendings[0].totalRecipients === 1 ? 1 : this.campaign.sendings[0].batchParts
      const minutes = batchParts * this.campaign.sendings[0].batchMinutes
      return Math.floor(minutes / 60).toFixed(0)
    },
    getShippingTotalMinutes () {
      const batchParts = this.campaign.sendings[0].totalRecipients === 1 ? 1 : this.campaign.sendings[0].batchParts
      const minutes = batchParts * this.campaign.sendings[0].batchMinutes
      return Math.ceil(minutes - (this.getShippingTotalHours * 60)).toFixed(0)
    },
    tooManyShippingHours () {
      return this.getShippingTotalHours >= 48
    },
  },
  created () {
    this.setBalanceAlert()
  },
}
