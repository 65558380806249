export default {
  props: {
    form: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  name: 'CardActions',
  components: { },
  data: function () {
    return {
    }
  },
  watch: {
  },
  methods: {
    removeItem (item) {
      const index = this.form.sections[0].items.indexOf(item)
      this.form.sections[0].items.splice(index, 1)
    },
    duplicateItem (item) {
      const index = this.form.sections[0].items.lastIndexOf(item)
      this.form.sections[0].items.splice(index, 0, JSON.parse(JSON.stringify(item)))
    },
    forceRender (item) {
      const stash = item.question
      item.question = ' '
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
        item.question = stash
      })
    },
  },
  mounted () {
  },
  created () {
  },
}
