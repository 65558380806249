import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'
import { Image } from './Image'
import { Text } from './Text'
import { Button } from './Button'

export class Product extends GeneralComponent {
  imageComponent;
  textComponent = 'Texto de ejemplo';
  buttonComponent = 'Aceptar';
  backgroundColor = '#ffffff';
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;

  constructor () {
    super(TypeComponent.Product)
    this.imageComponent = new Image()
    this.textComponent = new Text()
    this.buttonComponent = new Button()
  }

  getStructure () {
    return {
      id: this.id,
      type: this.type,
      imageComponent: this.imageComponent.getStructure(),
      textComponent: this.textComponent.getStructure(),
      buttonComponent: this.buttonComponent.getStructure(),
      backgroundColor: this.backgroundColor,
      paddingTop: this.paddingTop,
      paddingLeft: this.paddingLeft,
      paddingRight: this.paddingRight,
      paddingBottom: this.paddingBottom,
    }
  }
}
