import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import { FontTypes } from '../enums/FontTypes.js'
import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent.js'
import $t from '../../../../util/Translator.js'

export class CancelSubscription extends GeneralComponent {
  text1 = 'Darse de baja'
  redirectExample='https://example.com'
  redirectTo1 = '[unsubscribe_link]'
  text2 = 'Gestionar preferencias'
  redirectTo2 = 'https://example.com'
  mainText = 'En cumplimiento con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:'
  mainTextFont = FontTypes.Helvetica
  mainTextFontSize = 12
  mainTextColor = '#3F4254'
  secondaryText = 'Empresa Demo 123456<br>Calle Ejemplo 99, 1ºA 12345 Madrid'
  secondaryTextFont = FontTypes.Helvetica
  secondaryTextFontSize = 12
  secondaryTextColor = '#3F4254'
  urlFont = FontTypes.Helvetica
  urlFontSize = 12
  urlColor = '#00a4bd'
  alignment = AlignmentComponent.Center
  paddingTop = 10
  paddingLeft = 20
  paddingRight = 20
  paddingBottom = 10
  language = 'es'
  company = null
  tinymce = ''

  constructor (isCountryEu, legalText = null) {
    super(TypeComponent.CancelSubscription)
    this.text1 = $t('Darse de baja')
    this.text2 = $t('Gestionar preferencias')
    this.mainText = legalText || this.getMainText(isCountryEu)
    this.secondaryText = this.company || $t('Empresa Demo 123456<br>Calle Ejemplo 99, 1ºA 12345 Madrid')
  }

  getStructure () {
    return this
  }

  getMainText (isCountryEu) {
    if (isCountryEu) {
      return $t('En cumplimiento con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:')
    } else {
      return $t('Te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:')
    }
  }
}
