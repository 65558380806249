export class AutomaticConfig {
  frequency = 'daily'
  sendingDays = []
  filterPresent = true
  sendingTime = []
  maxCapping = 1
  cappingFrequency = 'daily'
  // { type: string, field: string, conditionsValues: Array<{ conditionVal: string, condition: string, description: string }> }
  conditions =
    {
      type: 'date',
      field: '',
      conditionsValues: [
        {
          conditionVal: '0',
          condition: 'delay',
          description: 'delay',
        },
        {
          conditionVal: 'm-d',
          condition: 'format',
          description: 'format',
        },
        {
          conditionVal: '',
          condition: 'timezone',
          description: '',
        },
      ],
    }

  constructor (data = null) {
    if (data) {
      this.frequency = data.frequency
      this.sendingDays = data.sendingDays
      this.filterPresent = true
      this.sendingTime = data.sendingTime
      this.maxCapping = data.capping_value
      this.cappingFrequency = data.capping_frequency
      this.conditions = data.conditions
    }
  }
}
