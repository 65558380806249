import { TypeComponent } from '../../enums/TypeComponent.js'
import Tile from '../tile/Tile.vue'
import DrawProperties from './draw-properties/DrawProperties.vue'
import EventBus from '@/util/EventBus'
import { TypeTabs } from '../../enums/TypeTabs.js'
import { ContainerDistributionColumns } from '../../enums/ContainerDistributionColumns.js'

export default {
  name: 'ToolsMenu',
  components: {
    Tile, DrawProperties,
  },
  props: { landing: { type: Object } },
  data: function () {
    return {
      TypeTabs: TypeTabs,
      containerTiles: [],
      componentTiles: [],
    }
  },
  created: function () {
    this.initContainerTiles()
    this.initComponentTiles()
    this.setChangePropertyTabListener()
    EventBus.$on('select-template-editor-tab', this.setTabSelectedByName)
  },
  destroyed: function () {
    EventBus.$off('select-template-editor-tab', this.setTabSelectedByName)
  },
  methods: {
    // eslint-disable-next-line max-lines-per-function
    initContainerTiles () {
      this.containerTiles.push(
        {
          type: TypeComponent.Container,
          text: '1',
          icon: 'container-type-1',
          slots: 1,
        },
        {
          type: TypeComponent.Container,
          text: '2',
          icon: 'container-type-2',
          slots: 2,
        },
        {
          type: TypeComponent.Container,
          text: '3',
          icon: 'container-type-3',
          slots: 3,
        },
        {
          type: TypeComponent.Container,
          text: '1/3 : 2/3',
          icon: 'container-type-1-2',
          slots: 2,
          distribution: ContainerDistributionColumns.OneAndTwo,
        },
        {
          type: TypeComponent.Container,
          text: '2/3 : 1/3',
          icon: 'container-type-2-1',
          slots: 2,
          distribution: ContainerDistributionColumns.TwoAndOne,
        },
        {
          type: TypeComponent.Container,
          text: '4',
          icon: 'container-type-4',
          slots: 4,
        },
      )
    },
    // eslint-disable-next-line max-lines-per-function
    initComponentTiles () {
      this.componentTiles.push(
        {
          type: TypeComponent.Text,
          text: this.$t('Texto').toString(),
          icon: 'text',
        },
        {
          type: TypeComponent.Button,
          text: this.$t('Botón').toString(),
          icon: 'link',
        },
        {
          type: TypeComponent.Qr,
          text: 'QR',
          icon: 'qr',
        },
        {
          type: TypeComponent.CancelSubscription,
          text: this.$t('Cancelar servicio').toString(),
          icon: 'delete',
        },
        {
          type: TypeComponent.Image,
          text: this.$t('Imagen').toString(),
          icon: 'image',
        },
        {
          type: TypeComponent.SocialMedia,
          text: this.$t('Redes sociales').toString(),
          icon: 'social',
        },
        {
          type: TypeComponent.Product,
          text: this.$t('Producto').toString(),
          icon: 'producto',
        },
        {
          type: TypeComponent.Video,
          text: this.$t('Video').toString(),
          icon: 'video',
        },
        {
          type: TypeComponent.Gif,
          text: 'Gif',
          icon: 'gif',
        },
        {
          type: TypeComponent.Separator,
          text: this.$t('Separador').toString(),
          icon: 'separador',
        },
        {
          type: TypeComponent.Html,
          text: this.$t('Insertar html').toString(),
          icon: 'html',
        },
        {
          type: TypeComponent.OpenInBrowser,
          text: this.$t('Ver en navegador').toString(),
          icon: 'open-in-browser',
        },
      )
    },

    setChangePropertyTabListener () {
      EventBus.$on('showPropertiesTabEvent', (type = null, containerIndex = -1, slotIndex = -1, key = '') => {
        this.landing.editorHelper.componentSelected.type = type
        this.landing.editorHelper.componentSelected.containerIndex = containerIndex
        this.landing.editorHelper.componentSelected.slotIndex = slotIndex
        this.landing.editorHelper.componentSelected.key = key
        document.getElementById('properties-tab').click()
        this.componentClickAction(type)
      })
    },

    componentClickAction (type) {
      if (type === TypeComponent.Html) {
        EventBus.$emit('show-html-edit-key-' + this.landing.editorHelper.componentSelected.key)
      }
    },

    setTabSelected (tabId) {
      this.landing.editorHelper.tabSelected = tabId
    },
    setTabSelectedByName (tabName) {
      document.getElementById(tabName).click()
    },
  },
}
