var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingTop: _vm.component.paddingTop + 'px',
    paddingLeft: _vm.component.paddingLeft + 'px',
    paddingRight: _vm.component.paddingRight + 'px',
    paddingBottom: _vm.component.paddingBottom + 'px',
  })},[_c('p',{style:({
      textAlign: _vm.component.alignment,
    }),attrs:{"id":"icon-container"}},_vm._l((_vm.component.urls),function(socialMedia,index){return _c('span',{key:index},[(socialMedia.url)?_c('img',{staticClass:"icon-url",attrs:{"src":require('@/assets/img/icons/social-media/'+socialMedia.icon),"alt":"no-icon"}}):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }