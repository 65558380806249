import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'
import { ContainerDistributionColumns } from '../enums/ContainerDistributionColumns.js'

export class Container extends GeneralComponent {
  slots = [];
  backgroundColor;
  distribution;
  paddingTop;
  paddingBottom;
  showBackground = false;
  minHeight = 95;
  activeDropzone = false

  constructor (slots, distribution, backgroundColor = null, paddingTop = null, paddingBottom = null) {
    super(TypeComponent.Container)

    this.backgroundColor = backgroundColor ?? '#ffffffff'
    this.paddingTop = paddingTop ?? 0
    this.paddingBottom = paddingBottom ?? 0
    this.distribution = (distribution) || ContainerDistributionColumns.Equal
    for (let i = 0; i < slots; i++) {
      this.slots.push([])
    }
  }

  getStructure () {
    return this
  }

  duplicate (slotsContainer) {
    for (let slotIndex = 0; slotIndex < slotsContainer.length; slotIndex++) {
      const slot = JSON.parse(JSON.stringify(slotsContainer[slotIndex]))

      for (let i = 0; i < slot.length; i++) {
        slot[i].id = slot[i].id + Math.random()
      }

      this.slots.push(slot)
    }

    return this
  }
}
