export default {
  props: {
    saveBarProperties: {
      type: Object,
    },
    showAutoSave: {
      type: Boolean,
      default: true,
    },
    templateType: {
      type: String,
      default: 'landing',
      require: false,
    },
  },
  name: 'HeaderBar',
  components: {},
  data: function () {
    return {
      autoSaving: false,
    }
  },
  methods: {
    save () {
      if (this.saveBarProperties.autoSave) {
        this.saveBarProperties.autoSaveInterval = setInterval(() => {
          if (!this.saveBarProperties.invalid) {
            this.$emit('save')
            this.autosave()
          }
        }, this.saveBarProperties.autoSaveIntervalMiliseconds)
      } else {
        clearInterval(this.saveBarProperties.autoSaveInterval)
      }
    },
    changeName () {
      this.$emit('change-name')
    },
    autosave () {
      this.autoSaving = true
      setTimeout(() => {
        this.autoSaving = false
      }, 500)
    },
  },
  created () {
  },
  mounted () {
    this.save()
  },
}
