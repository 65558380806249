import { ChannelVoice } from '@/models/ChannelVoice'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'

export default {
  props: ['campaign'],
  name: 'CostLimit',
  components: { HelpText },
  data: function () {
    return {
      tempCostLimit: 5,
    }
  },
  watch: {
    costLimit: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.campaign.sendings[0].channel.hasCostLimit) {
          if (parseFloat(newValue) > parseFloat(this.userBalance)) {
            this.campaign.sendings[0].channel.costLimit = parseFloat(this.userBalance)
          }
          this.campaign.sendings[0].channel.costLimit = Math.abs(this.campaign.sendings[0].channel.costLimit)
        }
      })
    },
  },
  computed: {
    costLimit: function () {
      return parseFloat(this.campaign.sendings[0].channel.costLimit)
    },
    userBalance: function () {
      return parseFloat(Math.floor(this.$store.state.balance.amount * 100) / 100)
    },
  },
  methods: {
    changeCostLimit () {
      if (this.campaign.sendings[0].channel.hasCostLimit) {
        this.campaign.sendings[0].channel.costLimit = this.tempCostLimit
      } else {
        this.tempCostLimit = this.campaign.sendings[0].channel.costLimit
        this.campaign.sendings[0].channel.costLimit = ChannelVoice.defaultCostLimit
      }
    },
  },
  created () {
    if (this.campaign.sendings[0].channel.costLimit !== ChannelVoice.defaultCostLimit) {
      this.tempCostLimit = this.campaign.sendings[0].channel.costLimit
      this.campaign.sendings[0].channel.hasCostLimit = true
    }
  },
  mounted () {
  },
}
