import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import SizeProperty from '../../common/size-property/SizeProperty.vue'

export default {
  name: 'GifProperties',
  components: { MarginProperty, SizeProperty },
  props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
  },
  data: function () {
    return {
      gifResults: [],
      loadingPreview: true,
      component: { type: Object },
      search: null,
      apiKey: '6iuFxIOntQfV6xbF2sBQuu5hJihTU4Pr',
      url: 'https://api.giphy.com/v1/gifs/',
      limit: 9,
      offset: 0,
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
    this.getGiphy()
    this.addDefaultGif()
  },
  methods: {

    /**
     * getGiphy
     */
    getGiphy () {
      this.offset = 0
      const endpoints = this.setEndpoint()
      this.getGiphyData(endpoints)
    },

    async getGiphyViewMore () {
      this.offset = this.offset + this.limit
      const endpoints = this.setEndpoint()
      this.getGiphyData(endpoints)
    },

    /**
    * setEndpoint
    */
    setEndpoint () {
      const structure = `api_key=${this.apiKey}&limit=${this.limit}&rating=g&offset=${this.offset}`
      return this.search != null ? `search?q=${this.search}&` + structure : 'trending?' + structure
    },

    /**
    * getGiphyData
    */
    async getGiphyData (endpoints) {
      await fetch(this.url + endpoints, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then((data) => {
          this.gifResults = this.offset === 0 ? data.data : this.gifResults.concat(data.data)
        })
    },

    /**
     * addGif
     */
    addGif (url) {
      this.component.path = url
    },

    /**
     * addDefaultGif
     */
    addDefaultGif () {
      if (this.component.path === '') {
        this.component.path = 'https://media0.giphy.com/media/3o6gbbuLW76jkt8vIc/giphy.gif'
        this.component.width = 50
      }
    },
  },
}
