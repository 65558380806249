import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent.js'
import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import $t from '../../../../util/Translator.js'

export class Qr extends GeneralComponent {
  code = '12345678'
  colorCode = '#000000'
  publicCode = true
  img = ''
  title = 'Código'
  titleCode = true
  titleColor = '#000000'
  level = 'H'
  width = 50
  margin = '0'
  renderAs = 'svg'
  background = '#ffffff'
  foreground = '#000000'
  class = ''
  alignment = AlignmentComponent.Center
  paddingTop = 10
  paddingLeft = 20
  paddingRight = 20
  paddingBottom = 10
  typeCodeOption = [
    'C39',
    'C93',
    'S25',
    'I25',
    'C128',
    'EAN2',
    'EAN5',
    'EAN8',
    'EAN13',
    'UPCA',
    'UPCE',
    'MSI',
    'POSTNET',
    'PLANET',
    'RMS4CC',
    'KIX',
    'IMB',
    'CODABAR',
    'CODE11',
    'PHARMA',
    'PHARMA2T',
    'QRCODE',
    'PDF417',
    'DATAMATRIX',
  ]

  typeCode = 'QRCODE'
  manualCode = true

  constructor () {
    super(TypeComponent.Qr)
    this.code = 12345678
    this.title = $t('Código')
  }

  getStructure () {
    return this
  }
}
