import { Service } from './service'

const PATH = 'countries'

class CountryService extends Service {
  getAllCountries () {
    return this.get(PATH + '/all')
  }

  getCountries (params = {}) {
    return this.get(PATH + '/get-countries', params)
  }

  getUserCountries () {
    return this.get(PATH + '/user-countries')
  }
}

export default new CountryService()
