import { Preview } from './Preview'

export class VoicePreview extends Preview {
  totalByCountry = {}
  totalCalls = 0
  contactsPhone = []

  constructor (response) {
    super()
    if (response) {
      this.totalRecipients = response.totalRecipients
      this.sendingCost = response.sendingCost
      this.contactsPreview = response.contactsPreview
      this.contactsFieldsValue = response.contactsFieldsValue
      this.errors = response.errors
      this.errorsTxt = response.errorsTxt
      this.totalByCountry = response.totalByCountry
      this.totalCalls = response.totalCalls
      this.contactsPhone = response.contactsPhone
    }
  }
}
