export const TypeComponent = {
  CancelSubscription: 'cancel-subscription',
  Button: 'button',
  Qr: 'qr',
  Text: 'text',
  Container: 'container',
  Image: 'image',
  SocialMedia: 'social-media',
  Product: 'product',
  Video: 'video',
  Gif: 'gif',
  Separator: 'separator',
  Html: 'html',
  OpenInBrowser: 'open-in-browser',
}
