import Container from './canvas-components/container/Container.vue'
import { TypeComponent } from '../../enums/TypeComponent.js'
import { ComponentFactory } from '../../utils/ComponentFactory'
import EventBus from '@/util/EventBus.js'
import { TypeTabs } from '../../enums/TypeTabs.js'

export default {
  props: { landing: { type: Object, require: true }, reRenderLanding: { type: Number, default: 0 }, selectedMainContainerByDefault: { type: Boolean, default: false } },
  name: 'LandingEditorCanvas',
  components: { Container },
  data: function () {
    return {
      activeDropzone: false,
      TypeTabs: TypeTabs,
      typeBackgroundSelected: {
        color: 'color',
        image: 'image',
      },
      cancelBlocksInStructure: 0,
      cancelSlotsInStructure: 0,
      initUndoRedoManager: false,
      selectedMainContainerByDefaultPreview: false,
    }
  },
  watch: {
    'landing.structure.containers': {
      handler (newValue, oldValue) {
        this.cancelBlocksInStructure = newValue.reduce((prev, nextContainer) => {
          if (!this.initUndoRedoManager) {
            this.initUndoRedoManager = true
            setTimeout(() => {
              EventBus.$emit('undoRedoManager')
            }, 500)
          }
          return prev + nextContainer.slots.reduce((prev, nextSlot) => {
            return prev || nextSlot.reduce((prev, nextBlock) => {
              return (nextBlock.type === 'cancel-subscription' ? 1 : 0)
            }, 0)
          }, 0)
        }, 0)
        this.cancelSlotsInStructure = newValue.reduce((prev, nextContainer) => {
          return prev + nextContainer.slots.reduce((prev, nextSlot) => {
            return prev + nextSlot.reduce((prev, nextBlock) => {
              return (nextBlock.type === 'cancel-subscription' ? 1 : 0)
            }, 0)
          }, 0)
        }, 0)
      },
      deep: true,
    },
  },
  methods: {
    dragStartContainer (event, index) {
      const componentData = {
        type: 'container-move',
        index: index,
      }
      event.dataTransfer.setData('componentData', JSON.stringify(componentData))
    },
    containerDrop (event, index) {
      try {
        const containerData = JSON.parse(event.dataTransfer.getData('componentData'))
        if (containerData.type === 'container-move') {
          const containerToMove = this.landing.structure.containers[containerData.index]
          const containerDestination = this.landing.structure.containers[index]

          this.landing.structure.containers[index] = containerToMove
          this.landing.structure.containers[containerData.index] = containerDestination
          const movedContainer = this.landing.structure.containers[index]

          EventBus.$emit('showPropertiesTabEvent', movedContainer.type, index, -1, movedContainer.id)
        } else {
          // eslint-disable-next-line max-depth
          if (containerData.type === TypeComponent.Container) {
            const newContainer = ComponentFactory.create(containerData)
            let containerIndex = this.landing.structure.containers.length - 1
            // eslint-disable-next-line max-depth
            if (index === containerIndex) {
              this.landing.structure.containers.splice(containerIndex, 0, newContainer.getStructure())
            } else {
              containerIndex = index + 1
              this.landing.structure.containers.splice(containerIndex, 0, newContainer.getStructure())
            }
            EventBus.$emit('showPropertiesTabEvent', newContainer.type, containerIndex, -1, newContainer.id)
          }
        }
        EventBus.$emit('undoRedoManager')
      } catch (error) { }
    },

    viewProperties () {
      EventBus.$emit('showPropertiesTabEvent')
    },

    moveContainer (currentPosition, nextPosition) {
      this.landing.structure.containers.splice(nextPosition, 0, this.landing.structure.containers.splice(currentPosition, 1)[0])
      EventBus.$emit('undoRedoManager')
    },
    getbackgroundType () {
      if (this.landing.structure.backgroundType === this.typeBackgroundSelected.color) {
        return { backgroundColor: this.landing.structure.backgroundColor }
      }

      if (this.landing.structure.backgroundType === this.typeBackgroundSelected.image) {
        return {
          backgroundImage: 'url(' + this.landing.structure.backgroundPath + ')',
          backgroundPosition: this.landing.structure.backgroundPathPosition,
          backgroundSize: this.landing.structure.backgroundPathSize,
          backgroundRepeat: 'no-repeat',
        }
      }
    },
  },
  mounted () {
    this.selectedMainContainerByDefaultPreview = this.selectedMainContainerByDefault
  },
}

