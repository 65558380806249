import { FontTypes } from '../../../../enums/FontTypes.js'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import Editor from '@tinymce/tinymce-vue'
import getEnv from '@/util/env'
import { Languages } from '@/classes/Languages.js'
import $t from '@/util/Translator'
import TextEditor from '@/components/TextEditor/TextEditor.vue'

export default {
  name: 'OpenInBrowserProperties',
  components: {
    MarginProperty,
    Editor,
    TextEditor,
  },
  props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
  },
  data: function () {
    return {
      FontTypes: FontTypes,
      component: { type: Object },
      editorApiKey: getEnv('VUE_APP_TINY_API_KEY'),
      languages: Languages.languages.map(l => ({ value: l.iso, text: l.tooltip })),
      selectedLanguage: null,
      init: {
        selector: 'textarea#full-featured-non-premium',
        plugins: 'fullscreen wordcount quickbars emoticons autolink link noneditable',
        toolbar: ['bold italic underline emoticons fontselect fontsizeselect forecolor backcolor alignleft aligncenter alignright alignfull numlist bullist outdent indent undo redo fullscreen link'],
        toolbar_sticky: true,
        branding: false,
        image_advtab: false,
        importcss_append: true,
        height: 300,
        contextmenu: false,
        lineheight_formats: '1 1.2 1.5 2 2.3 2.5 3',
        quickbars_insert_toolbar: 'alignleft aligncenter alignright alignfull | outdent indent | link',
        quickbars_selection_toolbar: 'bold italic | blockquote fontsizeselect',
        toolbar_mode: 'floating',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px; color: #3F4254; }',
        menubar: 'custom',
        nondeletable: ['unsubscribe-link'],
      },
      renderEditor: false,
    }
  },
  methods: {
    changeUrlAndLanguage () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.component.tinymce, 'text/html')
      const links = htmlDoc.getElementsByTagName('a')
      for (let l = 0; l < links.length; l++) {
        if (links[l].href.search(this.component.redirectTo1) >= 0) {
          links[l].textContent = $t('Ver en navegador', this.component.language)
          links[l].href = this.component.redirectTo1
          this.component.tinymce = htmlDoc.body.innerHTML
          break
        }
      }
    },
  },
  computed: {
    isDefaultSelected () {
      return this.component.redirectTo1 === '[show_link]'
    },
  },
  created: async function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]

    if (this.component.tinymce === '') {
      this.component.tinymce = `<p style="text-align: ${this.component.alignment};"><a
          style="
            color: ${this.component.urlColor};
          "
          class="temp-disable-link-action"
          href="${this.component.redirectTo1}"
          target="_blank"
          contenteditable="false"
        >${this.component.text1}</a>
      </p>`
    }
    this.renderEditor = true
  },
  mounted: function () {
    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })
  },
}
