export default {
  props: {
    component: { type: Object },
    landing: { type: Object },
    containerIndex: { type: Number },
  },
  name: 'SeparatorComponent',
  created: function () {
    this.landing.structure.containers[this.containerIndex].minHeight = 40
  },
}
