import LandingTemplateService from '@/services/landing-template.service'

export default {
  props: {
    landing: {
      type: Object,
      required: true,
    },
    mode: {
      default: null,
    },
    campaign: {
      type: Object,
      required: false,
    },
    fields: {
      type: Object,
      required: false,
    },
    campaignContact: {
      type: Object,
      required: false,
    },
    campaignPreview: {
      type: Boolean,
      default: false,
    },
  },
  name: 'LandingPreview',
  components: {
  },
  data: function () {
    return {
      toggle_landings: undefined,
      loadingPreview: false,
      isMailPreview: 'mail',
      preview: '',
      sizeSelected: 'desktop',
      deviceSizes: [
        { key: 'mobile', name: this.$t('Móvil'), icon: 'fas fa-mobile-alt' },
        { key: 'tablet', name: this.$t('Tablet'), icon: 'fas fa-tablet-alt' },
        { key: 'desktop', name: this.$t('Navegador'), icon: 'fas fa-desktop' },
      ],
      method: 'getPreviewFromStructure',
      contactPreview: 0,
      message: '',
      toggle_exclusive: 0,
    }
  },
  watch: {
    campaignPreview: {
      handler () {
        this.messagePreview()
      },
      deep: true,
    },
  },
  methods: {
    showAndSavePreview () {
      if (this.landing.type === 1) {
        this.loadingPreview = true
        this.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(this.iframePreview(this.landing.htmlContent))
        setTimeout(() => {
          this.loadingPreview = false
        })
      } else {
        const landingData = { id: this.landing.id, name: this.landing.name, structure: this.landing.structure, path: this.landing.path }
        this.getPreview('generatePreview', landingData)
      }
    },

    toogleLandings (toggle) {
      this.isMailPreview = toggle
      this.showPreviewFromStructure(this.landing.structure, this.isMailPreview)
    },

    showPreviewFromStructure (structure, typeLanding = 'mail') {
      const landingData = { structure: structure, typeLanding: typeLanding }
      console.log('showPreviewFromStructure campaign: ', this.campaign)
      if (this.campaign.sendings[0].channel.landingType === 0) {
        this.getPreview('getPreviewFromStructure', landingData)
      } else {
        this.loadingPreview = true
        this.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(this.iframePreview(this.campaign.sendings[0].channel.htmlContent))
        setTimeout(() => {
          this.loadingPreview = false
        })
      }
    },

    getPreview (method, data) {
      this.loadingPreview = true
      let shareHash = null
      if (this.mode === 'share') {
        shareHash = this.$route.params.shareHash
        method = 'getSharePreviewFromStructure'
      }
      LandingTemplateService[method](data, shareHash).then((response) => {
        const parser = new DOMParser()
        const htmlDoc = parser.parseFromString(response.preview, 'text/html')
        htmlDoc.head.insertAdjacentHTML('beforeend', '<style> .temp-disable-link-action { pointer-events: none; } </style>')
        const xMLSerializer = new XMLSerializer()
        const htmlString = xMLSerializer.serializeToString(htmlDoc)

        this.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(this.iframePreview(htmlString))
        this.loadingPreview = false
      }).catch((error) => {
        console.error(error.response)
      })
    },
    messagePreview () {
      if (typeof (this.campaignContact.contactsEmail) === 'undefined') {
        this.message = this.campaign.sendings[0].channel.subject
        return
      }

      if (this.campaignContact.contactsEmail.length > 0) {
        if (this.contactPreview === 0) {
          this.contactPreview = this.campaignContact.contactsEmail[0]
        }

        this.message = this.replaceCustomField(this.campaign.sendings[0].channel.subject, this.contactPreview)
      } else {
        this.message = this.campaign.sendings[0].channel.subject
      }
    },
    iframePreview (preview) {
      if (typeof (this.campaignContact) === 'undefined') {
        return preview
      }

      if (typeof (this.campaignContact.contactsEmail) === 'undefined') {
        return preview
      }

      if (this.campaignContact.contactsEmail.length > 0) {
        if (this.contactPreview === 0) {
          this.contactPreview = this.campaignContact.contactsEmail[0]
        }

        return this.replaceCustomField(preview, this.contactPreview)
      } else {
        return preview
      }
    },
    replaceCustomField (message, index) {
      const contactFields = this.campaignContact.contactsFieldsValue[index]

      for (const contactField in contactFields) {
        message = message.replaceAll('{' + contactField + '}', contactFields[contactField] + ' ')
      }

      return message
    },
    updateContactPreview () {
      this.messagePreview()
      this.showPreviewFromStructure(this.landing.structure, this.isMailPreview)
    },
    colDevices () {
      if (this.campaignPreview === true) {
        return 'col-md-6'
      } else {
        return 'col-md-8'
      }
    },
    colContactsEmail () {
      if (this.campaignPreview === true) {
        return 'col-md-4'
      } else {
        return ''
      }
    },
  },
}
