

export default {
  name: 'RoomsFilters',
  components: {
  },
  props: {
  },
  emits: [
    'reorder-rooms',
  ],
  data: function () {
    return {
      roomOrder: -1,
      roomPeriod: 7,
    }
  },
  created () {

  },
  computed: {
    roomOrderItems: function () {
      return [
        { text: this.$t('Más recientes'), value: -1 },
        { text: this.$t('Más antiguas'), value: 1 },
      ]
    },
    roomPeriodItems: function () {
      return [
        { text: this.$t('Hoy'), value: 0 },
        { text: this.$t('Ayer'), value: 1 },
        { text: this.$t('Últimos 7 días'), value: 7 },
        { text: this.$t('Últimos 30 días'), value: 30 },
        { text: this.$t('Este mes'), value: 301 },
        { text: this.$t('Mes pasado'), value: 601 },
        { text: this.$t('Toda la historia'), value: -1 },
      ]
    },
  },
  methods: {
    reorderRooms () {
      const filters = {
        roomOrder: this.roomOrder,
        roomPeriod: this.roomPeriod,
      }
      this.$emit('reorder-rooms', filters)
    },
  },
}
