
export default class State {
  constructor (stateName, excluded = []) {
    if (!stateName) {
      throw new Error('State needs the name of the variable it is assigned to')
    }
    this._stateName = stateName
    this._excluded = excluded
    this._initialState = {}
    this._state = {}
    this._lastOperationErrors = []
  }

  deepCopy (obj) {
    // RegExp.prototype.toJSON = RegExp.prototype.toString
    // Object.defineProperty(RegExp.prototype, "toJSON", {
    //   value: RegExp.prototype.toString
    // })

    function replacer (key, value) {
      if (value instanceof RegExp) {
        return ('__REGEXP ' + String(value))
      } else {
        return value
      }
    }

    function reviver (key, value) {
      if (String(value).indexOf('__REGEXP ') === 0) {
        var m = value.split('__REGEXP ')[1].match(/\/(.*)\/(.*)?/)
        return new RegExp(m[1], m[2] || '')
      } else {
        return value
      }
    }

    return JSON.parse(JSON.stringify(obj, replacer, 2), reviver)
  }

  setInitialState (data) {
    const { [this._stateName]: state, ...rest } = data
    Object.keys(rest).map(k => {
      try {
        if (this._excluded.findIndex(element => element === k) === -1) {
          this._initialState[k] = this.deepCopy(rest[k])
        }
      } catch (e) {
        this._lastOperationErrors.push({
          operation: 'setInitialState::serialization',
          element: k,
          error: e,
        })
      }
    })
  }

  setState (data) {
    const { [this._stateName]: state, ...rest } = data
    Object.keys(rest).map(k => {
        try {
          if (this._excluded.findIndex(element => element === k) === -1) {
            this._state[k] = this.deepCopy(rest[k])
          }
        } catch (e) {
          this._lastOperationErrors.push({
            operation: 'setState::serialization',
            element: k,
            error: e,
          })
        }
      })
  }

  recursiveAssign (dst, src) {
    if (Object(src) !== src) return this.deepCopy(src)
    if (Object(dst) !== dst) dst = {}
    for (const key in src) {
      dst[key] = this.recursiveAssign(dst[key], src[key])
    }
    return dst
}

  getInitialState (data = null) {
    if (!data) {
      return this._initialState
    }
    this.recursiveAssign(data, this._initialState)
    // Object.assign(data, this.deepCopy(this._initialState))
  }

  getState (data = null) {
    if (!data) {
      return this._state
    }
    this.recursiveAssign(data, this._state)
    // Object.assign(data, this.deepCopy(this._state))
  }

  get errors () {
    return this._lastOperationErrors
  }

  get errorCount () {
    return this._lastOperationErrors.length
  }
}
