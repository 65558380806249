import GroupService from '@/services/group.service'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { ChannelType } from '@/models/ChannelType'
import EventBus from '@/util/EventBus'

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    componentsData: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    previewUrl: {
      type: String,
      required: true,
    },
    channelType: {
      type: Number,
      required: true,
    },
    selectedGroups: {
      type: Array,
      required: true,
    },
    noRouteGroups: {
      type: Array,
    },
  },
  data () {
    return {
      CONTACTS_KEY: 'contacts',
      MAX_NO_ROUTE_COUNTRIES: 5,
      sendings: this.campaign.sendings[0],
      csvFileConfig: this.campaign.sendings[0].csvFileConfig,
      contactsFileUploadResponse: new ContactsFileUploadResponse(),
      maxShownErrorLines: 5,
      requestRoutingGroups: JSON.parse(localStorage.getItem('requestRoutingGroups')) || [],
    }
  },
  computed: {
    multiselectGroups: function () {
      if (typeof this.groups !== 'undefined') {
        return Object.values(this.groups)
      }
    },
    fileColumns: function () {
      const fileColumns = []
      Object.keys(this.fields.availableDateFields).forEach(groupId => {
        if (this.selectedGroups.map(g => g.id).includes(+groupId)) {
          Object.keys(this.fields.availableDateFields[groupId]).forEach(index => {
            if (!fileColumns.find(e => e.value === index)) {
              fileColumns.push({ value: index, text: this.fields.availableDateFields[groupId][index] })
            }
          })
        }
      })

      return fileColumns
    },
    hasNoRouteGroups: function () {
      return this.noRouteGroups?.length
    },
  },
  watch: {
    selectedGroups () {
      if (this.campaign.sendings[0].channel.type !== ChannelType.WHATSAPP_ID) {
        this.checkNoRouteGroups()
      }
    },
    'componentsData.loadingGroups' (nv, ov) {
      if (nv) {
        EventBus.$emit('showLoading', true, this.$t('Cargando grupos...'))
      } else {
        EventBus.$emit('showLoading', false)
      }
    },
  },
  methods: {
    checkNoRouteGroups () {
      if (this.channelType !== ChannelType.MAILING_ID) {
        if (this.selectedGroups.length) {
          const params = {
            groups: this.selectedGroups,
          }
          GroupService.checkRoutesForGroups(params).then(
            response => {
              this.$emit('no-route-groups-change', [...response])
            },
          )
        } else {
          this.$emit('no-route-groups-change', [])
        }
      }
    },
    showMoreErrors () {
      this.maxShownErrorLines += 50
      if (this.maxShownErrorLines > this.preview.errors.length) {
        this.maxShownErrorLines = this.preview.errors.length
      }
    },
    groupCountries (group) {
      if (group.countries?.length > this.MAX_NO_ROUTE_COUNTRIES) {
        return (group.countries.slice(0, this.MAX_NO_ROUTE_COUNTRIES).join(', ') + '...') || ''
      }
      return group.countries?.join(', ') || ''
    },
    requestRouting (group) {
      if (this.requestRoutingGroups.includes(group.group.id)) {
        return
      }
      const params = {
        group,
      }
      GroupService.requestRouting(params).then(
        response => {
          EventBus.$emit('showAlert', 'success', this.$t('Se ha enviado su solicitud a soporte').toString() + '. ' + this.$t('Su solicitud será procesada en las próximas 24/48 horas').toString() + '.')
          this.requestRoutingGroups.push(group.group.id)
          localStorage.setItem('requestRoutingGroups', JSON.stringify(this.requestRoutingGroups))
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error en su solicitud').toString() + '. ' + this.$t('Por favor , inténtelo más tarde').toString())
        },
      )
    },
    isRequestedRouting (group) {
      return this.requestRoutingGroups.includes(group.group.id) || false
    },
  },
  mounted () {
    this.checkNoRouteGroups()
    if (this.componentsData.loadingGroups) {
      EventBus.$emit('showLoading', true, this.$t('Cargando grupos...'))
    }
  },
}
