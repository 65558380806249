import Editor from '@tinymce/tinymce-vue'
import getEnv from '@/util/env'

export default {
  props: ['component'],
  name: 'InlineEditor',
  components: { Editor },
  data: function () {
    return {
      editorApiKey: getEnv('VUE_APP_TINY_API_KEY'),
      propertiesConfig: {
        menubar: false,
        inline: true,
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | removeformat',
      },
    }
  },
}
