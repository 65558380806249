import ImageProperties from '../image-properties/ImageProperties.vue'
import TextProperties from '../text-properties/TextProperties.vue'
import ButtonProperties from '../button-properties/ButtonProperties.vue'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import { TypeComponent } from '../../../../enums/TypeComponent.js'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import TextEditor from '@/components/TextEditor/TextEditor.vue'

export default {
  name: 'ProductProperties',
  components: {
    ImageProperties,
    TextProperties,
    ButtonProperties,
    MarginProperty,
    ColorPicker,
    TextEditor,
  },
  props: {
    landing: { type: Object },
    containerIndex: { type: Number },
    slotIndex: { type: Number },
  },
  data: function () {
    return {
      TypeComponent: TypeComponent,
      componentSelected: TypeComponent.Product,
      paddingInputChecked: Boolean,
      component: { type: Object },
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
  },
}
