export class GeneralComponent {
    id;
    type;
    backgroundType;
    backgroundColor;
    backgroundPath;
    backgroundPathSize;
    backgroundPathPosition;

    constructor (type) {
      this.id = (Date.now() + Math.random()).toString()
      this.type = type
      this.backgroundType = 'color'
      this.backgroundColor = null
      this.backgroundPath = null
    }
}
