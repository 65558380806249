import { FontTypes } from '../../../../enums/FontTypes.js'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import Editor from '@tinymce/tinymce-vue'
import getEnv from '@/util/env'
import UnsuscribeService from '@/services/unsuscribe.service.js'
import AccountService from '@/services/account.service.js'
import { Languages } from '@/classes/Languages.js'
import $t from '@/util/Translator'
import TextEditor from '@/components/TextEditor/TextEditor.vue'

export default {
  name: 'CancelSubscriptionProperties',
  components: {
    MarginProperty,
    Editor,
    TextEditor,
  },
  props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
  },
  data: function () {
    return {
      FontTypes: FontTypes,
      component: { type: Object },
      editorApiKey: getEnv('VUE_APP_TINY_API_KEY'),
      languages: Languages.languages.map(l => ({ value: l.iso, text: l.tooltip })),
      selectedLanguage: null,
      companies: [],
      selectedCompany: null,
      init: {
        selector: 'textarea#full-featured-non-premium',
        plugins: 'fullscreen wordcount quickbars emoticons autolink link noneditable',
        toolbar: ['bold italic underline emoticons fontselect fontsizeselect forecolor backcolor alignleft aligncenter alignright alignfull numlist bullist outdent indent undo redo fullscreen link'],
        toolbar_sticky: true,
        branding: false,
        image_advtab: false,
        importcss_append: true,
        height: 300,
        contextmenu: false,
        lineheight_formats: '1 1.2 1.5 2 2.3 2.5 3',
        quickbars_insert_toolbar: 'alignleft aligncenter alignright alignfull | outdent indent | link',
        quickbars_selection_toolbar: 'bold italic | blockquote fontsizeselect',
        toolbar_mode: 'floating',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px; color: #3F4254; }',
        menubar: 'custom',
        nondeletable: ['unsubscribe-link'],
        setup: function (editor) {
          const onNodeChangeCallback = function (e) {
            if (e.element.id === 'unsubscribe-link') {
              // editor.ui.disable()
              editor.container.querySelector("button[title='Insert/edit link']").style.display = 'none'
            } else {
              // editor.ui.enable()
              editor.container.querySelector("button[title='Insert/edit link']").style.display = 'block'
            }
          }

          editor.on('nodeChange', onNodeChangeCallback)
          const onChangeCallback = (e) => {
            for (let i = 0; i < this.nondeletable.length; i++) {
              if (!editor.dom.getParent(this.nondeletable[i])) {
                editor.undoManager.undo()
                editor.undoManager.clear()
              }
            }
          }
          editor.on('change', onChangeCallback)

          return function () {
            editor.off('nodeChange', onNodeChangeCallback)
            editor.off('change', onChangeCallback)
          }
        },
      },
      landingUnsubscribePages: [],
      renderEditor: false,
      profile: null,
    }
  },
  methods: {
    async getProfiles () {
      let profile = null
      await AccountService.getBilling()
      .then(
        (response) => {
          profile = response.billingProfiles[0]
          this.companies.push({
            text: profile.company_name,
            value: `<p id="companyInfo" style="text-align: center;">${profile.company_name} ${profile.CIF}</p><p id="companyInfo2" style="text-align: center;">${profile.address} ${profile.CP} ${profile.city}</p>`,
          })
          AccountService.getAdditionalCompanies()
          .then((companies) => {
            companies.forEach(company => {
              this.companies.push({
                text: company.company_name,
                value: `<p id="companyInfo" style="text-align: center;">${company.company_name} ${company.CIF}</p><p id="companyInfo2" style="text-align: center;">${company.address} ${company.CP} ${company.city}</p>`,
              })
            })
          })
        },
        () => {},
      )
      return profile
    },
    changeUrlAndLanguage () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.component.tinymce, 'text/html')
      const links = htmlDoc.getElementsByTagName('a')
      for (let l = 0; l < links.length; l++) {
        if (links[l].href.search(this.component.redirectTo1) >= 0) {
          links[l].textContent = $t('Darse de baja', this.component.language)
          links[l].href = this.component.redirectTo1
          this.component.tinymce = htmlDoc.body.innerHTML
          break
        }
      }
    },
    getUnsubscribePages () {
      this.landingUnsubscribePages = []
      UnsuscribeService.getPagesOptions({ type: 'mailing' }).then((response) => {
        response.options.map((page) => {
          this.$set(this, 'landingUnsubscribePages', [
            ...this.landingUnsubscribePages,
            {
              value: `[unsubscribe_link_${page.id}]`,
              text: page.name,
            },
          ])
        })
      })
    },
    changeUnsubscribePage () {
      this.changeUrlAndLanguage()
      this.$refs.cancelSubsEditor.resetEditorContent()
    },
    isSelected (unsubscribePage) {
      if (unsubscribePage.value === this.component.redirectTo1) {
        return true
      }
      return false
    },
  },
  computed: {
    isDefaultSelected () {
      return this.component.redirectTo1 === '[unsubscribe_link]'
    },
    canEditEmailFooter () {
      return true
      // return this.$store.getters['auth/getUser'].emailFooterEditable
    },
    companyInfo () {
      return `<p id="companyInfo" style="text-align: center;">${this.profile.company_name} ${this.profile.CIF}</p><p id="companyInfo2" style="text-align: center;">${this.profile.address} ${this.profile.CP} ${this.profile.city}</p>`
    },
  },
  created: function () {

  },
  mounted: async function () {
    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })
    this.getUnsubscribePages()

    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]

    this.profile = await this.getProfiles()
    if (!this.component.company) {
      this.component.company = this.companies[0].value
    }

    if (this.component.tinymce === '') {
      if (this.profile) {
        this.component.secondaryText = this.companyInfo
      }

      // this.component.tinymce = `<p style="
      //   text-align: ${this.component.alignment};
      //   font-size: ${this.component.mainTextFontSize}px;
      //   font-family: ${this.component.mainTextFont};
      //   color: ${this.component.mainTextColor};"
      // > ${this.component.mainText}</p><p style="
      //   text-align: ${this.component.alignment};
      //   font-size: ${this.component.secondaryTextSize}px;
      //   font-family: ${this.component.secondaryTextFont};
      //   color: ${this.component.secondaryTextColor};
      //   font-family: Helvetica,Arial,sans-serif;
      // ">${this.component.secondaryText}</p><p style="text-align: ${this.component.alignment};"><a
      //     style="
      //       color: ${this.component.urlColor};
      //     "
      //     class="temp-disable-link-action"
      //     href="${this.component.redirectTo1}"
      //     target="_blank"
      //     contenteditable="false"
      //   >${this.component.text1}</a>
      // </p>`

      this.component.tinymce = `<p style="
        text-align: ${this.component.alignment};
        font-size: ${this.component.mainTextFontSize}px;
        font-family: ${this.component.mainTextFont};
        color: ${this.component.mainTextColor};"
      > ${this.component.mainText}</p>`

      this.getUnsubscribePages()
    }
    this.renderEditor = true
  },

}
