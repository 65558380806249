import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import CopyPasteContacts from '@/components/ContactSource/CopyPasteContacts/CopyPasteContacts.vue'
import importMixin from '@/components/ContactSource/mixins/importMixin.js'

export default {
  name: 'ImportManual',
  mixins: [importMixin],
  components: {
    ButtonMultimedia,
    SelectCountry,
    CopyPasteContacts,
  },
  data () {
    return {
      IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
    }
  },
  methods: {
    setFile (type, formData, url) {
      formData.append('filePath', this.campaign.sendings[0].csvFileConfig.databaseFile)
      if (type === this.formDataType.sendFile) {
        formData.append('firstUpload', 'true')
        url = 'uploadFile'
        if (this.csvTextContent !== '') {
          formData.append('csvTextContent', this.csvTextContent)
          url = 'uploadCsvTextContent'
        }
      }
      return url
    },
  },
}
