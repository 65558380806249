import { ChannelType } from '@/models/ChannelType'
import { Unsubscribe as UnsubscribeModel } from '@/models/Unsubscribe.js'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import UnsubscribeForm from './UnsubscribeForm/UnsubscribeForm.vue'

export default {
  name: 'UnsubscribeEditor',
  props: {
    editMode: {
      required: false,
      type: Boolean,
      default: false,
    },
    pageId: {
      required: false,
    },
    channel: {
      required: false,
      type: Number,
    },
  },
  components: {
    UnsubscribeForm,
    OverlayManager,
  },
  data () {
    return {
      selectedChannel: ChannelType.SMS_ID,
      channelType: ChannelType,
      channelOptions: [
        { text: 'SMS', value: ChannelType.SMS_ID },
        { text: 'Mailing', value: ChannelType.MAILING_ID },
      ],
      unsubscribeSms: UnsubscribeModel.creteUnsubscribeSms(),
      unsubscribeMailing: UnsubscribeModel.creteUnsubscribeMailing(),
    }
  },
  methods: {
    selectChannel (channel) {
      this.selectedChannel = channel
    },
  },
}
