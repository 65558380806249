import HelpText from '@/components/Layout/HelpText/HelpText.vue'
export default {
  props: ['campaign'],
  name: 'CallRetries',
  components: { HelpText },
  data: function () {
    return {
      MAX_RETRIES: 3,
    }
  },
  methods: {
    getRetriesOptions () {
      const options = []
      options.push({ value: 0, text: this.$t('Sin reintentos').toString() })
      for (var i = 1; i <= this.MAX_RETRIES; i++) {
        options.push({ value: i, text: i + ' ' + this.$t('Reintentos').toString() })
      }
      return options
    },
  },
  created () {
  },
  mounted () {
  },
}
