/* eslint-disable max-lines-per-function */
export class SynthesisOptions {
  gender = 'M'
  language = ''
  message = ''
  url = ''
  languageWithGender = { id: '', text: '' }

  prepareWithLanguageAndGender () {
    this.setGenderAndLanguageByLanguagueOption(this.languageWithGender.id)
    this.setMessageByLanguage()
  }

  setGenderAndLanguageByLanguagueOption (language) {
    const parts = language.split('_')
    this.gender = parts[parts.length - 1]
    parts.pop()
    this.language = parts.join('_')
  }

  // eslint-disable-next-line complexity
  setLanguage (lang) {
    switch (lang) {
      case 'en':
        this.language = 'en_US'
        break
      case 'es':
        this.language = 'es_ES'
        break
      case 'pt':
        this.language = 'pt_PT'
        break
      case 'arb':
        this.language = 'arb'
        break
      case 'cmn':
        this.language = 'cmn_CN'
        break
      case 'fr':
        this.language = 'fr_FR'
        break
      case 'de':
        this.language = 'de_DE'
        break
      case 'it':
        this.language = 'it_IT'
        break
      case 'ro':
        this.language = 'ro_RO'
        break
    }
  }

  // eslint-disable-next-line max-lines-per-function
  // eslint-disable-next-line complexity
  setMessageByLanguage () {
    switch (this.language) {
      case 'en_GB':
        this.message = 'This is a test to test speech synthesis'
        break
      case 'en_US':
        this.message = 'This is a test to test speech synthesis'
        break
      case 'es_ES':
        this.message = 'Esto es una prueba para probar la síntesis de voz'
        break
      case 'es_US':
        this.message = 'Esto es una prueba para probar la síntesis de voz'
        break
      case 'pt_PT':
        this.message = 'Este é um teste para testar a síntese de voz'
        break
      case 'pt_BR':
        this.message = 'Este é um teste para testar a síntese de voz'
        break
      case 'arb':
        this.message = 'هذا اختبار لاختبار تركيب الكلام'
        break
      case 'cmn_CN':
        this.message = '这是测试语音合成的测试'
        break
      case 'fr_FR':
        this.message = 'Ceci est un test pour tester la synthèse vocale'
        break
      case 'de_DE':
        this.message = 'Dies ist ein Test zum Testen der Sprachsynthese'
        break
      case 'en_IN':
        this.message = 'यह भाषण संश्लेषण का परीक्षण करने के लिए एक परीक्षण है'
        break
      case 'it_IT':
        this.message = 'Questo è un test per testare la sintesi vocale'
        break
      case 'ro_RO':
        this.message = 'Acesta este un test pentru a testa sinteza vorbirii'
        break
    }
  }
}
