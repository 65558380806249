import DrawComponent from './draw-component/DrawComponent.vue'
import { TypeComponent } from '../../../../enums/TypeComponent.js'
import { TypeTabs } from '../../../../enums/TypeTabs.js'
import EventBus from '@/util/EventBus.js'
import { ContainerDistributionColumns } from '../../../../enums/ContainerDistributionColumns.js'
import { ComponentFactory } from '../../../../utils/ComponentFactory'
import { BackgroundType } from '@/components/Template/LandingEditor/classes/BackgroundType.js'

export default {
  props: {
    landing: { type: Object },
    containerIndex: { type: Number },
    cancelBlocks: { type: Number },
    cancelSlots: { type: Number },
    reRenderLanding: { type: Number, default: 0 },
  },
  name: 'Container',
  components: { DrawComponent },
  data: function () {
    return {
      protectedComponents: ['cancel-subscription'],
      uniqueComponents: ['cancel-subscription'],
      container: null,
      activeDropzone: false,
      TypeTabs: TypeTabs,
      TypeComponent: TypeComponent,
      backgroundType: new BackgroundType(),
      user: this.$store.getters['auth/getUser'],
    }
  },
  created: function () {
    this.container = this.landing.structure.containers[this.containerIndex]
  },
  computed: {
    isRemovable () {
      if (!this.user) {
        this.user = JSON.parse(localStorage.getItem('user'))
      }
      return this.container.slots.reduce((prev, currSlot) => {
        return !this.protectedComponents.includes(currSlot[0]?.type) && prev
      }, true) || this.cancelBlocks > 1 || this.user.avoidUnsubscribeLink
    },
    isDuplicable () {
      return this.container.slots.reduce((prev, currSlot) => {
        return !this.uniqueComponents.includes(currSlot[0]?.type) && prev
      }, true)
    },
  },
  methods: {
    componentDrop (slotIndex, event) {
      try {
        if (!this.user) {
          this.user = JSON.parse(localStorage.getItem('user'))
        }
        const componentData = JSON.parse(event.dataTransfer.getData('componentData'))
        componentData.isCountryEu = this.user.isCountryEu

        if (componentData.type !== TypeComponent.Container && this.container.slots[slotIndex].length === 0) {
          const newComponent = ComponentFactory.create(componentData)
          // eslint-disable-next-line max-depth
          if (newComponent.minHeight !== null && newComponent.minHeight !== undefined) {
            this.landing.structure.containers[this.containerIndex].minHeight = newComponent.minHeight
          }
          this.landing.structure.containers[this.containerIndex].slots[slotIndex].push(newComponent.getStructure())
          EventBus.$emit('showPropertiesTabEvent', newComponent.type, this.containerIndex, slotIndex, newComponent.id)
        }
      } catch (error) { }

      this.activeDropzone = false

      setTimeout(() => {
        EventBus.$emit('undoRedoManager')
      }, 500)
    },

    componentDragOver () {
      this.activeDropzone = true
    },

    componentDragLeave () {
      this.activeDropzone = false
    },

    removeContainer () {
      this.landing.editorHelper.componentSelected.type = null
      this.landing.editorHelper.componentSelected.containerIndex = -1
      this.landing.editorHelper.componentSelected.slotIndex = -1

      this.landing.structure.containers.splice(this.containerIndex, 1)
      EventBus.$emit('undoRedoManager')
    },

    duplicateContainer () {
      const constainers = []
      for (let i = 0; i < this.landing.structure.containers.length; i++) {
        constainers.push(this.landing.structure.containers[i])

        if (this.containerIndex === i) {
          const newComponent = ComponentFactory.createDuplicate({
            slots: this.landing.structure.containers[i].slots,
            backgroundColor: this.landing.structure.containers[i].backgroundColor,
            paddingTop: this.landing.structure.containers[i].paddingTop,
            paddingBottom: this.landing.structure.containers[i].paddingBottom,
            type: 'container',
          })
          constainers.push(newComponent)
        }
      }

      this.landing.structure.containers = constainers
      EventBus.$emit('undoRedoManager')
    },

    viewContainerProperties () {
      EventBus.$emit('showPropertiesTabEvent', this.container.type, this.containerIndex, -1, this.container.id)
    },

    calculateWidthColumns (index) {
      const oneOfThreeColumn = 33.333
      const twoOfThreeColumn = 66.333
      const maxWidth = 100
      let columnWidth = 0
      switch (this.container.distribution) {
        case ContainerDistributionColumns.OneAndTwo:
          columnWidth = (index === 0) ? oneOfThreeColumn : twoOfThreeColumn
          break
        case ContainerDistributionColumns.TwoAndOne:
          columnWidth = (index === 0) ? twoOfThreeColumn : oneOfThreeColumn
          break
        default:
          columnWidth = (maxWidth / this.container.slots.length)
          break
      }

      return columnWidth
    },

    moveContainer () {
      this.$emit('moveContainerEvent', this.containerIndex, 1)
    },
    getbackgroundType () {
      if (this.container.backgroundType === this.backgroundType.color) {
        return { backgroundColor: this.container.backgroundColor, minHeight: this.container.minHeight + 'px' }
      }

      if (this.container.backgroundType === this.backgroundType.image) {
        return {
          backgroundImage: 'url(' + this.container.backgroundPath + ')',
          backgroundPosition: this.container.backgroundPathPosition,
          backgroundSize: this.container.backgroundPathSize,
          backgroundRepeat: 'no-repeat',
          minHeight: this.container.minHeight + 'px',
        }
      }
    },
  },

}
