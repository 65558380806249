
import { Service } from '@/services/service'

const PATH = 'whatsapp/close-reasons/'

class UserInboxCloseReasonService extends Service {
  getAllReasons (params) {
    return this.get(PATH + 'all-reasons', params)
  }

  getReasonsData (params) {
    return this.get(PATH + 'reasons-data', params)
  }

  save (params) {
    return this.post(PATH + 'save', params)
  }

  delete (params) {
    return this.post(PATH + 'delete', params)
  }
}

export default new UserInboxCloseReasonService()
