import { render, staticRenderFns } from "./TextComponent.vue?vue&type=template&id=4b385e88&scoped=true&"
import script from "./TextComponent.vue.js?vue&type=script&lang=js&"
export * from "./TextComponent.vue.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b385e88",
  null
  
)

export default component.exports