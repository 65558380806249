import { TypeComponent } from '../../enums/TypeComponent.js'

export default {
  props: { tile: { Type: Object, required: true } },
  name: 'Tile',
  components: {},
  data: function () {
    return {
      TypeComponent: TypeComponent,
    }
  },
  methods: {
    dragStart (event) {
      const componentData = {
        type: this.tile.type,
      }

      if (this.tile.type === TypeComponent.Container) {
        componentData.slots = this.tile.slots
        componentData.distribution = this.tile.distribution
      }

      event.dataTransfer.setData('componentData', JSON.stringify(componentData))
    },
  },

}
