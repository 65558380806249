export default {
  props: { component: { type: '' } },
  name: 'MarginProperty',
  data: function () {
    return {
      paddingInputChecked: this.checkPaddings(this.component),
    }
  },
  created: function () {
    this.paddingInputChecked = this.checkPaddings(this.component)
  },
  methods: {
    checkPaddings (component) {
      return (component.paddingTop > 0 || component.paddingLeft > 0 || component.paddingRight > 0 || component.paddingBottom > 0)
    },
    changePaddingValue () {
      if (this.paddingInputChecked) {
        this.component.paddingTop = 10
        this.component.paddingLeft = 20
        this.component.paddingRight = 20
        this.component.paddingBottom = 10
      } else {
        this.component.paddingTop = 0
        this.component.paddingLeft = 0
        this.component.paddingRight = 0
        this.component.paddingBottom = 0
      }
    },
  },
}
