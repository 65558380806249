import EventBus from '@/util/EventBus.js'
import VueTagsInput from '@johmun/vue-tags-input'
import { SendingStatuses } from '@/models/SendingStatuses'
import { ChannelType } from '@/models/ChannelType'
import Campaign from '@/models/Campaign'
import { Template } from '@/models/Template'
import { SaveBarProperties } from '@/models/SaveBarProperties'
import { PhonesWithPrefix as PhonesWithPrefixClass } from '@/models/PhonesWithPrefix'
import { ChannelVoice } from '@/models/ChannelVoice'
import ComponentStatusStore from '@/models/ComponentStatusStore'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { VoicePreview as VoicePreviewClass } from '@/classes/preview/VoicePreview'
import CampaignVoiceService from '@/services/campaign-voice.service'
import groupService from '@/services/group.service'
import FirstSection from '@/components/FirstSection/FirstSection.vue'
import SendLater from '@/components/SendLater/SendLater.vue'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import ImportFile from '@/components/ContactSource/ImportFile/ImportFile.vue'
import ImportManual from '@/components/ContactSource/ImportManual/ImportManual.vue'
import ImportGroups from '@/components/ContactSource/ImportGroups/ImportGroups.vue'
import ChangeSourceDialog from '@/components/ContactSource/ChangeSourceDialog/ChangeSourceDialog.vue'
import TestModal from '@/components/Modals/TestModal/TestModal.vue'
import Info from '@/components/Info/Info.vue'
import ContactSource from '@/components/ContactSource/ContactSource.vue'
import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import VoiceTemplate from '@/components/Template/Voice/VoiceTemplate.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import VoicePreview from '@/components/Template/Voice/VoicePreview/VoicePreview.vue'
import CampaingHeaderTitle from '../CampaingHeader/CampaingHeaderTitle/CampaingHeaderTitle.vue'
import PreviewPhone from './PreviewPhone/PreviewPhone.vue'
import Senders from './Senders/Senders.vue'
import AvailableTime from './AvailableTime/AvailableTime.vue'
import CallRetries from './CallRetries/CallRetries.vue'
import CostLimit from './CostLimit/CostLimit.vue'
import State from '@/util/State'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import CampaignModalExit from '../CampaignModalExit/CampaignModalExit.vue'
import { SmsPreview } from '@/classes/preview/SmsPreview'
import UnsuscribeService from '@/services/unsuscribe.service'
import UnsubscribeEditor from '@/components/UnsubscribeEditor/UnsubscribeEditor.vue'
import getEnv from '@/util/env'
import FormDataTable from '@/classes/Form/FormDataTable.js'
import FormsEditor from '@/components/FormsEditor/FormsEditor.vue'
import FormPreview from '@/components/FormPreview/FormPreview.vue'
import { Landing } from '@/components/Template/LandingEditor/classes/Landing'
import CampaignPreview from '@/views/Pages/Campaigns/CampaignPreview/CampaignPreview.vue'
import LandingTemplateService from '@/services/landing-template.service'
import LandingEditor from '@/components/Template/LandingEditor/LandingEditor.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import CampaignService from '@/services/campaign.service'
import { SmsRegExp } from '@/classes/SmsRegExp'
import { Menu } from '@/models/voice/Menu'
import CountryService from '@/services/country.service'
import { Country } from '@/util/Country'
import ChatWindow from '@/components/vue-chat/lib/ChatWindow.vue'

export default {
  name: 'CampaignVoice',
  components: {
    HeaderTopDashboard,
    FirstSection,
    PreviewPhone,
    Senders,
    AvailableTime,
    SendLater,
    CallRetries,
    VueTagsInput,
    CostLimit,
    ShippingMode,
    VoiceTemplate,
    ContactSource,
    ButtonMultimedia,
    OverlayManager,
    HelpText,
    TestModal,
    Info,
    CampaingHeaderTitle,
    VoicePreview,
    MultiSelect,
    ImportFile,
    ImportManual,
    ImportGroups,
    ChangeSourceDialog,
    VideoPlayer,
    CampaignModalExit,
    UnsubscribeEditor,
    FormsEditor,
    FormPreview,
    CampaignPreview,
    LandingEditor,
    LandingPreview,
    ChatWindow,
  },
  data: function () {
    return {
      MAX_RETRIES: 25,
      contactsFileUploadResult: new ContactsFileUploadResponse(),
      invalidForm: true,
      saveBarProperties: new SaveBarProperties(),
      saveBarPropertiesTemplate: new SaveBarProperties(),
      user: null,
      drawer: null,
      showSendLater: false,
      campaign: new Campaign(new ChannelVoice()),
      componentsData: {},
      options: new ComponentStatusStore(false, false, 'now'),
      tag: '',
      tags: [],
      usedTemplate: { show: false },
      editTemplateDialog: false,
      channelType: ChannelType.VOICE_INTERACTIVE_ID,
      urls: {
        redirectUrl: '/',
        store: 'save',
        send: 'send',
        submitUrlEdit: 'update',
        previewUrl: 'campaign/voice/preview',
        submitUrl: '',
      },
      preview: new VoicePreviewClass(),
      loadingPreview: true,
      phonesWithPrefix: new PhonesWithPrefixClass(),
      showPhonePreview: true,
      fields: {
        availableFields: {},
        currentFieldsMessage: [],
        currentUrlFieldsMessage: [],
      },
      errorsAlert: {
        show: false,
        textContent: '',
        type: 'error',
      },
      emptyRecipients: false,
      contactsSourceErrors: {
        show: false,
        textContent: '',
        type: 'error',
      },
      sendTestDialog: false,
      ready: false,
      readyStatus: {
        ready: false,
      },
      editorModeSaving: false,
      showPreviewDialog: false,
      multiSelectHeaders: {
        name: { header: this.$t('Grupo') },
        // sendings: { header: this.$t('Envíos'), help: this.$t('Número de veces que se realizaron envíos a un grupo') },
        contacts: { header: this.$t('Contactos'), align: 'center' },
      },
      nextGroupsPage: 1,
      lastGroupsPage: 10,
      groupsTotal: 0,
      selectedGroups: [],
      sourceKeys: {
        IMPORT_KEY: 'import',
        CONTACTS_KEY: 'contacts',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
      },
      changeRecipientsSource: false,
      nextRecipientsSource: null,
      state: new State('state', ['urlCampaignExit']),
      panel: [],
      panelAdvanced: [],
      showCampaignExit: false,
      urlCampaignExit: '',
      confirmCampaignExit: false,
      routeLeave: true,
      smsPreview: new SmsPreview(),

      unsubscribePreview: null,
      showUnsubscribePreviewIframe: false,
      showUnsubscribe: false,
      smsOptionChannelType: ChannelType.SMS_ID,

      showForm: false,
      formPreviewUrl: null,
      showFormEditor: false,
      showFormPreviewFlag: true,
      formDataTable: new FormDataTable(),

      showLanding: false,
      landing: { id: null, name: '', preview: '' },
      landingTemplate: new Landing(null),
      showEditor: false,

      regex: SmsRegExp.getRegExp(),
      menu: Menu.getMainMenu(),
      isSendingTest: false,
      isSendingCampaign: false,
      Country: Country,
      isEditingAction: false,
      dialogExecuteActionWhileEditing: false,
      actionToExecute: null,
    }
  },
  methods: {
    changeSpeechRetries () {
      this.menu.speechTimeoutSeconds = this.menu.speechRetries === 0 ? 0 : 5
    },
    getSpeechTimeoutSecondsOptions () {
      const options = []
      const timeouts = [5, 10, 15, 20, 30]
      timeouts.forEach(timeout => {
        options.push({ value: timeout, text: timeout + ' ' + this.$t('Reintentos').toString() })
      })
      return timeouts
    },
    getSpeechRetriesOptions () {
      const options = []
      options.push({ value: 0, text: this.$t('Sin reintentos').toString() })
      for (var i = 1; i <= this.MAX_RETRIES; i++) {
        options.push({ value: i, text: i + ' ' + this.$t('Reintentos').toString() })
      }
      return options
    },
    async showUnsubscribePreview () {
      this.showUnsubscribePreviewIframe = false
      UnsuscribeService.getPage({ type: 'sms' }).then((page) => {
        UnsuscribeService.preview(page.unsubscribePage).then(
          (response) => {
            this.unsubscribePreview = response.page
            this.showUnsubscribePreviewIframe = true
          },
          () => {},
        )
      })

      this.showUnsubscribe = true
    },
    showOverlayUnsubscribe () {
      EventBus.$emit('showOverlayUnsubscribe')
    },
    showOverlayUnsubscribeEditorPreview () {
      this.showOverlayUnsubscribe()
      setTimeout(() => {
        EventBus.$emit('showUnsubscribePreviewInEditor')
      })
    },

    async showFormPreview (formId) {
      this.formId = formId
      const user = this.$store.getters['auth/getUser']
      this.formPreviewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + formId + '?token=' + user.token
      this.showForm = true
    },

    async showOverlayPreviewForm () {
      const user = this.$store.getters['auth/getUser']
      this.formDataTable.previewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + this.formId + '?token=' + user.token
      this.formDataTable.preview = true
      EventBus.$emit('showOverlayFormPreview')
      this.updateFormPreview()
    },
    updateFormPreview () {
      this.showFormPreviewFlag = false
      setTimeout(() => {
        this.showFormPreviewFlag = true
      })
    },
    showOverlayFormEditor () {
      EventBus.$emit('showOverlayFormEditor')
    },

    openVideoPlayer () {
      const videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 6)
      this.$store.dispatch('videoPlayer/toggle', {
        show: true,
        url: videoSms.url,
        title: videoSms.title,
      })
    },
    saveState () {
      this.state.setState(this.$data)
      // EventBus.$emit('contactSource:saveState')
    },
    recoverState () {
      this.state.getState(this.$data)
      // EventBus.$emit('contactSource:recoverState')
    },
    showMenuPreview () {
      this.loadingPreview = true
      this.showPreviewDialog = true
      this.sendMenuToSaveEvent()
      setTimeout(() => {
        this.loadingPreview = false
      // eslint-disable-next-line no-magic-numbers
      }, 300)
    },
    updateContactsFileUploadResult (data) {
      this.contactsFileUploadResult = new ContactsFileUploadResponse(data)
      EventBus.$emit('hideAlert')
    },
    setInvalidProperty () {
      this.saveBarProperties.invalid = this.usedTemplate.show === false || this.invalidForm
    },
    setInvalidElements (invalid) {
      this.invalidForm = invalid
      this.setInvalidProperty()
    },
    updatePreview (data) {
      this.preview = new VoicePreviewClass(data)
      EventBus.$emit('hideAlert')
    },
    changePreviewUploadResponse (uploadResponse) {
      EventBus.$emit('changePreviewUploadResponseVoiceSms', uploadResponse)
    },
    showCreateTemplate () {
      this.loadingPreview = true
      this.editTemplateDialog = true
      setTimeout(() => {
        this.loadingPreview = false
      }, 700)
    },
    showEditTemplate () {
      this.loadingPreview = true
      this.editTemplateDialog = true
      this.loadingPreview = false
    },
    setMenu (menu) {
      this.editorModeSaving = true
      setTimeout(() => {
        this.editorModeSaving = false
        this.usedTemplate.content = menu
        this.setReady()
      // eslint-disable-next-line no-magic-numbers
      }, 300)
    },
    setEditUrl () {
      this.urls.submitUrl = this.urls.submitUrlEdit + '/' + this.campaign.id
    },
    prepareCampaignEdit (sending) {
      this.campaign.id = sending.campaign_id
      this.campaign.sendings[0].id = sending.id
      this.campaign.sendings[0].channel.id = sending.channel_voice.id
      this.setEditUrl()
    },
    saveAndSendTest () {
      this.isSendingTest = true
      EventBus.$emit('close-test-dialogs')
      this.campaign.sendings[0].recipientsTest = this.phonesWithPrefix.phonesWithPrefix
      return this.save()
    },
    setDefaultCampaignData (campaign, componentsData) {
      this.campaign.name = campaign.name
      this.campaign.sendings[0].sendingTimezone = componentsData.defaultTimezone
      this.campaign.subtype = campaign.campaign_subtype
      this.campaign.type = campaign.campaign_type_id
    },
    updateTags (newTags) {
      this.campaign.sendings[0].setUiTags(newTags)
    },
    checkAction (action) {
      if (this.isEditingAction) {
        this.dialogExecuteActionWhileEditing = true
        this.actionToExecute = action
      } else {
        this.actionToExecute = null
        action()
      }
    },
    closeDialogAndExecuteActionWhileEditing () {
      this.dialogExecuteActionWhileEditing = false
      if (this.actionToExecute) {
        this.actionToExecute()
      }
      this.actionToExecute = null
    },
    sendTestEvent () {
      EventBus.$emit('send-test-event')
    },
    save (redirect = false) {
      const contactSource = this.$refs.contactSource
      if (contactSource.validate()) {
        this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
        this.campaign.sendings[0].statusId = SendingStatuses.SAVED
        this.persist(this.urls.submitUrl, redirect)
      }
    },
    saveAndBalance () {
      this.routeLeave = false
      const contactSource = this.$refs.contactSource
      if (contactSource.validate()) {
        this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
        this.campaign.sendings[0].statusId = SendingStatuses.SAVED
        this.sendMenuToSaveEvent()
        setTimeout(() => {
          const campaign = this.campaign.formatData()
          campaign.sendings[0].channel.menu = JSON.stringify(this.usedTemplate.content)
          campaign.sendings[0].channel.availableTimes = JSON.stringify(this.campaign.sendings[0].channel.availableTimes)

          CampaignVoiceService.persist(this.urls.submitUrl, campaign)
          .then(
            (response) => {
              this.prepareCampaignEdit(response.sending)
              EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado su campaña correctamente'))
              this.$router.push({
                name: 'addPayment',
                query: {
                  campaign_id: this.campaign.id,
                  campaign_type: 'voice',
                  campaign_sub_type: this.campaign.subtype,
                },
              })
            },
            () => {
              EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al guardar la campaña'))
            },
          )
        }, 500)
      }
    },
    send () {
      this.routeLeave = false
      const contactSource = this.$refs.contactSource
      if (contactSource.validate()) {
        this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
        if (this.campaign.sendings[0].sendingDate) {
          this.campaign.sendings[0].statusId = SendingStatuses.PENDING
        } else {
          this.campaign.sendings[0].statusId = SendingStatuses.PREPARING
        }
        if (this.urls.submitUrl === this.urls.store) {
          this.urls.submitUrl = this.urls.send
        }
        this.isSendingCampaign = true
        this.persist(this.urls.submitUrl, true)
      }
    },
    persist (url, redirect = false) {
      this.sendMenuToSaveEvent()
      setTimeout(() => {
        const campaign = this.campaign.formatData()
        campaign.sendings[0].channel.menu = JSON.stringify(this.usedTemplate.content)
        campaign.sendings[0].channel.availableTimes = JSON.stringify(this.campaign.sendings[0].channel.availableTimes)

        CampaignVoiceService.persist(url, campaign)
        .then(
          (response) => {
            if (response.error) {
              EventBus.$emit('showAlert', 'danger', this.$t(response.error_message))
              this.confirmCampaignExit = true
              this.$router.push({ path: this.urls.redirectUrl })
              EventBus.$emit('flashCampaign', this.campaign)
            } else {
              if (redirect) {
                this.$router.push({ path: this.urls.redirectUrl })
              } else {
                this.prepareCampaignEdit(response.sending)
              }
              let message = this.isSendingTest
                ? this.$t('Se ha enviado la prueba correctamente')
                : this.$t('Se ha guardado su campaña correctamente')
              message = this.isSendingCampaign
                ? this.$t('Se ha creado su campaña correctamente')
                : message
              EventBus.$emit('showAlert', 'success', message)
              this.isSendingTest = false
              this.isSendingCampaign = false
            }
          },
          (error) => {
            if (error.response.status === 422) {
              let errorsAlert = ''
              const errors = error.response.data
              for (const index in errors) {
                // eslint-disable-next-line max-depth
                if (errors[index].length) {
                  errorsAlert += errors[index][0] + '<br>'
                } else {
                  errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
                }
              }
              return EventBus.$emit('showAlert', 'danger', errorsAlert)
            }
            if (error.response.status === 400) {
              return EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
            }

            if (error.response.data.balanceAlert) {
              return this.showBalanceAlert(error.response.data.isScheduled)
            }
            this.showErrorAlert(this.$t('Ocurrió un error al crear la campaña'))
          },
        )
      }, 500)
    },
    async selectVoiceTemplate (url) {
      CampaignVoiceService.getTemplatePreview({ path: url })
      .then(
        (response) => {
          this.usedTemplate = new Template(response.template)
          this.usedTemplate.content = JSON.parse(response.template.content)
          this.setReady()
        },
        () => {},
      )
    },
    sendMenuToSaveEvent () {
      EventBus.$emit('emit-menu-to-save')
    },
    setReady () {
      this.readyStatus.ready = false
      setTimeout(() => {
       this.readyStatus.ready = true
      })
    },
    async loadGroups () {
      if (this.nextGroupsPage > this.lastGroupsPage) {
        return
      }
      if (this.nextGroupsPage === 1) {
        this.componentsData.groups = []
      }
      const groups = await groupService.getWithContactsSendingsByChannel({
        channelType: this.channelType,
        page: this.nextGroupsPage,
      })
      this.lastGroupsPage = groups.last_page
      this.groupsTotal = groups.total
      this.componentsData.groups = [...this.componentsData.groups, ...groups.data]
      this.nextGroupsPage++
      this.componentsData.groups = this.componentsData.groups.filter(ar => !this.selectedGroups.find(rm => rm.id === ar.id))
    },
    showContactSource () {
      EventBus.$emit('contactSource:showDrawer', this.campaign.recipientsSource)
    },
    async loadAllGroups () {
      const groups = await groupService.getAllGroups({
        channelType: this.channelType,
      })
      this.componentsData.groups = [...groups]
    },
    onCleanSelectedGroups () {
      this.selectedGroups = []
      this.componentsData.groups = [...this.componentsData.groups]
    },
    onCheckShowDrawer (recipientsSource) {
      this.saveState()
      EventBus.$emit('contactSource:saveState')
      if (this.campaign.recipientsSource !== recipientsSource) {
        if (this.preview.totalRecipients > 0) {
          this.nextRecipientsSource = recipientsSource
          this.changeRecipientsSource = true
        } else {
          this.openDrawer(recipientsSource)
        }
      } else {
        this.openDrawer(recipientsSource)
      }
    },
    openDrawer (recipientsSource) {
      this.campaign.recipientsSource = recipientsSource
      EventBus.$emit('contactSource:showDrawer', recipientsSource)
    },
    confirmOpenDrawer () {
      EventBus.$emit('contactSource:cleanDrawer')
      EventBus.$emit('contactSource:resetDrawer')
      this.onCleanSelectedGroups()
      this.preview = new VoicePreviewClass()
      this.changeRecipientsSource = false
      this.saveState()
      this.openDrawer(this.nextRecipientsSource)
    },
    cancelOpenDrawer () {

    },
    closeAcceptDrawer () {
      EventBus.$emit('Campaign:changeCountryIso', this.campaign.sendings[0].csvFileConfig.countryIso)
      this.saveState()
      EventBus.$emit('contactSource:saveState')
    },
    closeCancelDrawer () {
      this.recoverState()
      if (this.preview.totalRecipients === 0) {
        EventBus.$emit('contactSource:resetDrawer')
        this.onCleanSelectedGroups()
      } else {
        EventBus.$emit('contactSource:recoverState')
      }
    },
    isSelectedRecipientsSource (recipientsSource) {
      return this.campaign.recipientsSource === recipientsSource
    },
    setSelectedGroups () {
      this.selectedGroups = [...this.componentsData.groups]
    },
    checkAndSetEventDate () {
      const programCampaignDate = localStorage.getItem('programCampaignDate')
      if (programCampaignDate) {
        this.panel = [2]
        this.campaign.sendings[0].sendingDate = programCampaignDate
      }
      localStorage.removeItem('programCampaignDate')
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    isValidForm () {
      return this.saveBarProperties.invalid === false && this.saveBarPropertiesTemplate.invalid === false
    },
    updateSelectedGroups (newGroups) {
      this.selectedGroups = newGroups
      this.campaign.groups = newGroups.map(g => g.id)
      if (newGroups.length) {
        this.preview.totalRecipients = newGroups.reduce((prev, curr) => {
          return prev + curr.contacts
        }, 0)
      }
    },
    closeCampaignExit () {
      this.showCampaignExit = false
      this.campaignExit = false
    },
    saveCampaignExit () {
      this.showCampaignExit = false
      this.confirmCampaignExit = true
      this.save()
      this.$router.push(this.urlCampaignExit.fullPath)
    },
    noSaveCampaignExit () {
      this.showCampaignExit = false
      this.confirmCampaignExit = true
      this.$router.push(this.urlCampaignExit.fullPath)
    },
    openCampaignExit (to) {
      if (this.isValidSave === true) {
        this.showCampaignExit = true
        this.urlCampaignExit = to
      } else {
        this.confirmCampaignExit = true
        this.$router.push(to.fullPath)
      }
    },
    async updateLandingPreview () {
      this.campaign.sendings[0].channel.templateStructure = this.landingTemplate.structure
      const data = {
        structure: this.landingTemplate.structure,
        typeLanding: 'Email',
      }

      LandingTemplateService.getPreviewFromStructure(data)
        .then(
          (response) => {
            this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.preview)
          },
          () => { },
        )
    },
    getPreview () {
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showPreviewFromStructure(this.landingTemplate.structure)
      })
    },
    onEditingAction (isEditing) {
      this.isEditingAction = isEditing
    },
  },
  watch: {
    usedTemplate () {
      // console.log(this.usedTemplate)
      this.setInvalidProperty()
    },
  },
  computed: {
    hasMenuOptions: function () {
      if (this.menu && this.menu.menuOptions.length > 1) {
        return true
      }
      return false
    },
    isValidSendTest: function () {
      return this.menu?.url &&
             this.isValidForm()
    },
    isValidSave: function () {
      return this.menu?.url &&
              !this.tooManyShippingHours &&
              this.isValidForm()
    },
    isValidSend: function () {
      return this.preview.totalRecipients !== 0 &&
             this.menu?.url &&
             !this.tooManyShippingHours &&
             this.isValidForm()
    },
    getShippingTotalHours () {
      const minutes = this.campaign.sendings[0].batchParts * this.campaign.sendings[0].batchMinutes
      return Math.floor(minutes / 60).toFixed(0)
    },
    tooManyShippingHours () {
      return this.getShippingTotalHours >= 48
    },
    maxCampaignCost () {
      return this.campaign.sendings[0].channel.hasCostLimit
        ? parseFloat(this.campaign.sendings[0].channel.costLimit)
        : -1
    },
  },
  mounted () {
    EventBus.$on('multimediaSelected', (url, type) => {
      if (type === 'voz') {
        this.selectVoiceTemplate(url)
      }
    })

    EventBus.$on('showUnsubscribePreviewFromSmsOption', () => {
      this.showUnsubscribePreview()
    })

    EventBus.$on('showFormPreviewFromSmsOption', (formId) => {
      this.showFormPreview(formId)
    })

    EventBus.$on('updateLandingTemplateSmsOption', (landingData) => {
      this.isLandingMessage = landingData.isLandingMessage
      this.showLanding = landingData.showLanding
      this.landing = landingData.landing
      this.landingTemplate = landingData.landingTemplate
    })

    EventBus.$on('showLandingFromSmsOption', () => {
      this.showLanding = true
    })

    EventBus.$on('queryLandingFromSmsOption', (templateId, templateStructure) => {
      CampaignService.getLandingPreviewData({ templateId: templateId, templateStructure: templateStructure }).then((template) => {
        this.isLandingMessage = true
        this.landing = template.landing
        this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(this.landing.preview)
        this.landingTemplate.structure = templateStructure
        this.landingTemplate.customVars = this.fields.availableFields
        this.campaign.sendings[0].channel.templateId = templateId
        this.campaign.sendings[0].channel.templateStructure = templateStructure
        this.landingTemplate.setEditorHelper()
      })
    })

    EventBus.$on('changedLanguage', () => {
      CountryService.getUserCountries().then(
        (response) => {
          this.componentsData.countries = response.countries
        },
      )
    })
  },
  created () {
    EventBus.$emit('showLoading', true)
    const campaignId = this.$route.params.id
    this.user = this.$store.getters['auth/getUser']
    this.phonesWithPrefix.isoCode = this.user.isoCountry?.toLowerCase() || this.user.isoTld?.toLowerCase()

    if (campaignId) {
      CampaignVoiceService.getEditCampaignData(campaignId)
      .then(
        (response) => {
          this.componentsData = response.componentsData
          this.setSelectedGroups()
          this.loadGroups()
          if (!campaignId || !this.campaign.countryIso) {
            this.campaign.sendings[0].csvFileConfig.setCountry(this.user.isoCountry.toUpperCase() || this.user.isoTld.toUpperCase())
          }
          this.showSendLater = true
          this.campaign.load(response.campaign)
          this.contactsFileUploadResult = new ContactsFileUploadResponse(response.contactsFileUploadResult)
          this.setEditUrl()
          this.preview = new VoicePreviewClass(response.preview)
          this.fields.availableFields = response.availableFields
          this.usedTemplate = new Template()
          this.usedTemplate.content = JSON.parse(this.campaign.sendings[0].channel.menu)
          this.usedTemplate.show = true
          this.setReady()
          EventBus.$emit('showLoading', false)
        },
      )
    } else {
      CampaignVoiceService.getVoiceCreateData()
      .then(
        (response) => {
          this.componentsData = response.componentsData
          this.loadGroups()
          this.showSendLater = true
          this.setDefaultCampaignData(response.campaign, response.componentsData)
          this.urls.submitUrl = this.urls.store

          CampaignVoiceService.getCreateTemplateData().then(
            (response) => {
              this.usedTemplate = response.template
              this.usedTemplate.content = JSON.parse(response.template.content)
              this.loadingPreview = false
              this.checkAndSetEventDate()
              this.setReady()
              EventBus.$emit('showLoading', false)
            })
        },
      )
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else if (this.routeLeave === false) {
      next()
    } else if (this.confirmCampaignExit) {
      next()
    } else {
      this.openCampaignExit(to)
      next(false)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else if (this.routeLeave === false) {
      next()
    } else if (this.confirmCampaignExit) {
      next()
    } else {
      this.openCampaignExit(to)
      next(false)
    }
  },
}
