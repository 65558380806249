import { BlocklistType } from '@/models/whatsapp/BlocklistType'

export default {
  name: 'RoomBlocklist',
  components: {
  },

  props: {
    value: { type: Boolean, required: true },
    blocklistStatus: { type: Array, required: true },
    contactDefinitiveBlocklist: { type: Object, required: true },
  },

  emits: [
    'add-to-blacklist',
    'remove-from-blacklist',
    'close-dialog',
  ],

  data () {
    return {
      dialogVisible: false,
      selectedBlacklistTypes: this.blocklistStatus,
    }
  },

  watch: {
    value (nv) {
      this.dialogVisible = nv
    },
    dialogVisible (nv) {
      this.$emit('input', this.dialogVisible)
    },
    blocklistStatus (nv) {
      this.selectedBlacklistTypes = nv
    },
  },

  computed: {
    blacklistTypes () {
      return [
        { value: BlocklistType.UTILITY_ID, text: this.$t('Utilidad'), disabled: this.isDefinitive(BlocklistType.UTILITY_ID) },
        { value: BlocklistType.MARKETING_ID, text: this.$t('Marketing'), disabled: this.isDefinitive(BlocklistType.MARKETING_ID) },
        { value: BlocklistType.AUTHENTICATION_ID, text: this.$t('Autenticación'), disabled: this.isDefinitive(BlocklistType.AUTHENTICATION_ID) },
        { value: BlocklistType.INBOX_ID, text: this.$t('Inbox'), disabled: this.isDefinitive(BlocklistType.INBOX_ID) },
      ]
    },
  },

  methods: {
    isDefinitive (blockListType) {
      return this.contactDefinitiveBlocklist.list.includes(blockListType)
    },

    closeDialog () {
      this.$emit('close-dialog')
    },
    modifyBlackList () {
      this.$emit('modify-blacklist', this.selectedBlacklistTypes)
      this.closeDialog()
    },
  },
}
