import CampaignService from '@/services/campaign.service'

export default {
  name: 'FirstSection',
  props: {
    campaign: { type: Object, require: true },
    preview: { type: Object, require: true },
    readyStatus: { type: Object, require: true },
    disabledEditTemplateWhatsapp: { type: Boolean, require: false, default: false },
  },
  data: function () {
    return {
      timer: null,
      showErrors: false,
      uniqueCampaignName: true,
      sourceKeys: {
        IMPORT_KEY: 'import',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
        CONTACTS_KEY: 'contacts',
      },
    }
  },
  methods: {
    getErrorsWithUniqueError (errors) {
      return this.uniqueCampaignName ? errors : [...errors, ...[this.$t('El nombre de campaña ya existe').toString()]]
    },
    validateCampaignName () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        CampaignService.checkCampaignName({ name: this.campaign.name }).then((data) => {
          this.uniqueCampaignName = data.error !== 1
        })
      }, 700)
    },
    showDrawer (recipientsSource) {
      this.$emit('checkShowDrawer', recipientsSource)
    },
    isSelectedRecipientsSource (recipientsSource) {
      return this.campaign.recipientsSource === recipientsSource
    },
    highlight (recipientsSource) {
      return (this.isSelectedRecipientsSource(recipientsSource) && this.preview.totalRecipients > 0)
              ? 'font-weight-bold'
              : ''
    },
  },
}
