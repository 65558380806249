/* eslint-disable no-useless-escape */
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import SizeProperty from '../../common/size-property/SizeProperty.vue'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import getEnv from '@/util/env'

export default {
  name: 'QrProperties',
  components: {
    MarginProperty,
    SizeProperty,
    ColorPicker,
  },
  props: {
    landing: { type: Object },
    containerIndex: { type: Number },
    slotIndex: { type: Number },
    inComponent: { type: Boolean, default: false },
  },
  data: function () {
    return {
      component: { type: Object },
      typeUrlSelected: null,
      levels: ['L', 'M', 'Q', 'H'],
      aligment: ['left', 'center', 'right'],
    }
  },
  created: function () {
    if (this.inComponent) {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].qrComponent
    } else {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
    }
  },
  mounted: function () {
    this.getImageCode()

    console.log('landing.customVars:', this.landing.customVars)
  },
  methods: {
    typeUrl () {
      this.component.url = this.component.url.replace('mailto:', '')
      this.component.url = this.component.url.replace('tel:', '')
      if (this.component.url.match(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/)) {
        this.component.url = 'mailto:' + this.component.url
      } else if (this.component.url.match(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{3,}\)?[\-\.\ \\\/]?){3,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i)) {
        this.component.url = 'tel:' + this.component.url
      } else if (this.component.url.match(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/)) {
        this.component.url = this.component.url.replace('mailto:', '')
        this.component.url = this.component.url.replace('tel:', '')
      }
    },
    getImageCode () {
      this.component.img = `${getEnv('VUE_APP_API_IMAGE_URL')}?method=barcode&type=${this.component.typeCode}&code=${this.component.code}&rgb=3f3f3f`
    },
    changeCode () {
      this.getImageCode()
    },
    changeManualCode () {
      if (!this.component.manualCode) {
        for (const property in this.landing.customVars) {
          this.component.code = `{${property}}`
          break
        }
      } else {
        this.component.code = this.$t('Ingrese su codigo aquí')
      }
      this.getImageCode()
    },
    notEmptyObject (someObject) {
      if (Object.keys(someObject).length > 0) {
        return true
      } else {
        return false
      }
    },
    colValues (col) {
      return `{${col}}`
    },
  },
}
