export class Preview {
    totalRecipients = 0
    sendingCost = 0
    //  Array<{ countryIso: string, message: string, parts: number, phone: string }> = []
    contactsPreview = []
    contactsEmail = []
    contactsFieldsValue = {}
    errors = []
    errorsTxt = ''
    constructor (response) {
      if (response) {
        this.totalRecipients = response.totalRecipients
        this.sendingCost = response.sendingCost
        this.contactsPreview = response.contactsPreview
        this.contactsFieldsValue = response.contactsFieldsValue
        this.errors = response.errors
        this.errorsTxt = response.errorsTxt
      }
    }
}
