import EventBus from '@/util/EventBus'
import { ChannelType } from '@/models/ChannelType'
import ModalResumeSend from '../ModalResumeSend/ModalResumeSend.vue'
import AccountService from '@/services/account.service'
import UserService from '@/services/user.service'

const SMS_ID = 1

export default {
  name: 'CampaingHeaderTitle',
  components: {
    ModalResumeSend,
  },
  props: {
    title: {
      default: '',
    },
    options: {
      default: '',
    },
    saveBarProperties: {
      default: '',
    },
    isValidSendTest: {
      default: '',
    },
    isValidSave: {
      default: function () {
        return this.saveBarProperties.invalid
      },
    },
    isValidSend: {
      default: function () {
        return this.saveBarProperties.invalid
      },
    },
    isValidWhiteList: {
      type: Boolean,
      default: function () {
        return true
      },
    },
    campaignType: {
      default: 'basic',
    },
    position: {
      default: 'top',
    },
    icon: '',
    channelType: {
      require: false,
      type: Number,
    },
    campaign: {
      require: false,
      type: Object,
    },
    preview: {
      require: false,
      type: Object,
    },
    currentSmsIn: {
      require: false,
      type: Object,
      default: () => ({
        hasCustomFields: false,
      }),
    },
    campaignCost: {
      require: false,
      type: Number,
      default: 0,
    },
    maxCampaignCost: {
      require: false,
      type: Number,
      default: -1,
    },
    sendTestEventAction: {
      require: false,
      type: Function,
    },
  },
  data () {
    return {
      formValid: true,
      toogleResumeSend: false,
      loadingResumeSend: false,
      balanceAlert: {},
      userStatus: 0,
    }
  },
  computed: {
    balance () {
      return this.$store.state.balance
    },
    calculatedBalance () {
      return this.balance.amount
    },
    canSeeOverview () {
      return this.toogleResumeSend && this.campaign.campaignCosts
    },
    isSMS () {
      return this.campaign?.sendings
        ? this.campaign.sendings[0].channel.type === ChannelType.SMS_ID
        : false
    },
    isMailing () {
      return this.campaign?.sendings
        ? this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID
        : false
    },
    isVoiceInteractive () {
      return this.campaign?.sendings
        ? this.campaign.sendings[0].channel.type === ChannelType.VOICE_INTERACTIVE_ID
        : false
    },
    canSend () {
      return this.userStatus === 1
    },
  },
  methods: {
    emitSave () {
      this.$emit('save')
    },
    emitSaveAndExit () {
      this.$emit('saveAndExit')
    },
    emitSaveAndGetCost () {
      this.$emit('saveAndGetCost')
    },
    emitResume () {
      if (this.channelType === SMS_ID) {
        this.emitSaveAndGetCost()
        this.toogleResumeSend = true
      } else {
        this.emitSend()
      }
    },
    emitSend () {
      this.$emit('send')
    },
    emitWhiteList () {
       this.$emit('whiteList')
    },
    sendTestEvent () {
      if (this.sendTestEventAction) {
        this.sendTestEventAction()
      } else {
        EventBus.$emit('send-test-event')
      }
    },
    closeResumeSend () {
      this.toogleResumeSend = false
    },
    acceptedResumeSend () {
      this.loadingResumeSend = true
      setTimeout(() => {
        this.loadingResumeSend = false
        this.toogleResumeSend = false
      }, 1000)
      this.emitSend()
    },
    emitSaveAndBalance () {
      this.$emit('saveAndBalance')
    },
    setBalanceAlert () {
      AccountService.getBalance().then((data) => {
        this.balanceAlert = data
      })
    },
    checkUserStatus () {
      UserService.getUserStatus()
        .then(data => {
          this.userStatus = data.status
        })
    },
  },
  created () {
    this.setBalanceAlert()
    this.checkUserStatus()
  },
}
