import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'

export default {
  props: {
    form: {
      type: Object,
    },
  },
  name: 'FormsColor',
  components: { ButtonMultimedia },
  data: function () {
    return {
    }
  },
  watch: {
  },
  methods: {
    selectImage (file) {
      this.form.header.imageUrl = file
    },
  },
  mounted () {
  },
  created () {
  },
}
