export class Contact {
  id = null
  userId = null
  msisdnUser = null
  msisdnClient = null
  firstName = null
  lastName = null
  email = null
  tags = []
  tagsNames = []
  source = 1
  interest = null
  comment = null
  countryIso = null
  status = 1
  closedReasons = []
  isClosedStatus = null
  isPendingStatus = null
  isOpenedStatus = null
  latestDateActivity = null
  routes = []
  closeReason = null
  statusName = null
  lastInteractionAt = null
  read = 0
  agentId = null
  agentTypeId = null
  agentName = null
  lastClosedReason = null
  lastClosedReasonText = null

  constructor (data) {
    this.loadData(data)
  }

  // eslint-disable-next-line complexity
  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.userId || data.user_id
    this.msisdnUser = data.msisdnUser || data.msisdn_user
    this.msisdnClient = data.msisdnClient || data.msisdn_client
    this.firstName = data.firstName || data.firstname
    this.lastName = data.lastName || data.lastname
    this.email = data.email
    this.tagsNames = data.tagsNames || data.tags_names || []
    this.source = data.source
    this.interest = data.interest
    this.comment = data.comment
    this.countryIso = ((data.country_iso || data.countryIso) ? data.country_iso || data.countryIso : this.countryIso).toUpperCase()
    this.status = data.status
    this.closedReasons = data.closed_reasons
    this.isClosedStatus = data.is_closed_status
    this.isPendingStatus = data.is_pending_status
    this.isOpenedStatus = data.is_opened_status
    this.latestDateActivity = data.latestDateActivity
    this.closeReason = data.closeReason
    this.statusName = data.statusName ?? this.statusName
    this.lastInteractionAt = data.lastInteractionAt || data.last_interaction_at
    this.read = data.read
    this.agentId = data.agent_id
    this.agentTypeId = data.agent_type_id
    this.agentName = data.agent_name
    this.lastClosedReason = data.last_closed_reason
    this.tags = data.tags
    this.lastClosedReasonText = data.lastClosedReasonText || data.last_closed_reason_text
  }
}
