import $t from '../util/Translator'

export class LanguageOptions {
  static options = [
    { id: 'en_GB_M', text: $t('Inglés').toString() + ' - ' + $t('Reino Unido').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'en_GB_F', text: $t('Inglés').toString() + ' - ' + $t('Reino Unido').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'en_US_M', text: $t('Inglés').toString() + ' - ' + $t('Estados Unidos').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'en_US_F', text: $t('Inglés').toString() + ' - ' + $t('Estados Unidos').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'es_ES_M', text: $t('Español').toString() + ' - ' + $t('España').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'es_ES_F', text: $t('Español').toString() + ' - ' + $t('España').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'es_US_M', text: $t('Español').toString() + ' - ' + $t('América Latina').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'es_US_F', text: $t('Español').toString() + ' - ' + $t('América Latina').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'pt_PT_M', text: $t('Portugués').toString() + ' - ' + $t('Portugal').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'pt_PT_F', text: $t('Portugués').toString() + ' - ' + $t('Portugal').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'pt_BR_M', text: $t('Portugués').toString() + ' - ' + $t('Brasil').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'pt_BR_F', text: $t('Portugués').toString() + ' - ' + $t('Brasil').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'arb_F', text: $t('Árabe').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'cmn_CN_F', text: $t('Chino mandarín').toString() + ' - ' + $t('China').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'fr_FR_M', text: $t('Francés').toString() + ' - ' + $t('Francia').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'fr_FR_F', text: $t('Francés').toString() + ' - ' + $t('Francia').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'de_DE_M', text: $t('Alemán').toString() + ' - ' + $t('Alemania').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'de_DE_F', text: $t('Alemán').toString() + ' - ' + $t('Alemania').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'en_IN_F', text: $t('Indi').toString() + ' - ' + $t('India').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'it_IT_M', text: $t('Italiano').toString() + ' - ' + $t('Italia').toString() + ' - ' + $t('Masculino').toString() },
    { id: 'it_IT_F', text: $t('Italiano').toString() + ' - ' + $t('Italia').toString() + ' - ' + $t('Femenino').toString() },
    { id: 'ro_RO_F', text: $t('Rumano').toString() + ' - ' + $t('Rumanía').toString() + ' - ' + $t('Femenino').toString() },
  ]
}
