import CampaignVoiceService from '@/services/campaign-voice.service'
import Info from '@/components/Info/Info.vue'

export default {
  props: ['campaign', 'componentsData'],
  name: 'Senders',
  components: { Info },
  data: function () {
    return {
      callers: {},
      callersHelper: {},
    }
  },
  computed: {
    infoErrorSenders () {
      return this.$t('Si quieres configurar tu propio remitente puedes ') +
        '<a href="' + this.$router.resolve({ name: 'support' }).href + '" class="text-decoration-none text-body-2 ls-0 btn-dark-hover no-default-hover link_contacto">' +
        this.$t('Contactar con soporte').toLowerCase() +
        '</a>'
    },
  },
  watch: {
    'campaign.countryIso': function (o, n) {
      this.getCallers()
    },
  },
  methods: {
    getCallers () {
      // eslint-disable-next-line no-magic-numbers
      if (this.campaign.recipientsSource === 'contacts') {
        CampaignVoiceService.getCallersByGroups({ groups: this.campaign.groups })
        .then(
          (response) => {
            this.setCallersHelper(response)
            this.callers = response.callers
            this.setCallersFromCampaign()
          },
          () => { },
        )
      } else {
        CampaignVoiceService.getCallersByIsoCodeAndCurrentUser({ isoCode: this.campaign.countryIso })
        .then(
          (response) => {
            this.setCallersHelper(response)
            this.callers = response.callers
            this.setCallersFromCampaign()
          },
          () => { },
        )
      }
    },
    setCallersHelper (jsonResponse) {
      this.callersHelper = {}
      const callers = jsonResponse.callers
      for (const key in callers) {
        this.callersHelper[key] = ''
      }
    },
    getNCallersChanged () {
      let count = 0
      for (const key in this.callersHelper) {
        if (this.callersHelper[key] !== '') {
          count++
        }
      }
      return count
    },
    changeCallers () {
      this.campaign.sendings[0].channel.callers = this.getNCallersChanged() > 0 ? JSON.stringify(this.callersHelper) : null
    },
    setCallersFromCampaign () {
      const callers = this.campaign.sendings[0].channel.callers
      if (callers !== null && Object.keys(callers).length > 0) {
        for (const key in callers) {
          this.callersHelper[key] = callers[key]
        }
      }
    },
  },
  created () {
    this.getCallers()
  },
  mounted () {
  },
}
