var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingTop: _vm.component.paddingTop + 'px',
    paddingLeft: _vm.component.paddingLeft + 'px',
    paddingRight: _vm.component.paddingRight + 'px',
    paddingBottom: _vm.component.paddingBottom + 'px',
    textAlign: _vm.component.alignment,
  })},[_c('a',{staticClass:"button-component",class:{ 'full-width': _vm.component.fullWidth },style:({
      backgroundColor: _vm.component.backgroundColor,
      fontFamily: _vm.component.font
    })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.component.tinymce)}})])])}
var staticRenderFns = []

export { render, staticRenderFns }