var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editMode),expression:"!editMode"}],attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showNewPage),expression:"!showNewPage"}],attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.smsUbsubscribePages,"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5","label":_vm.$t('Página de baja'),"return-object":""},on:{"change":_vm.getPage},model:{value:(_vm.selectedSmsUbsubscribePage),callback:function ($$v) {_vm.selectedSmsUbsubscribePage=$$v},expression:"selectedSmsUbsubscribePage"}}),_c('div',{staticClass:"mt-n7"},[_c('small',[_c('strong',[_vm._v(_vm._s(_vm.selectedSmsUbsubscribePage.value ? ("(ID: " + (_vm.selectedSmsUbsubscribePage.value) + ")") : ''))])])])],1),(!_vm.showNewPage)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn-toggle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table btn-custom-icon-grey",attrs:{"disabled":!_vm.$permissions.hasPermission('edit-unsubscription-pages'),"color":"white","elevation":"0","large":"","dark":_vm.$permissions.hasPermission('edit-unsubscription-pages')},on:{"click":_vm.createNewPage}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t('Nueva página'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Crearás una nueva página de baja')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"red","elevation":"0","large":"","disabled":!_vm.$permissions.hasPermission('delete-unsubscription-pages') || _vm.isNewPage || _vm.selectedSmsUbsubscribePage && _vm.selectedSmsUbsubscribePage.value === undefined},on:{"click":function($event){_vm.removePageDialog = true}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar')))])])],1)],1):_vm._e(),(_vm.showNewPage)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Nombre'),"error-messages":errors,"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.newPageName),callback:function ($$v) {_vm.newPageName=$$v},expression:"newPageName"}})]}}],null,true)})],1):_vm._e(),(_vm.showNewPage)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"filled":"","large":"","elevation":"0","dark":"","color":"red"},on:{"click":_vm.cancelNewPage}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-times ")]),_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"mt-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('Página 1'))+" "),_c('span',{staticClass:"blue--text text-caption"},[_vm._v(" - "+_vm._s(_vm.$t('Baja')))])]),_c('validation-provider',{attrs:{"name":_vm.$t('título de la página'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Título de la página'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.unsubscribeForm.headerText),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm, "headerText", $$v)},expression:"unsubscribeForm.headerText"}})]}}],null,true)}),_c('v-textarea',{attrs:{"label":_vm.$t('Cuerpo de la página'),"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.unsubscribeForm.subheaderText),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm, "subheaderText", $$v)},expression:"unsubscribeForm.subheaderText"}}),_c('validation-provider',{attrs:{"name":_vm.$t('botón aceptar'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Botón aceptar'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.unsubscribeForm.buttonText),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm, "buttonText", $$v)},expression:"unsubscribeForm.buttonText"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"mt-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('Página 2'))+" "),_c('span',{staticClass:"blue--text text-caption"},[_vm._v(" - "+_vm._s(_vm.$t('Confirmación de baja')))])]),_c('validation-provider',{attrs:{"name":_vm.$t('título de la página 2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Titulo de la página 2'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.unsubscribeForm.confirmHeaderText),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm, "confirmHeaderText", $$v)},expression:"unsubscribeForm.confirmHeaderText"}})]}}],null,true)}),_c('v-textarea',{attrs:{"label":_vm.$t('Cuerpo de la página 2'),"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.unsubscribeForm.confirmSubheaderText),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm, "confirmSubheaderText", $$v)},expression:"unsubscribeForm.confirmSubheaderText"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"container-upload",style:({
                'background-image': 'url(' + _vm.apiUrl + _vm.unsubscribeForm.logo + ')',
                'background-size': 'cover'
              })},[(!_vm.unsubscribeForm.logo)?_c('a',{staticClass:"d-block",on:{"click":_vm.selectLogo}},[_c('h4',{staticClass:"mt-8 d-block grey--text"},[_vm._v(" "+_vm._s(_vm.$t('Selecciona tu logo'))+" ")]),_c('v-icon',{staticClass:"d-block",attrs:{"color":"grey lighten-1","size":"60"}},[_vm._v(" fas fa-image ")])],1):_vm._e(),(_vm.unsubscribeForm.logo)?_c('v-btn',{staticClass:"white--text btn-delete-upload",attrs:{"color":"red"},on:{"click":_vm.removeLogo}},[_c('v-icon',[_vm._v(" fas fa-times ")])],1):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"container-upload",style:({
                'background-image': 'url(' + _vm.apiUrl + _vm.unsubscribeForm.background + ')',
                'background-size': 'cover !important'
              })},[(!_vm.unsubscribeForm.background)?_c('a',{staticClass:"d-block",on:{"click":_vm.selectBackGround}},[_c('h4',{staticClass:"mt-8 d-block grey--text"},[_vm._v(" "+_vm._s(_vm.$t('Selecciona tu imagen de fondo'))+" ")]),_c('v-icon',{staticClass:"d-block",attrs:{"color":"grey lighten-1","size":"60"}},[_vm._v(" fas fa-image ")])],1):_vm._e(),(_vm.unsubscribeForm.background)?_c('v-btn',{staticClass:"white--text btn-delete-upload",attrs:{"color":"red"},on:{"click":_vm.removeBackground}},[_c('v-icon',[_vm._v(" fas fa-times ")])],1):_vm._e()],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-4 mb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn-toggle',{staticClass:"float-right btn-canales-home",staticStyle:{"color":"#0196df !important","background-color":"#ffffff !important","font-weight":"400"},attrs:{"active-class":"grey lighten-1","background-color":"blue lighten-4","dense":"","rounded":"","mandatory":"","elevation":"0","height":"40","block":""},model:{value:(_vm.togglePreview),callback:function ($$v) {_vm.togglePreview=$$v},expression:"togglePreview"}},[_c('v-btn',{attrs:{"s":""}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"color":"#c3cdd8 !important"}},[_vm._v(" fas fa-eye ")]),_vm._v(" "+_vm._s(_vm.$t('Página 1'))+" ")],1),_c('v-btn',[_c('v-icon',{staticClass:"mr-1",staticStyle:{"color":"#c3cdd8 !important"}},[_vm._v(" fas fa-eye ")]),_vm._v(" "+_vm._s(_vm.$t('Página 2'))+" ")],1)],1)],1)],1)],1),_c('div',{staticClass:"container-preview"},[(_vm.readyPreview)?_c('div',{staticClass:"vertical-text"},[_c('h4',{staticClass:"grey--text text-h1"},[_vm._v(" "+_vm._s(_vm.$t('Previsualización páginas'))+" ")])]):_c('div',[_c('div',{staticClass:"background-preview",style:({
                    'background-image': 'url(' + _vm.apiUrl + _vm.unsubscribeForm.background + ')',
                    'background-size': 'cover !important'
                  })},[_c('div',{staticClass:"vertical-text",style:({
                      'background-color': _vm.unsubscribeForm.styles.background,
                    })},[_c('div',{staticClass:"d-block text-center"},[(_vm.unsubscribeForm.logo)?_c('img',{staticClass:"logo-preview mb-2",attrs:{"src":_vm.apiUrl + _vm.unsubscribeForm.logo,"alt":"Logo"}}):_vm._e()]),(_vm.togglePreview === 0)?_c('div',{staticClass:"d-block text-center"},[_c('h3',{style:({ 'color': _vm.unsubscribeForm.styles.text})},[_vm._v(" "+_vm._s(_vm.unsubscribeForm.headerText)+" ")]),_c('p',{staticClass:"mb-2",style:({ 'color': _vm.unsubscribeForm.styles.text})},[_vm._v(" "+_vm._s(_vm.unsubscribeForm.subheaderText)+" ")]),_c('button',{style:({
                          'color': _vm.unsubscribeForm.styles.text,
                          'background-color': _vm.unsubscribeForm.styles.button,
                          'border': '1px solid ' + _vm.Color.colorShade(_vm.unsubscribeForm.styles.button, -35),
                          'border-radius': '5px',
                          'padding': '10px 20px',
                          'opacity': '0.9',
                        }),attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.unsubscribeForm.buttonText)+" ")])]):_vm._e(),(_vm.togglePreview === 1)?_c('div',{staticClass:"d-block text-center"},[_c('h3',{style:({ 'color': _vm.unsubscribeForm.styles.text})},[_vm._v(" "+_vm._s(_vm.unsubscribeForm.confirmHeaderText)+" ")]),_c('p',{staticClass:"mb-2",style:({ 'color': _vm.unsubscribeForm.styles.text})},[_vm._v(" "+_vm._s(_vm.unsubscribeForm.confirmSubheaderText)+" ")])]):_vm._e()])])])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Textos'))+" ")]),_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":_vm.unsubscribeForm.styles.text,"elevation":"0","height":"50","width":"100%"}},'v-sheet',attrs,false),on))]}}],null,true)},[_c('v-color-picker',{attrs:{"dot-size":"25","swatches-max-height":"200"},model:{value:(_vm.unsubscribeForm.styles.text),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm.styles, "text", $$v)},expression:"unsubscribeForm.styles.text"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Botón'))+" ")]),_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":_vm.unsubscribeForm.styles.button,"elevation":"0","height":"50","width":"100%"}},'v-sheet',attrs,false),on))]}}],null,true)},[_c('v-color-picker',{attrs:{"dot-size":"25","swatches-max-height":"200"},model:{value:(_vm.unsubscribeForm.styles.button),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm.styles, "button", $$v)},expression:"unsubscribeForm.styles.button"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Fondo'))+" ")]),_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":_vm.unsubscribeForm.styles.background,"elevation":"0","height":"50","width":"100%"}},'v-sheet',attrs,false),on))]}}],null,true)},[_c('v-color-picker',{attrs:{"dot-size":"25","swatches-max-height":"200"},model:{value:(_vm.unsubscribeForm.styles.background),callback:function ($$v) {_vm.$set(_vm.unsubscribeForm.styles, "background", $$v)},expression:"unsubscribeForm.styles.background"}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"card-title-bloque-bottom"},[_c('v-col',[_c('v-btn',{staticClass:"text-transform-none ml-2 float-right",attrs:{"elevation":"0","large":"","rounded":"","color":"light-green white--text","disabled":!_vm.$permissions.hasPermission('edit-unsubscription-pages') || invalid},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t('Guardar cambios'))+" ")],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"width":"100%","max-width":"500px"},model:{value:(_vm.removePageDialog),callback:function ($$v) {_vm.removePageDialog=$$v},expression:"removePageDialog"}},[_c('div',{staticClass:"header_modal gradient-text-color"},[_vm._v(" "+_vm._s(_vm.$t('Eliminar página de baja'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.removePageDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card',{staticClass:"nocard"},[_c('v-card-text',[_c('p',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.$t('¿Deseas eliminar la página de baja?'))+" ")]),_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.selectedSmsUbsubscribePage ? _vm.selectedSmsUbsubscribePage.text : ''))])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","color":"blue-grey darken-4 white--text"},on:{"click":function($event){_vm.removePageDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"darken-1  white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","color":"red darken-1"},on:{"click":_vm.removePage}},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }