import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'
import { AlignmentComponent } from '../enums/AlignmentComponent.js'

export class Video extends GeneralComponent {
  videoUrl = '';
  videoUrlResource = '';
  thumbnailUrlResource = '';
  url = 'https://example.com';
  alignment = AlignmentComponent.Center;
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;
  width = 100;
  buttonPlayColor = '#ffffff';
  videoPlatform = 'youtube'

  constructor () {
    super(TypeComponent.Video)
  }

  getStructure () {
    return this
  }
}
