import { ChannelType } from '@/models/ChannelType'

export class Unsubscribe {
    id
    userId
    type
    json
    logo
    background

    setData (data) {
        this.id = data.id
        this.userId = data.userId
        this.type = data.type
        this.json = data.json
        this.logo = data.logo
        this.background = data.background
    }

    static creteUnsubscribeSms () {
        const unsubscribe = new Unsubscribe()
        unsubscribe.type = ChannelType.SMS
        return unsubscribe
    }

    static creteUnsubscribeMailing () {
        const unsubscribe = new Unsubscribe()
        unsubscribe.type = ChannelType.MAILING
        return unsubscribe
    }

    static creteUnsubscribeLanding () {
        const unsubscribe = new Unsubscribe()
        unsubscribe.type = ChannelType.LANDING_PAGE
        return unsubscribe
    }

    static creteUnsubscribeVoice () {
        const unsubscribe = new Unsubscribe()
        unsubscribe.type = ChannelType.VOICE_INTERACTIVE
        return unsubscribe
    }
}
