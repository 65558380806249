import { render, staticRenderFns } from "./SizeProperty.vue?vue&type=template&id=191c0a1f&scoped=true&"
import script from "./SizeProperty.vue.js?vue&type=script&lang=js&"
export * from "./SizeProperty.vue.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191c0a1f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VSlider})
