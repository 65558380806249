import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import { FontTypes } from '../enums/FontTypes.js'
import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent.js'
import $t from '../../../../util/Translator.js'

export class OpenInBrowser extends GeneralComponent {
  text1 = 'Ver en navegador'
  redirectExample='https://example.com'
  redirectTo1 = '[show_link]'
  urlFont = FontTypes.Helvetica
  urlFontSize = 12
  urlColor = '#00a4bd'
  alignment = AlignmentComponent.Center
  paddingTop = 0
  paddingLeft = 20
  paddingRight = 20
  paddingBottom = 0
  minHeight = 10
  language = 'es'
  tinymce = ''

  constructor () {
    super(TypeComponent.OpenInBrowser)
    this.text1 = $t('Ver en navegador')
  }

  getStructure () {
    return this
  }
}
