import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'
import { AlignmentComponent } from '../enums/AlignmentComponent.js'

export class SocialMedia extends GeneralComponent {
  urls;
  alignment = AlignmentComponent.Center;
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;

  constructor () {
    super(TypeComponent.SocialMedia)
    this.urls = [
      { text: 'Facebook', url: 'URL', icon: 'facebook.png' },
      { text: 'Twitter', url: 'URL', icon: 'twitter.png' },
      { text: 'Instagram', url: 'URL', icon: 'instagram.png' },
      { text: 'Linkedin', url: '', icon: 'linkedin.png' },
      { text: 'Youtube', url: '', icon: 'youtube.png' },
      { text: 'Pinterest', url: '', icon: 'pinterest.png' },
      { text: 'Email', url: '', icon: 'mail.png' },
      { text: 'Web', url: '', icon: 'web.png' },
      { text: 'Tiktok', url: '', icon: 'tiktok.png' },
      { text: 'Whatsapp', url: '', icon: 'whatsapp.png' },
      { text: 'Phone', url: '', icon: 'call.png', type: 'tel' },
      { text: 'Map', url: '', icon: 'map-marker.png' },
    ]
  }

  getStructure () {
    return this
  }
}
