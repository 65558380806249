import MarginProperty from '../../common/margin-property/MarginProperty.vue'

export default {
  name: 'SocialMediaProperties',
  components: { MarginProperty },
  props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
  },
  data: function () {
    return {
      component: { type: Object },
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
  },
  methods: {
    moveUrl (currentPosition, nextPosition) {
      this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].urls
      .splice(nextPosition, 0, this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].urls.splice(currentPosition, 1)[0])
    },
  },
}
