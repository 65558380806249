import { ChannelType } from './ChannelType'
import { Channel } from './Channel'

export class ChannelRcs extends Channel {
  id = null
  type = ChannelType.RCS_ID
  sender = ''
  encoding = 'GSM'
  message = ''
  certified = 0
  certified2way = 0
  flash = 0
  bookmark = 0
  templateId = null
  templateStructure = null
  attachments = []
  clickable = false
  verifiedSenders = []
  messageRcs = {}

  encodingTypes = [
    'GSM',
  ];

  constructor (data) {
    super()
    if (data) {
      this.id = data.id
      this.sender = data.sender
      this.encoding = 'GSM'
      this.message = JSON.parse(data.message).text_message
      this.certified = parseInt(data.certified)
      this.certified2way = parseInt(data.certified_2way)
      this.flash = parseInt(data.flash)
      this.bookmark = parseInt(data.bookmark)
      this.templateId = data.template_id
      this.templateStructure = data.template_structure ? JSON.parse(data.template_structure) : null
      this.attachments = data.attachments ? JSON.parse(data.attachments) : []
    }
  }
}
