export class BlocklistType {
  static UTILITY_ID = 1
  static MARKETING_ID = 2
  static AUTHENTICATION_ID = 3
  static INBOX_ID = 4

  static UTILITY = 'utility'
  static MARKETING = 'marketing'
  static AUTHENTICATION = 'authentication'
  static INBOX = 'inbox'
}
