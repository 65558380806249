import { Service } from '@/services/service'

const PATH = 'whatsapp/quick-responses/'
const PATH_AUTO_MESSAGES = 'user-inbox/auto-messages/'
const PATH_AVAILABLE_TIMES = 'user-inbox/available-times/'
const PATH_WELCOMING_AGENT = 'user-inbox/welcoming-agent/'

class InboxConfigService extends Service {
  getQuickResponsesData (params = {}) {
    return this.get(PATH + 'responses', params)
  }

  allResponses (params = {}) {
    return this.get(PATH + 'all-responses', params)
  }

  removeResponse (params = {}) {
    return this.post(PATH + 'remove', params)
  }

  save (params = {}) {
    return this.post(PATH + 'save', params)
  }

  update (params = {}) {
    return this.post(PATH + 'update', params)
  }


  getAutoMessages (params = {}) {
    return this.get(PATH_AUTO_MESSAGES + 'messages', params)
  }

  createAutoMessages (params = {}) {
    return this.post(PATH_AUTO_MESSAGES + 'create-messages', params)
  }

  saveAutoMessages (params = {}) {
    return this.post(PATH_AUTO_MESSAGES + 'save-messages', params)
  }

  getAvailableTimes (params = {}) {
    return this.get(PATH_AVAILABLE_TIMES + 'available-hours', params)
  }

  saveAvailableTimes (params = {}) {
    return this.post(PATH_AVAILABLE_TIMES + 'save', params)
  }

  updateAvailableTimes (params = {}) {
    return this.post(PATH_AVAILABLE_TIMES + 'update', params)
  }

  getWelcomingAgents (params = {}) {
    return this.get(PATH_WELCOMING_AGENT + 'agents', params)
  }

  saveWelcomingAgents (params = {}) {
    return this.post(PATH_WELCOMING_AGENT + 'save', params)
  }
}

export default new InboxConfigService()
