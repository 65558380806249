export default {
  props: {
    component: { type: Object },
  },
  name: 'VideoComponent',
  data: function () {
    return {
      youtubeUrl: 'https://www.youtube.com/embed/',
    }
  },
  methods: {
    getPositionAligment () {
      if (this.component.alignment === 'left') {
        return 'flex-start'
      }

      if (this.component.alignment === 'center') {
        return 'center'
      }

      if (this.component.alignment === 'right') {
        return 'flex-end'
      }
    },
  },
}
