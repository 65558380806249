var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vac-emoji-wrapper"},[_c('div',{staticClass:"vac-svg-button",class:{ 'vac-emoji-reaction': _vm.emojiReaction },on:{"click":_vm.openEmoji}},[_vm._t(_vm.messageId
          ? 'emoji-picker-reaction-icon_' + _vm.messageId
          : 'emoji-picker-icon',[_c('svg-icon',{attrs:{"name":"emoji","param":_vm.emojiReaction ? 'reaction' : ''}})])],2),(_vm.emojiOpened)?[_c('transition',{attrs:{"name":"vac-slide-up","appear":""}},[_c('div',{staticClass:"vac-emoji-picker",class:{ 'vac-picker-reaction': _vm.emojiReaction },style:({
          height: (_vm.emojiPickerHeight + "px"),
          top: _vm.positionTop ? _vm.emojiPickerHeight : (_vm.emojiPickerTop + "px"),
          right: _vm.emojiPickerRight,
          display: _vm.emojiPickerTop || !_vm.emojiReaction ? 'initial' : 'none'
        })},[(_vm.emojiOpened)?_c('emoji-picker',{ref:"emojiPicker",attrs:{"data-source":_vm.emojiDataSource}}):_vm._e()],1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }