export default {
  name: 'SeparatorProperties',
  props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
  },
  data: function () {
    return {
      component: { type: Object },
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
  },
  methods: {
  },
}
