import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import { FontTypes } from '../enums/FontTypes.js'
import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'
import $t from '../../../../util/Translator.js'

export class Html extends GeneralComponent {
  colorTitle = '#23496D';
  alignmentTitle = AlignmentComponent.Center;
  fontSizeTitle = 24;
  title = 'Editor HTML';
  fontTitle = FontTypes.Helvetica;
  colorContent = '#23496D';
  alignmentContent = AlignmentComponent.Center;
  fontSizeContent = 14;
  content = 'Inserte su propio código HTML';
  fontContent = FontTypes.Helvetica;
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;
  tinymce = ''

  constructor () {
    super(TypeComponent.Html)
    this.title = $t('Editor HTML')
    this.content = $t('Inserte su propio código HTML')
  }

  getStructure () {
    return this
  }
}
