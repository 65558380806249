import TextOnly from './whatsapp/TextOnly'

export class AutoAnswer {
  answerType = 'text'
  label = 'Por defecto'
  id = 'default'

  answer = new TextOnly()

  hasExpirationDate = false
  expirationDate = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (data) {
      this.answerType = data.answerType
      this.label = data.label
      this.id = data.id
      this.hasExpirationDate = data.hasExpirationDate
      this.expirationDate = data.expirationDate
      this.answer = data.answer
    }
  }
}
