export default {
ROOMS_EMPTY: 'No rooms',
ROOM_EMPTY: 'No room selected',
NEW_MESSAGES: 'New Messages',
MESSAGE_DELETED: 'This message was deleted',
MESSAGES_EMPTY: 'No messages',
CONVERSATION_STARTED: 'Conversation started on:',
TYPE_MESSAGE: 'Type message',
SEARCH: 'Search',
IS_ONLINE: 'is online',
LAST_SEEN: 'last seen ',
IS_TYPING: 'is writing...',
CANCEL_SELECT_MESSAGE: 'Cancel',
}
