
export class UnsubscribeForm {
    title = ''
    headerText = ''
    subheaderText = ''
    buttonText = ''
    confirmHeaderText = ''
    confirmSubheaderText = ''
    textColor = ''
    buttonColor = ''
    backgroundColor = ''
    logo = ''
    background = ''
    styles = {
        background: '#029ee1',
        button: '#2e2e2e',
        text: '#ffffff',
    }

    setData (data) {
        this.title = data.title
        this.headerText = data.headerText
        this.subheaderText = data.subheaderText
        this.buttonText = data.buttonText
        this.confirmHeaderText = data.confirmHeaderText
        this.confirmSubheaderText = data.confirmSubheaderText
        this.logo = data.logo
        this.background = data.background
        this.styles.background = data.styles.background
        this.styles.button = data.styles.button
        this.styles.text = data.styles.text
    }
}
