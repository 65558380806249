import { FontTypes } from '../../../../enums/FontTypes.js'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import Editor from '@tinymce/tinymce-vue'
import getEnv from '@/util/env'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import EventBus from '@/util/EventBus'

export default {
  name: 'HtmlProperties',
  components: { MarginProperty, Editor, TextEditor },
  props: {
    landing: {},
    containerIndex: Number,
    slotIndex: Number,
    inComponent: Boolean,
  },
  data: function () {
    return {
      editorApiKey: getEnv('VUE_APP_TINY_API_KEY'),
      FontTypes: FontTypes,
      items: [],
      component: { type: Object },
      init: {
        selector: 'textarea#full-featured-non-premium',
        plugins: 'code',
        toolbar: 'code',
        toolbar_sticky: true,
        branding: false,
        image_advtab: false,
        importcss_append: true,
        height: 300,
        contextmenu: false,
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'floating',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        menubar: 'custom',
        init_instance_callback: (editor) => {
          // eslint-disable-next-line no-undef
          // tinymce.activeEditor.execCommand('mceCodeEditor')
        },
      },
      containerSlotCanvas: 1,
      renderEditor: false,
    }
  },
  created: function () {
    this.component = null

    if (this.inComponent) {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].textComponent
      this.containerSlotCanvas = this.landing.structure.containers[this.containerIndex].slots.length
    } else {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
      this.containerSlotCanvas = this.landing.structure.containers[this.containerIndex].slots.length
    }

    this.initTinymce()
  },
  mounted: function () {
    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })

    EventBus.$on('show-html-edit-key-' + this.$vnode.key, () => {
      this.$refs.editorComponent.setAndShowHtmlDialog()
    })
  },
  methods: {
    addFieldToMessage (item, inputRef, componentVar) {
      if (item === null) {
        return
      }

      const fieldName = '{' + item + '}'
      this.insertFieldAtCursor(fieldName, inputRef, componentVar)
    },
    insertFieldAtCursor (myValue, inputRef, componentVar) {
      const tArea = this.$refs[inputRef]
      // get cursor's position:
      const startPos = tArea.selectionStart
      const tmpStr = tArea.value
      const endPos = tArea.selectionEnd
      let cursorPos = startPos

      // insert:
      this.component[componentVar] = tmpStr.substring(0, startPos) + myValue + tmpStr.substring(endPos, tmpStr.length)

      // move cursor:
      setTimeout(() => {
        cursorPos += myValue.length
        tArea.selectionStart = tArea.selectionEnd = cursorPos
      }, 10)
    },
    async changeSizeComponet () {
      if (this.containerSlotCanvas === 2) {
        this.component.fontSizeTitle = 18
        this.component.fontSizeContent = 12
      } else if (this.containerSlotCanvas === 4) {
        this.component.fontSizeTitle = 16
        this.component.fontSizeContent = 10
      }
    },
    async initTinymce () {
      // await this.changeSizeComponet()

      if (this.component.tinymce === '') {
        this.component.tinymce = `<h2 style="
          text-align: center;
          font-size: ${this.component.fontSizeTitle}px;
        ">${this.component.title}</h2><p style="
          text-align: center;
          font-size: ${this.component.fontSizeContent}px;
        ">${this.component.content}</p>`
      }
      this.renderEditor = true
    },
  },
}
