import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import { FontTypes } from '../enums/FontTypes.js'
import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'
import $t from '../../../../util/Translator.js'

export class Text extends GeneralComponent {
  colorTitle = '#3F4254';
  alignmentTitle = AlignmentComponent.Center;
  fontSizeTitle = 24;
  title = 'Título';
  fontTitle = FontTypes.Helvetica;
  colorContent = '#3F4254';
  alignmentContent = AlignmentComponent.Center;
  fontSizeContent = 14;
  content = 'Texto de ejemplo';
  fontContent = FontTypes.Helvetica;
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;
  url = []
  urlPreview = []
  tinymce = ''

  constructor () {
    super(TypeComponent.Text)
    this.title = $t('Título')
    this.content = $t('Texto de ejemplo')
  }

  getStructure () {
    return this
  }
}
