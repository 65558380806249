import moment from 'moment'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'

export default {
  name: 'SendLater',
  components: { HelpText },
  props: ['currentTimezoneSelection', 'campaign', 'timezones', 'defaultTimezone', 'options'],
  data: function () {
    return {
      timezoneSelector: false,
      menuDate: false,
      dateSimple: false,
    }
  },
  methods: {
    getMinimumDate () {
      return moment(new Date()).format('YYYY-MM-DDTHH:mm')
    },
    getMaximumDate () {
      return moment(new Date()).add(3, 'M').format('YYYY-MM-DDTHH:mm')
    },
    showTimezoneSelector () {
      this.timezoneSelector = true
    },

    setSendLater () {
      if (this.campaign.sendings[0].sendingDate) {
        this.options.sendLater = true
      } else {
        this.cancelSendLater()
      }
    },

    cancelSendLater () {
      this.campaign.sendings[0].sendingDate = null
      this.campaign.sendings[0].expirationDate = null
      this.campaign.sendings[0].sendingTimezone = this.defaultTimezone
      this.timezoneSelector = false
      this.options.sendLater = false
      this.$emit('cancelSendLaterOptions')
    },
  },
  computed: {
    timezonesList: function () {
      const list = []
      for (const timezone in this.timezones) {
        list.push({ value: timezone, text: this.timezones[timezone] })
      }
      return list
    },
  },
  created () {
    if (this.campaign.sendings[0].sendingDate !== null) {
      this.options.sendLater = true
      this.campaign.sendings[0].sendingDate = moment(this.campaign.sendings[0].sendingDate).format('YYYY-MM-DDTHH:mm')
    }
    if (this.campaign.sendings[0].expirationDate !== null) {
      this.options.sendLater = true
      this.campaign.sendings[0].expirationDate = moment(this.campaign.sendings[0].expirationDate).format('YYYY-MM-DDTHH:mm')
    }
  },
}
