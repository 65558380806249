import { Dates } from '@/util/Dates'
export class AvailableTime {
  day
  from
  to
  errorState = false

  constructor (day = 1, from = undefined, to = undefined) {
    this.day = day
    this.from = from
    this.to = to
  }

  getDayName () {
    return Dates.getDayName(this.day)
  }

  isValid () {
    if (this.from && this.to && this.from !== this.to) {
      return true
    }
    return false
  }

  isNotIn (endNightTime, startNightTime) {
    return (this.from < endNightTime || this.from > startNightTime) || (this.to > startNightTime || this.to < endNightTime)
  }


  hasError (availableTime) {
    return ((availableTime.from >= this.from && availableTime.to <= this.to) || (availableTime.to >= this.to && availableTime.from < this.to))
  }

  isBeforeThan (availableTime) {
    return (this.from < availableTime.from)
  }

  isGreaterThan (availableTime) {
    return (this.from > availableTime.from)
  }

  copy () {
    return { ...this }
  }

  static parseAvailableTimes (availableTimes) {
    if (availableTimes === null) {
      return []
    }
    const result = []
    for (let i = 0; i < availableTimes.length; i++) {
      const availableTime = new AvailableTime(availableTimes[i].day, availableTimes[i].from, availableTimes[i].to)
      result.push(availableTime)
    }
    return result
  }
}

