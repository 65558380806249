import moment from 'moment'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'
import { ChannelType } from '../../models/ChannelType'

export default {
  props: ['campaign', 'options', 'preview'],
  name: 'ShippingMode',
  components: { HelpText },
  data: function () {
    return {
      smsPerMinute: null,
    }
  },
  watch: {
    shippingMode (newMode, oldMode) {
      if (newMode === 'batch-fragmented') {
        this.campaign.sendings[0].batchParts = 2
        this.campaign.sendings[0].batchMinutes = 5
      } else {
        this.campaign.sendings[0].batchParts = null
        this.campaign.sendings[0].batchMinutes = null
      }
    },
  },
  computed: {
    shippingModes () {
      const modes = [
        {
          value: 'now',
          text: this.$t('Enviar todo inmediatamente'),
        },
        {
          value: 'batch',
          text: this.$t('Limitar velocidad de envío'),
        },
      ]

      if (this.campaign.sendings[0].channel_type_id !== ChannelType.WHATSAPP_ID) {
        modes.push({
          value: 'batch-fragmented',
          text: this.$t('Envío fragmentado'),
        })
      }

      return modes
    },
    shippingMode () {
      return this.campaign.sendings[0].shippingMode
    },
    getFragmentedRecipients () {
      if (this.campaign.sendings[0].batchParts && this.campaign.sendings[0].totalRecipients) {
        return Math.ceil(this.campaign.sendings[0].totalRecipients / this.campaign.sendings[0].batchParts).toFixed(0)
      }
      return null
    },
    getShippingModesOptions () {
      return this.thereAreRecipients() ? this.shippingModes : [this.shippingModes[0]]
    },
    getShippingTotalHours () {
      const batchParts = this.campaign.sendings[0].totalRecipients === 1 ? 1 : this.campaign.sendings[0].batchParts
      const minutes = batchParts * this.campaign.sendings[0].batchMinutes
      return Math.floor(minutes / 60).toFixed(0)
    },
    getShippingTotalMinutes () {
      const batchParts = this.campaign.sendings[0].totalRecipients === 1 ? 1 : this.campaign.sendings[0].batchParts
      const minutes = batchParts * this.campaign.sendings[0].batchMinutes
      return Math.ceil(minutes - (this.getShippingTotalHours * 60)).toFixed(0)
    },
    tooManyShippingHours () {
      return this.getShippingTotalHours >= 48
    },
  },
  methods: {
    sendingTime () {
      if (this.preview.totalRecipients > 0 && this.smsPerMinute > 0) {
        this.campaign.sendings[0].batchMinutes = 1
        this.campaign.sendings[0].batchParts = Math.ceil((this.preview.totalRecipients / this.smsPerMinute))
        moment.locale('es')
        return moment.duration(this.campaign.sendings[0].batchParts, 'minutes').humanize()
      } else {
        this.campaign.sendings[0].batchParts = null
        this.campaign.sendings[0].batchMinutes = null
        return ''
      }
    },
    hasDuplicatedCampaignTheShippedModeConfigured () {
      return this.isBatchConfigured() && this.thereAreRecipients()
    },
    isBatchConfigured () {
      return this.campaign.sendings[0].batchParts !== null && this.campaign.sendings[0].batchMinutes !== null
    },
    thereAreRecipients () {
      return this.preview.totalRecipients > 0
    },
  },
  mounted () {
    this.options.shippingMode = 'now'
    if (this.campaign.sendings[0].batchParts !== null) {
      this.options.shippingMode = 'batch'
      this.smsPerMinute = Math.ceil((this.preview.totalRecipients / this.campaign.sendings[0].batchParts))
    }
  },
  created () {
    if (this.hasDuplicatedCampaignTheShippedModeConfigured()) {
      this.smsPerMinute = Math.ceil((this.preview.totalRecipients / this.campaign.sendings[0].batchParts))
    }
  },
}
