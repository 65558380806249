import { Preview } from './Preview'

export class SmsPreview extends Preview {
  totalByCountry = {}
  totalParts = 0
  contactsPhone = []
  contactPreview = 0

  constructor (response) {
    super()
    this.loadData(response)
  }

  loadData (response) {
    if (response) {
      this.totalRecipients = response.totalRecipients
      this.sendingCost = response.sendingCost
      this.contactsPreview = response.contactsPreview
      this.contactsFieldsValue = response.contactsFieldsValue
      this.errors = response.errors
      this.errorsTxt = response.errorsTxt
      this.totalByCountry = response.totalByCountry
      this.totalParts = response.totalParts
      this.contactsPhone = response.contactsPhone
    }
  }
}
