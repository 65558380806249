import { QuestionType } from '@/classes/Form/QuestionType.js'
import { Languages } from '@/classes/Languages'

export default {
  props: {
    form: {
      type: Object,
    },
    item: {
      type: Object,
    },
    renderComponent: {
      type: Boolean,
    },
    unsubscribePages: {
      type: Object,
    },
    fields: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  name: 'ItemQuestion',
  components: { },
  data: function () {
    return {
      questionType: new QuestionType(),
      unsubscribeTextPlaceholder: this.$t('En cumplimiento con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:', this.item.unsubscribeLinkLanguage),
      languages: Languages.languages.map(l => ({ value: l.iso, text: l.tooltip })),
      changeItem: 0,
    }
  },
  computed: {
    fieldsAsArray () {
      const fields = []
      for (const key in this.fields.availableFields) {
        fields.push({ value: key, text: this.fields.availableFields[key] })
      }
      return fields
    },
    unsubscribeText () {
      return this.user.isCountryEu
        ? this.$t('En cumplimiento con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:', this.item.unsubscribeLinkLanguage)
        : this.$t('Te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:', this.item.unsubscribeLinkLanguage)
    },
  },
  methods: {
    questionLabel (item) {
      return item.type === 'hidden' ? this.$t('Nombre del campo') : this.$t('Formule su pregunta')
    },
    newBoxItem (item) {
      console.info('new item: ', item)
      const index = this.form.sections[0].items.indexOf(item)
      if (this.form.sections[0].items[index].options === undefined) {
        this.form.sections[0].items[index].options = []
      }
      this.form.sections[0].items[index].options.push({
        value: '',
      })
      this.forceRender(item)
    },
    removeBoxItem (item, secondIndex) {
      const index = this.form.sections[0].items.indexOf(item)
      this.form.sections[0].items[index].options.splice(secondIndex, 1)
      this.forceRender(item)
    },
    forceRender (item) {
      const stash = item.question
      item.question = ' '
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
        item.question = stash
      })
    },
    changeUrlAndLanguage () {
      this.unsubscribeTextPlaceholder = this.unsubscribeText
      this.item.defaultUnsubscribeText = this.unsubscribeTextPlaceholder
      this.item.unsubscribeLinkText = this.$t('Darse de baja', this.item.unsubscribeLinkLanguage)
    },
    addFieldToMessage (value) {
      if (this.item.value) {
        this.item.value += `{${value}}`
      } else {
        this.item.value = `{${value}}`
      }
      this.changeItem += 1
    },
  },
  mounted () {
    this.unsubscribeTextPlaceholder = this.unsubscribeText
  },
  created () {
  },
}
