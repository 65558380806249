import { Dates } from '@/util/Dates'
import { AvailableTime as AvailableTimeClass } from '@/models/voice/AvailableTime'

export default {
  props: ['campaign'],
  data: function () {
    return {
      startTime: '00:00',
      endTime: '23:59',
      fromGlobalTime: '',
      toGlobalTime: '',
      availableTime: new AvailableTimeClass(),
      count: 0,
      customAvailableTime: false,
      hasAvailableTimesErrors: false,
    }
  },
  watch: {
    hasAvailableTimesErrors: {
      handler () {
        if (this.hasAvailableTimesErrors === true) {
          setTimeout(() => {
            this.clearErrors()
            this.hasAvailableTimesErrors = false
          }, 3000)
        }
      },
    },
  },
  methods: {
    isGenericAssignButtonDisabled () {
      return this.areGenericFromTimeAndToTimeEmpty() || this.isGenericFromTimeGreaterThanToTime()
    },
    isCustomAssignButtonDisabled () {
        return !this.availableTime.isValid()
    },
    areGenericFromTimeAndToTimeEmpty () {
      return this.fromGlobalTime === undefined || this.toGlobalTime === undefined
    },
    isGenericFromTimeGreaterThanToTime () {
      return this.fromGlobalTime >= this.toGlobalTime
    },
    getDaysOfWeek () {
      const options = []
      Dates.daysOfWeek.forEach((dayOfWeek, index) => {
        options.push({ value: index + 1, text: this.$t(Dates.getDayName(index + 1)) })
      })
      return options
    },
    updateCount () {
      this.count = this.campaign.sendings[0].channel.availableTimes.length
    },
    set24Hours () {
      this.fromGlobalTime = this.startTime
      this.toGlobalTime = this.endTime
      this.fillAvailableTimesByTimes(this.startTime, this.endTime)
      this.updateCount()
    },
    setGlobalAvailableTimes () {
      if (!this.areGenericFromTimeAndToTimeEmpty()) {
        this.fillAvailableTimesByTimes(this.fromGlobalTime, this.toGlobalTime)
      }
      this.updateCount()
    },
    fillAvailableTimesByTimes (fromTime, toTime) {
      if (this.isChannelAvailableTimesEmpty()) {
        this.fillAvailableTimesByTimesWhenAvailableTimesisEmpty(fromTime, toTime)
      } else {
        this.fillAvailableTimesByTimesWhenAvailableTimesIsNotEmpty(fromTime, toTime)
      }
      this.sortAvailableTimes()
    },
    fillAvailableTimesByTimesWhenAvailableTimesisEmpty (fromTime, toTime) {
      this.campaign.sendings[0].channel.availableTimes = []
      for (let day = 1; day <= Dates.daysOfWeekCount; day++) {
        const availableTime = new AvailableTimeClass(day, fromTime, toTime)
        this.campaign.sendings[0].channel.availableTimes.push(availableTime)
      }
    },
    fillAvailableTimesByTimesWhenAvailableTimesIsNotEmpty (fromTime, toTime) {
      const existingHourForDay = []
      for (let day = 1; day <= Dates.daysOfWeekCount; day++) {
        const availableTime = new AvailableTimeClass(day, fromTime, toTime)
        if (this.checkIfTheDayIsAlreadySet(day)) {
          this.checkErrorsByDay(day, availableTime)
          existingHourForDay.push(day)
        } else {
          this.campaign.sendings[0].channel.availableTimes.push(availableTime)
        }
      }
      if (existingHourForDay.length > 0) {
          this.hasAvailableTimesErrors = true
      }
    },
    checkIfTheDayIsAlreadySet (day) {
      return this.campaign.sendings[0].channel.availableTimes.map((availableTime) => {
        return availableTime.day
      }).includes(day)
    },
    checkErrorsByDay (day, availableTime) {
      const indexAlreadySetInCustomForm = this.campaign.sendings[0].channel.availableTimes.findIndex((availableTime) => {
        return availableTime.day === day
      })
      if (this.campaign.sendings[0].channel.availableTimes[indexAlreadySetInCustomForm].hasError(availableTime)) {
        this.campaign.sendings[0].channel.availableTimes[indexAlreadySetInCustomForm].errorState = true
        this.isTherhasAvailableTimesErrorseErrors = true
      }
    },
    checkErrors () {
      this.clearErrors()
      const existingHourForDay = []
      this.campaign.sendings[0].channel.availableTimes.forEach((availableTime) => {
        if (this.availableTime.day === availableTime.day) {
          if (this.availableTime.hasError(availableTime)) {
            availableTime.errorState = true
            existingHourForDay.push(availableTime)
          }
        }
      })
      return existingHourForDay.length
    },
    clearErrors () {
      this.campaign.sendings[0].channel.availableTimes.forEach((availableTime) => {
        availableTime.errorState = false
      })
    },
    addDayToScheduling () {
      if (this.isChannelAvailableTimesEmpty()) {
        this.campaign.sendings[0].channel.availableTimes = []
      }

      if (!this.availableTime.isValid()) {
        return
      }
      const errors = this.checkErrors()
      if (errors > 0) {
        this.hasAvailableTimesErrors = true
        return
      }
      this.addAvailableTime()
      this.sortAvailableTimes()
      this.updateCount()
    },
    isChannelAvailableTimesEmpty () {
      return this.campaign.sendings[0].channel.availableTimes === undefined || this.campaign.sendings[0].channel.availableTimes === null
    },
    addAvailableTime () {
      this.campaign.sendings[0].channel.availableTimes.push(new AvailableTimeClass(this.availableTime.day, this.availableTime.from, this.availableTime.to))
      this.availableTime = new AvailableTimeClass()
    },
    removeSchedule (availableTimeToRemove) {
      this.campaign.sendings[0].channel.availableTimes = this.campaign.sendings[0].channel.availableTimes.filter(function (availableTime) { return availableTime !== availableTimeToRemove })
      this.updateCount()
    },
    sortAvailableTimes () {
      this.campaign.sendings[0].channel.availableTimes.sort(this.compareAvailableTimes)
    },
    compareAvailableTimes (availableTimeFirst, availableTimeSecond) {
      if (availableTimeFirst.day === availableTimeSecond.day) {
        if (!availableTimeFirst.isGreaterThan(availableTimeSecond)) {
          return +1
        }
        if (availableTimeFirst.isBeforeThan(availableTimeSecond)) {
          return -1
        }
      } else {
        if (availableTimeFirst.day < availableTimeSecond.day) {
          return -1
        }
        if (availableTimeFirst.day > availableTimeSecond.day) {
          return 1
        }
      }
      return 0
    },
  },

  created () { },
}

