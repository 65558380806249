import { Service } from './service'

const PATH = 'payment'

class PaymentService extends Service {
  getPaymentMethods (params = {}) {
    return this.get(PATH, params)
  }

  getMethodCreditCard (params = {}) {
    return this.get(PATH + '/method-credit-card', params)
  }

  getBankPaymentMethod (params = {}) {
    return this.get(PATH + '/bank', params)
  }

  confimBankPaymentMethod (data = {}) {
    return this.post(PATH + '/bank/confirm', data)
  }

  callbackBankPaymentMethod (data = {}) {
    return this.post(PATH + '/bank/callback', data)
  }

  getMultibancoPaymentMethod (params = {}) {
    return this.get(PATH + '/multibanco', params)
  }

  confimMultibancoPaymentMethod (data = {}) {
    return this.post(PATH + '/multibanco/confirm', data)
  }

  callbackMultibancoPaymentMethod (data = {}) {
    return this.post(PATH + '/multibanco/callback', data)
  }

  getBoletoPaymentMethod (params = {}) {
    return this.get(PATH + '/boleto', params)
  }

  confimBoletoPaymentMethod (data = {}) {
    return this.post(PATH + '/boleto/confirm', data)
  }

  callbackBoletoPaymentMethod (data = {}) {
    return this.post(PATH + '/boleto/callback', data)
  }

  getCreditCardPaymentMethod (params = {}) {
    return this.get(PATH + '/credit-card', params)
  }

  confimCreditCardPaymentMethod (data = {}) {
    return this.post(PATH + '/credit-card/confirm', data)
  }

  callbackCreditCardPaymentMethod (data = {}) {
    return this.post(PATH + '/credit-card/callback', data)
  }

  deletedCreditCard (data = {}, id) {
    return this.post(PATH + '/credit-card/delete/' + id, data)
  }

  getPaypalPaymentMethod (params = {}) {
    return this.get(PATH + '/paypal', params)
  }

  confimPaypalPaymentMethod (data = {}) {
    return this.post(PATH + '/paypal/confirm', data)
  }

  callbackPaypalPaymentMethod (data = {}) {
    return this.post(PATH + '/paypal/callback', data)
  }

  getPlanPrices (params = {}) {
    return this.get(PATH + '/plans', params)
  }

  checkPromotionalCode (data = {}) {
    return this.post(PATH + '/promotional-code', data)
  }
}

export default new PaymentService()
