export class Template {
  id
  name
  content
  path
  userId
  show= false

  constructor (data = null) {
    this.setData(data)
  }

  setData (data) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.content = data.content
      this.path = data.path
      this.userId = data.userId
      this.show = true
    }
  }
}
