<template>
  <div
    v-show="(isMobile && !showRoomsList) || !isMobile || singleRoom"
    class="vac-col-messages"
    @drop.prevent="onDropFiles"
    @dragenter.prevent
    @dragover.prevent
    @dragleave.prevent
    @touchstart="touchStart"
  >
    <slot
      v-if="showNoRoom"
      name="no-room-selected"
    >
      <div class="vac-container-center vac-room-empty">
        <v-icon
          size="120"
          class="mb-4"
        >
          fa fa-comments
        </v-icon>
        <div><strong>{{ $t('¡Comienza a hablar con tus contactos!') }}</strong></div>
        <div>{{ $t('Selecciona un contacto para iniciar la conversación') }}</div>
      </div>
    </slot>

    <room-header
      v-else
      :chat-props="chatProps"
      :routes="routes"
      :current-user-id="currentUserId"
      :text-messages="textMessages"
      :single-room="singleRoom"
      :show-rooms-list="showRoomsList"
      :is-mobile="isMobile"
      :room-info-enabled="roomInfoEnabled"
      :menu-actions="menuActions"
      :room="room"
      :message-selection-enabled="messageSelectionEnabled"
      :message-selection-actions="messageSelectionActions"
      :selected-messages-total="selectedMessages.length"
      :tags="tags"
      :total-notes="totalNotes"
      @toggle-rooms-list="$emit('toggle-rooms-list')"
      @room-info="$emit('room-info')"
      @menu-action-handler="$emit('menu-action-handler', $event)"
      @message-selection-action-handler="messageSelectionActionHandler"
      @cancel-message-selection="messageSelectionEnabled = false"
      @closeConversation="closeConversation"
      @openClosedConversation="openClosedConversation"
      @showNoteDialogList="showNoteDialogList"
      @modifyBlacklist="modifyBlacklist"
      @toggleAsRead="toggleAsRead"
    >
      <template
        v-for="(i, name) in $slots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </room-header>
    <div
      id="messages-list"
      ref="scrollContainer"
      class="vac-container-scroll"
      @scroll="onContainerScroll"
    >
      <loader
        :show="loadingMessages"
        type="messages"
      >
        <template
          v-for="(idx, name) in $slots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </loader>
      <div class="vac-messages-container">
        <div :class="{ 'vac-messages-hidden': loadingMessages }">
          <transition name="vac-fade-message">
            <div>
              <div
                v-if="showNoMessages"
                class="vac-text-started"
              >
                <slot name="messages-empty">
                  {{ $t('Mensajes vacíos') }}
                </slot>
              </div>
              <div
                v-if="showMessagesStarted"
                class="vac-text-started"
              >
                {{ $t('La conversación comenzó en') }} {{ messages[0].date }}
              </div>
            </div>
          </transition>
          <div
            v-if="messages.length && !messagesLoaded"
            id="infinite-loader-messages"
          >
            <loader
              :show="true"
              :infinite="true"
              type="infinite-messages"
            >
              <template
                v-for="(idx, name) in $slots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </loader>
          </div>
          <div
            v-if="messagesLoaded"
            v-intersect.quiet="onIntersect"
          />
          <transition-group
            :key="roomId"
            name="vac-fade-message"
            tag="span"
          >
            <div
              v-for="(m, i) in messages"
              :key="m.indexId || m._id"
            >
              <room-message
                :current-user-id="currentUserId"
                :message="m"
                :index="i"
                :messages="messages"
                :edited-message-id="editedMessageId"
                :message-actions="messageActions"
                :room-users="room.users"
                :text-messages="textMessages"
                :new-messages="newMessages"
                :show-reaction-emojis="showReactionEmojis"
                :show-new-messages-divider="showNewMessagesDivider"
                :text-formatting="textFormatting"
                :link-options="linkOptions"
                :username-options="usernameOptions"
                :message-selection-enabled="messageSelectionEnabled"
                :selected-messages="selectedMessages"
                :emoji-data-source="emojiDataSource"
                @message-added="onMessageAdded"
                @message-action-handler="messageActionHandler"
                @open-file="openFile"
                @open-user-tag="openUserTag"
                @open-failed-message="$emit('open-failed-message', $event)"
                @send-message-reaction="sendMessageReaction"
                @select-message="selectMessage"
                @unselect-message="unselectMessage"
              >
                <template
                  v-for="(idx, name) in $slots"
                  #[name]="data"
                >
                  <slot
                    :name="name"
                    v-bind="data"
                  />
                </template>
              </room-message>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <div v-if="!loadingMessages">
      <transition name="vac-bounce">
        <div
          v-if="scrollIcon"
          class="vac-icon-scroll"
          @click="scrollToBottom"
        >
          <transition name="vac-bounce">
            <div
              v-if="scrollMessagesCount"
              class="vac-badge-counter vac-messages-count"
            >
              {{ scrollMessagesCount }}
            </div>
          </transition>
          <slot name="scroll-icon">
            <svg-icon
              name="dropdown"
              param="scroll"
            />
          </slot>
        </div>
      </transition>
    </div>
    <room-footer
      :chat-props="chatProps"
      :room="room"
      :room-id="roomId"
      :room-message="roomMessage"
      :text-messages="textMessages"
      :show-send-icon="showSendIcon"
      :show-files="showFiles"
      :show-audio="showAudio"
      :show-emojis="showEmojis"
      :show-footer="showFooter"
      :accepted-files="acceptedFiles"
      :capture-files="captureFiles"
      :textarea-action-enabled="textareaActionEnabled"
      :textarea-auto-focus="textareaAutoFocus"
      :user-tags-enabled="userTagsEnabled"
      :emojis-suggestion-enabled="emojisSuggestionEnabled"
      :templates-text="templatesText"
      :text-formatting="textFormatting"
      :link-options="linkOptions"
      :audio-bit-rate="audioBitRate"
      :audio-sample-rate="audioSampleRate"
      :init-reply-message="initReplyMessage"
      :init-edit-message="initEditMessage"
      :dropped-files="droppedFiles"
      :emoji-data-source="emojiDataSource"
      @update-edited-message-id="editedMessageId = $event"
      @edit-message="$emit('edit-message', $event)"
      @send-message="$emit('send-message', $event)"
      @typing-message="$emit('typing-message', $event)"
      @textarea-action-handler="$emit('textarea-action-handler', $event)"
      @showLocationDialog="showLocationDialog"
      @showMessageInteractiveDialog="showMessageInteractiveDialog"
      @showNoteDialog="showNoteDialog"
      @checkShowValidTemplate="checkShowValidTemplate"
    >
      <template
        v-for="(idx, name) in $slots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </room-footer>
  </div>
</template>

<script>
  import Loader from '../../components/Loader/Loader'
  import SvgIcon from '../../components/SvgIcon/SvgIcon'

  import RoomHeader from './RoomHeader/RoomHeader'
  import RoomFooter from './RoomFooter/RoomFooter'
  import RoomMessage from './RoomMessage/RoomMessage'
  import { ChatProps } from '@/classes/chat/chat-props.js'

  export default {
    name: 'ChatRoom',
    components: {
      Loader,
      SvgIcon,
      RoomHeader,
      RoomFooter,
      RoomMessage,
    },

    props: {
      chatProps: { type: Object, required: true, default: () => { return new ChatProps() } },
      currentUserId: { type: [String, Number], required: true },
      textMessages: { type: Object, required: true },
      singleRoom: { type: Boolean, required: true },
      showRoomsList: { type: Boolean, required: true },
      isMobile: { type: Boolean, required: true },
      allRooms: { type: Array, required: true },
      rooms: { type: Array, required: true },
      roomId: { type: [String, Number], required: true },
      loadFirstRoom: { type: Boolean, required: true },
      messages: { type: Array, required: true },
      roomMessage: { type: String, default: null },
      messagesLoaded: { type: Boolean, required: true },
      menuActions: { type: Array, required: true },
      messageActions: { type: Array, required: true },
      messageSelectionActions: { type: Array, required: true },
      autoScroll: { type: Object, required: true },
      showSendIcon: { type: Boolean, required: true },
      showFiles: { type: Boolean, required: true },
      showAudio: { type: Boolean, required: true },
      audioBitRate: { type: Number, required: true },
      audioSampleRate: { type: Number, required: true },
      showEmojis: { type: Boolean, required: true },
      showReactionEmojis: { type: Boolean, required: true },
      showNewMessagesDivider: { type: Boolean, required: true },
      showFooter: { type: Boolean, required: true },
      acceptedFiles: { type: String, required: true },
      captureFiles: { type: String, required: true },
      textFormatting: { type: Object, required: true },
      linkOptions: { type: Object, required: true },
      loadingRooms: { type: Boolean, required: true },
      roomInfoEnabled: { type: Boolean, required: true },
      textareaActionEnabled: { type: Boolean, required: true },
      textareaAutoFocus: { type: Boolean, required: true },
      userTagsEnabled: { type: Boolean, required: true },
      emojisSuggestionEnabled: { type: Boolean, required: true },
      scrollDistance: { type: Number, required: true },
      templatesText: { type: Array, default: null },
      usernameOptions: { type: Object, required: true },
      emojiDataSource: { type: String, default: undefined },
      tags: { type: Array, default: () => [] },
      routes: { type: Object },
      totalNotes: {
        type: Number,
        default: 0,
      },
    },

    emits: [
      'toggle-rooms-list',
      'room-info',
      'menu-action-handler',
      'message-selection-action-handler',
      'edit-message',
      'send-message',
      'delete-message',
      'message-action-handler',
      'fetch-messages',
      'send-message-reaction',
      'typing-message',
      'open-file',
      'open-user-tag',
      'open-failed-message',
      'textarea-action-handler',
    ],

    data () {
      return {
        editedMessageId: null,
        initReplyMessage: null,
        initEditMessage: null,
        loadingMessages: false,
        observer: null,
        showLoader: true,
        loadingMoreMessages: false,
        scrollIcon: false,
        scrollMessagesCount: 0,
        newMessages: [],
        messageSelectionEnabled: false,
        selectedMessages: [],
        droppedFiles: [],
      }
    },

    computed: {
      room () {
        return this.allRooms.find(room => room.roomId === this.roomId) || {}
      },
      showNoMessages () {
        return (
          this.roomId &&
          !this.messages.length &&
          !this.loadingMessages &&
          !this.loadingRooms
        )
      },
      showNoRoom () {
        const noRoomSelected =
          // (!this.rooms.length && !this.loadingRooms) ||
          (!this.roomId && !this.loadFirstRoom)

        if (noRoomSelected) {
          this.updateLoadingMessages(false)
        }
        return noRoomSelected
      },
      showMessagesStarted () {
        return this.messages.length && this.messagesLoaded
      },
    },

    watch: {
      roomId: {
        immediate: true,
        handler () {
          this.onRoomChanged()
        },
      },
      messages: {
        deep: true,
        handler (newVal, oldVal) {
          newVal.forEach((message, i) => {
            if (
              this.showNewMessagesDivider &&
              !message.seen &&
              message.senderId !== this.currentUserId
            ) {
              this.newMessages.push({
                _id: message._id,
                index: i,
              })
            }
          })
          if (oldVal?.length === newVal?.length - 1) {
            this.newMessages = []
          }
          setTimeout(() => (this.loadingMoreMessages = false))
        },
      },
      messagesLoaded (val) {
        if (val) this.updateLoadingMessages(false)
      },
    },

    mounted () {
      this.newMessages = []
    },

    methods: {
      onIntersect (entries, observer, isIntersecting) {
        const hasItems = this.messages.length > 0
        if (isIntersecting && hasItems) {
          this.$emit('load-next-page')
        }
      },
      checkShowValidTemplate (template) {
        this.$emit('checkShowValidTemplate', template)
      },
      showLocationDialog () {
        this.$emit('showLocationDialog')
      },
      showMessageInteractiveDialog () {
        this.$emit('showMessageInteractiveDialog')
      },
      showNoteDialog () {
        this.$emit('showNoteDialog')
      },
      showNoteDialogList () {
        this.$emit('showNoteDialogList')
      },
      closeConversation (data) {
        this.$emit('closeConversation', data)
      },
      openConversation (data) {
        this.$emit('openConversation', data)
      },
      openClosedConversation (data) {
        this.$emit('openClosedConversation', data)
      },
      modifyBlacklist (room, blocklistTypes) {
        this.$emit('modifyBlacklist', room, blocklistTypes)
      },
      toggleAsRead (room) {
        this.$emit('toggleAsRead', room)
      },
      updateLoadingMessages (val) {
        this.loadingMessages = val

        if (!val) {
          setTimeout(() => this.initIntersectionObserver())
        }
      },
      initIntersectionObserver () {
        if (this.observer) {
          this.showLoader = true
          this.observer.disconnect()
        }

        const loader = this.$el.querySelector('#infinite-loader-messages')

        if (loader) {
          const options = {
            root: this.$el.querySelector('#messages-list'),
            rootMargin: `${this.scrollDistance}px`,
            threshold: 0,
          }

          this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
              this.loadMoreMessages()
            }
          }, options)

          this.observer.observe(loader)
        }
      },
      preventTopScroll () {
        const container = this.$refs.scrollContainer
        const prevScrollHeight = container.scrollHeight

        const observer = new ResizeObserver(_ => {
          if (container.scrollHeight !== prevScrollHeight) {
            if (this.$refs.scrollContainer) {
              this.$refs.scrollContainer.scrollTo({
                top: container.scrollHeight - prevScrollHeight,
              })
              observer.disconnect()
            }
          }
        })

        for (var i = 0; i < container.children.length; i++) {
          observer.observe(container.children[i])
        }
      },
      touchStart (touchEvent) {
        if (this.singleRoom) return

        if (touchEvent.changedTouches.length === 1) {
          const posXStart = touchEvent.changedTouches[0].clientX
          const posYStart = touchEvent.changedTouches[0].clientY

          addEventListener(
            'touchend',
            touchEvent => this.touchEnd(touchEvent, posXStart, posYStart),
            { once: true },
          )
        }
      },
      touchEnd (touchEvent, posXStart, posYStart) {
        if (touchEvent.changedTouches.length === 1) {
          const posXEnd = touchEvent.changedTouches[0].clientX
          const posYEnd = touchEvent.changedTouches[0].clientY

          const swippedRight = posXEnd - posXStart > 100
          const swippedVertically = Math.abs(posYEnd - posYStart) > 50

          if (swippedRight && !swippedVertically) {
            this.$emit('toggle-rooms-list')
          }
        }
      },
      onRoomChanged () {
        this.updateLoadingMessages(true)
        this.scrollIcon = false
        this.scrollMessagesCount = 0
        this.resetMessageSelection()

        const unwatch = this.$watch(
          () => this.messages,
          val => {
            if (!val || !val.length) return

            const element = this.$refs.scrollContainer
            if (!element) return

            unwatch()
            this.goToBottom()
          },
        )
      },
      goToBottom () {
        const element = this.$refs.scrollContainer
        let currentInterval = 0
        const scrollInterval = setInterval(() => {
          currentInterval++
          element.scrollTo({ top: element.scrollHeight })
          if (currentInterval === 4) {
            this.updateLoadingMessages(false)
            clearInterval(scrollInterval)
          }
        }, 400)
      },
      resetMessageSelection () {
        this.messageSelectionEnabled = false
        this.selectedMessages = []
      },
      selectMessage (message) {
        this.selectedMessages.push(message)
      },
      unselectMessage (messageId) {
        this.selectedMessages = this.selectedMessages.filter(
          message => message._id !== messageId,
        )
      },
      onMessageAdded ({ message, index, ref }) {
        if (index !== this.messages.length - 1) return

        if (!ref) {
          this.goToBottom()
          return
        }

        const autoScrollOffset = ref.offsetHeight + 60

        // eslint-disable-next-line complexity
        setTimeout(() => {
          const scrollContainer = this.$refs.scrollContainer
          let scrolledUp = false

          if (scrollContainer) {
            scrolledUp = this.getBottomScroll(scrollContainer) > autoScrollOffset
          }

          if (message.senderId === this.currentUserId) {
            if (scrolledUp) {
              // eslint-disable-next-line max-depth
              if (this.autoScroll.send.newAfterScrollUp) {
                this.scrollToBottom()
              }
            } else {
              // eslint-disable-next-line max-depth
              if (this.autoScroll.send.new) {
                this.scrollToBottom()
              }
            }
          } else {
            if (scrolledUp) {
              // eslint-disable-next-line max-depth
              if (this.autoScroll.receive.newAfterScrollUp) {
                this.scrollToBottom()
              } else {
                this.scrollIcon = true
                this.scrollMessagesCount++
              }
            } else {
              // eslint-disable-next-line max-depth
              if (this.autoScroll.receive.new) {
                this.scrollToBottom()
              } else {
                this.scrollIcon = true
                this.scrollMessagesCount++
              }
            }
          }
        })
      },
      onContainerScroll (e) {
        if (!e.target) return

        const bottomScroll = this.getBottomScroll(e.target)
        if (bottomScroll < 60) this.scrollMessagesCount = 0
        this.scrollIcon = bottomScroll > 500 || this.scrollMessagesCount
      },
      loadMoreMessages () {
        if (this.loadingMessages) return

        setTimeout(
          () => {
            if (this.loadingMoreMessages) return

            if (this.messagesLoaded || !this.roomId) {
              this.loadingMoreMessages = false
              this.showLoader = false
              return
            }

            this.preventTopScroll()
            this.$emit('fetch-messages')
            this.loadingMoreMessages = true
          },
          // prevent scroll bouncing speed
          500,
        )
      },
      messageActionHandler ({ action, message }) {
        switch (action.name) {
          case 'replyMessage':
            this.initReplyMessage = message
            setTimeout(() => {
              this.initReplyMessage = null
            })
            return
          case 'editMessage':
            this.initEditMessage = message
            setTimeout(() => {
              this.initEditMessage = null
            })
            return
          case 'deleteMessage':
            return this.$emit('delete-message', message)
          case 'selectMessages':
            this.selectedMessages = [message]
            this.messageSelectionEnabled = true
            return
          default:
            return this.$emit('message-action-handler', { action, message })
        }
      },
      messageSelectionActionHandler (action) {
        this.$emit('message-selection-action-handler', {
          action,
          messages: this.selectedMessages,
        })
        this.resetMessageSelection()
      },
      sendMessageReaction (messageReaction) {
        this.$emit('send-message-reaction', messageReaction)
      },
      getBottomScroll (element) {
        const { scrollHeight, clientHeight, scrollTop } = element
        return scrollHeight - clientHeight - scrollTop
      },
      scrollToBottomCore (timeMilis) {
        setTimeout(() => {
          const element = this.$refs.scrollContainer
          if (element) {
            element.classList.add('vac-scroll-smooth')
            element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' })
            setTimeout(() => element.classList.remove('vac-scroll-smooth'))
          }
        }, timeMilis)
      },
      scrollToBottom () {
        [50, 250, 550].forEach(timeMilis => {
          this.scrollToBottomCore(timeMilis)
        })
      },
      openFile ({ message, file }) {
        this.$emit('open-file', { message, file })
      },
      openUserTag (user) {
        this.$emit('open-user-tag', user)
      },
      onDropFiles (event) {
        if (this.showFiles) {
          this.droppedFiles = event.dataTransfer.files
        }
      },
    },
  }
</script>
