import { ChannelType } from './ChannelType'
import { Channel } from './Channel'

export class ChannelMailing extends Channel {
  id = null
  type = ChannelType.MAILING_ID
  subject = ''
  preHeader = ''
  fromEmail = ''
  fromName = ''
  replyTo = ''
  templateId = null
  certified = 0
  utmSource = ''
  utmMedium = ''
  utmTerm = ''
  utmContent = ''
  utmCampaign = ''
  templateStructure = ''
  landingType = 0
  htmlContent = ''

  /* eslint-disable camelcase */
  // { id?: number, subject: string, from_email: string, from_name: string, reply_to: string, template_id: number, ga_utm_tracking: number, utm_source: string, utm_medium: string, utm_term: string, utm_content: string, utm_campaign: string, template_structure}
  constructor (data) {
    super()
    if (data) {
      /* eslint-disable camelcase */
      this.id = data.id ? data.id : this.id
      this.subject = data.subject
      this.preHeader = data.pre_header
      this.fromEmail = data.from_email
      this.fromName = data.from_name
      this.replyTo = data.reply_to
      this.templateId = data.template_id
      this.certified = parseInt(data.certified)
      this.utmSource = data.utm_source
      this.utmMedium = data.utm_medium
      this.utmTerm = data.utm_term
      this.utmContent = data.utm_content
      this.utmCampaign = data.utm_campaign
      this.templateStructure = JSON.parse(data.template_structure)
      this.landingType = data.landing_type
      this.htmlContent = data.html_content
    }
  }
}
