var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingTop: _vm.component.paddingTop + 'px',
    paddingLeft: _vm.component.paddingLeft + 'px',
    paddingRight: _vm.component.paddingRight + 'px',
    paddingBottom: _vm.component.paddingBottom + 'px',
  })},[(_vm.component.videoPlatform == 'youtube')?_c('div',{style:({
      display: 'flex',
      alignItems: _vm.getPositionAligment(),
      justifyContent: _vm.getPositionAligment()
    }),attrs:{"height":"300"}},[_c('div',{style:({ width: _vm.component.width + '%', position: 'relative' })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.component.thumbnailUrlResource.length > 0
            ? _vm.component.thumbnailUrlResource
            : require('@/assets/img/images/grey-background.png'),"alt":"video"}}),_c('svg',{staticClass:"svg-inline--fa fa-play fa-w-14",staticStyle:{"position":"absolute","z-index":"1"},style:({
          width: 0.6 + _vm.component.width * 0.15 + '%',
          height: 'auto', color: _vm.component.buttonPlayColor,
          left: (50 - (0.6 + _vm.component.width * 0.15) / 2) + '%',
          top: (50 - (0.6 + _vm.component.width * 0.15)) + '%'
        }),attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"play","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"}})])])]):_vm._e(),(_vm.component.videoPlatform == 'tiktok')?_c('div',{style:({
      display: 'flex',
      alignItems: _vm.getPositionAligment(),
      justifyContent: _vm.getPositionAligment()
    }),attrs:{"height":"300"}},[_c('div',{style:({ width: _vm.component.width + '%', position: 'relative' })},[(!_vm.component.thumbnailUrlResource.includes('/storage/multimedia/'))?_c('iframe',{attrs:{"width":"100%","height":"760","src":'https://www.tiktok.com/embed/v2/' + _vm.component.videoUrlResource,"frameborder":"0"}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.component.thumbnailUrlResource.length > 0
            ? _vm.component.thumbnailUrlResource
            : require('@/assets/img/images/grey-background.png'),"alt":"video"}}),_c('svg',{staticClass:"svg-inline--fa fa-play fa-w-14",staticStyle:{"position":"absolute","z-index":"1"},style:({
          width: 0.6 + _vm.component.width * 0.15 + '%',
          height: 'auto', color: _vm.component.buttonPlayColor,
          left: (50 - (0.6 + _vm.component.width * 0.15) / 2) + '%',
          top: (50 - (0.6 + _vm.component.width * 0.15)) + '%'
        }),attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"play","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"}})])])]):_vm._e(),(_vm.component.videoPlatform == 'vimeo')?_c('div',{style:({
      display: 'flex',
      alignItems: _vm.getPositionAligment(),
      justifyContent: _vm.getPositionAligment()
    }),attrs:{"height":"300"}},[_c('div',{style:({ width: _vm.component.width + '%', position: 'relative' })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.component.thumbnailUrlResource.length > 0
            ? _vm.component.thumbnailUrlResource
            : require('@/assets/img/images/grey-background.png'),"alt":"video"}}),_c('svg',{staticClass:"svg-inline--fa fa-play fa-w-14",staticStyle:{"position":"absolute","z-index":"1"},style:({
          width: 0.6 + _vm.component.width * 0.15 + '%',
          height: 'auto', color: _vm.component.buttonPlayColor,
          left: (50 - (0.6 + _vm.component.width * 0.15) / 2) + '%',
          top: (50 - (0.6 + _vm.component.width * 0.15)) + '%'
        }),attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"play","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"}})])])]):_vm._e(),(_vm.component.videoPlatform == 'instagram')?_c('div',{style:({
      display: 'flex',
      alignItems: _vm.getPositionAligment(),
      justifyContent: _vm.getPositionAligment()
    }),attrs:{"height":"300"}},[_c('div',{style:({ width: _vm.component.width + '%', position: 'relative' })},[(!_vm.component.thumbnailUrlResource.includes('/storage/multimedia/'))?_c('iframe',{attrs:{"width":"100%","height":"900","src":'https://www.instagram.com/reel/' + _vm.component.videoUrlResource + '/embed/',"frameborder":"0"}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.component.thumbnailUrlResource.length > 0
            ? _vm.component.thumbnailUrlResource
            : require('@/assets/img/images/grey-background.png'),"alt":"video"}}),_c('svg',{staticClass:"svg-inline--fa fa-play fa-w-14",staticStyle:{"position":"absolute","z-index":"1"},style:({
          width: 0.6 + _vm.component.width * 0.15 + '%',
          height: 'auto', color: _vm.component.buttonPlayColor,
          left: (50 - (0.6 + _vm.component.width * 0.15) / 2) + '%',
          top: (50 - (0.6 + _vm.component.width * 0.15)) + '%'
        }),attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"play","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"}})])])]):_vm._e(),(_vm.component.videoPlatform == 'dailymotion')?_c('div',{style:({
      display: 'flex',
      alignItems: _vm.getPositionAligment(),
      justifyContent: _vm.getPositionAligment()
    }),attrs:{"height":"300"}},[_c('div',{style:({ width: _vm.component.width + '%', position: 'relative' })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.component.thumbnailUrlResource.length > 0
            ? _vm.component.thumbnailUrlResource
            : require('@/assets/img/images/grey-background.png'),"alt":"video"}}),_c('svg',{staticClass:"svg-inline--fa fa-play fa-w-14",staticStyle:{"position":"absolute","z-index":"1"},style:({
          width: 0.6 + _vm.component.width * 0.15 + '%',
          height: 'auto', color: _vm.component.buttonPlayColor,
          left: (50 - (0.6 + _vm.component.width * 0.15) / 2) + '%',
          top: (50 - (0.6 + _vm.component.width * 0.15)) + '%'
        }),attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"play","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"}})])])]):_vm._e(),(_vm.component.videoPlatform == 'twitch')?_c('div',{style:({
      display: 'flex',
      alignItems: _vm.getPositionAligment(),
      justifyContent: _vm.getPositionAligment()
    }),attrs:{"height":"300"}},[_c('div',{style:({ width: _vm.component.width + '%', position: 'relative' })},[(!_vm.component.thumbnailUrlResource.includes('/storage/multimedia/'))?_c('iframe',{attrs:{"src":'https://player.twitch.tv/?video=' + _vm.component.videoUrlResource + '&parent=localhost&autoplay=false',"width":"100%","height":"300"}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.component.thumbnailUrlResource.length > 0
            ? _vm.component.thumbnailUrlResource
            : require('@/assets/img/images/grey-background.png'),"alt":"video"}}),_c('svg',{staticClass:"svg-inline--fa fa-play fa-w-14",staticStyle:{"position":"absolute","z-index":"1"},style:({
          width: 0.6 + _vm.component.width * 0.15 + '%',
          height: 'auto', color: _vm.component.buttonPlayColor,
          left: (50 - (0.6 + _vm.component.width * 0.15) / 2) + '%',
          top: (50 - (0.6 + _vm.component.width * 0.15)) + '%'
        }),attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"play","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"}})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }