import { TypeComponent } from '../../../enums/TypeComponent.js'
import ContainerProperties from './container-properties/ContainerProperties.vue'
import ButtonProperties from './button-properties/ButtonProperties.vue'
import QrProperties from './qr-properties/QrProperties.vue'
import CancelSubscriptionProperties from './cancel-subscription-properties/CancelSubscriptionProperties.vue'
import TextProperties from './text-properties/TextProperties.vue'
import HtmlProperties from './html-properties/HtmlProperties.vue'
import ImageProperties from './image-properties/ImageProperties.vue'
import TemplateProperties from './template-properties/TemplateProperties.vue'
import SocialMediaProperties from './social-media-properties/SocialMediaProperties.vue'
import ProductProperties from './product-properties/ProductProperties.vue'
import VideoProperties from './video-properties/VideoProperties.vue'
import GifProperties from './gif-properties/GifProperties.vue'
import SeparatorProperties from './separator-properties/SeparatorProperties.vue'
import OpenInBrowserProperties from './open-in-browser-properties/OpenInBrowserProperties.vue'

export default {
  name: 'DrawProperties',
  components: {
    ContainerProperties,
    ButtonProperties,
    QrProperties,
    CancelSubscriptionProperties,
    OpenInBrowserProperties,
    TextProperties,
    HtmlProperties,
    ImageProperties,
    SocialMediaProperties,
    ProductProperties,
    VideoProperties,
    GifProperties,
    TemplateProperties,
    SeparatorProperties,
  },
  props: {
    landing: { type: Object },
  },
  data: function () {
    return {
      TypeComponent: TypeComponent,
    }
  },
  methods: {
    mounted () {
    },
  },
}
