import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import importMixin from '@/components/ContactSource/mixins/importMixin.js'
import { ChannelType } from '../../../models/ChannelType'

export default {
  name: 'ImportFile',
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  mixins: [importMixin],
  components: {
    ButtonMultimedia,
    SelectCountry,
  },
  data () {
    return {
      IMPORT_KEY: 'import',
      channelType: ChannelType
    }
  },
  methods: {
    selectFile (url) {
      this.campaign.sendings[0].csvFileConfig.databaseFile = url
      return this.sendFile()
    },
  },
}
