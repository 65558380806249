import { Service } from './service'

const PATH = 'contact-source/'

class ContactSourceService extends Service {
  sendFile (params = {}, url) {
    return this.post(PATH + url, params)
  }

  loadPreview (params = {}, url) {
    return this.post(url, params)
  }
}

export default new ContactSourceService()
