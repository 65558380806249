import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import SizeProperty from '../../common/size-property/SizeProperty.vue'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import LandingTemplateService from '@/services/landing-template.service.js'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'
import getEnv from '@/util/env'

export default {
    name: 'VideoProperties',
    components: { ButtonMultimedia, MarginProperty, SizeProperty, ColorPicker, HelpText },
    props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
  },
  data: function () {
    return {
      youtubeRegexp: /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm,
      component: { type: Object },
      loadingPreview: true,
      imageLoadError: false,
      videoUrl: '',
      activateThumbnailModel: false,
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
    if (!this.component.videoPlatform) {
      this.videoUrl = (this.component.videoUrlResource !== '') ? 'https://www.youtube.com/watch?v=' + this.component.videoUrlResource : ''
    } else {
      this.videoUrl = this.component.videoUrl
    }
  },
  methods: {
    changeThumbnail () {
      if (this.activateThumbnailModel) {
        this.component.thumbnailUrlResource = require('@/assets/img/images/grey-background.png')
      } else {
        this.extractUrl()
      }
    },
    hideLoading () {
      this.loadingPreview = false
      this.imageLoadError = false
    },
    showError () {
      this.loadingPreview = false
      this.imageLoadError = true
    },
    extractUrl () {
      if (this.videoUrl === '') {
        this.component.videoUrlResource = ''
        this.component.videoPlatform = 'youtube'
        this.setDefaultThumbnail()
        return
      }

      this.component.videoUrl = this.videoUrl
      this.getYoutubeVideo()
      this.getTiktokVideoLink()
      this.getVimeoVideo()
      this.getInstagramVideo()
      this.getDailyMotionVideo()
    },
    setThumbnail (url) {
      this.component.thumbnailUrlResource = getEnv('VUE_APP_ASSETS_URL') + url
    },
    setDefaultThumbnail () {
      this.component.thumbnailUrlResource = require('@/assets/img/images/grey-background.png')
    },
    getTiktokVideoLink () {
      const regex = /https?:\/\/(?:www\.)?tiktok\.com\/\S*\/video\/(\d+)|https?:\/\/(?:www\.)?vm.tiktok.com\/\S*\//gm
      const matchResult = this.videoUrl.matchAll(regex)
      for (const match of matchResult) {
        const videoId = match[1]
        this.component.videoUrlResource = videoId
        this.component.videoPlatform = 'tiktok'
        this.setDefaultThumbnail()
      }
    },

    getYoutubeVideo () {
      const matchResult = this.videoUrl.matchAll(this.youtubeRegexp)

      for (const match of matchResult) {
        this.component.thumbnailUrlResource = (match === null) ? require('@/assets/img/images/grey-background.png') : 'https://i.ytimg.com/vi/' + match[3] + '/hq720.jpg'
        this.component.videoUrlResource = (match === null) ? '' : match[3]
        this.component.videoPlatform = 'youtube'
      }
    },

    async getVimeoVideo () {
      const regex = /vimeo.*\/(\d+)/gm
      const matchResult = this.videoUrl.matchAll(regex)
      for (const match of matchResult) {
        const videoId = match[1]
        this.component.videoUrlResource = videoId
        this.component.videoPlatform = 'vimeo'

        LandingTemplateService.getVimeoVideoData({ videoId: videoId }).then((response) => {
          this.component.thumbnailUrlResource = response.thumbnail_large
        })
      }
    },

    getInstagramVideo () {
      const regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)\/(.*?((?=[&#?/])|$))/gm
      const matchResult = this.videoUrl.matchAll(regex)
      for (const match of matchResult) {
        const videoId = match[2]
        this.component.videoUrlResource = videoId
        this.component.videoPlatform = 'instagram'
        this.setDefaultThumbnail()
      }
    },

    getDailyMotionVideo () {
      const regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:dailymotion.com)\/(\w+)\/(.*?((?=[&#?/])))/gm
      const matchResult = this.videoUrl.matchAll(regex)
      for (const match of matchResult) {
        const videoId = match[2]
        this.component.videoUrlResource = videoId
        this.component.videoPlatform = 'dailymotion'
        this.component.thumbnailUrlResource = 'https://www.dailymotion.com/thumbnail/video/' + videoId
      }
    },

    getTwitchVideo () {
      const regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/(\w+)\/([0-9]*)/gm
      const matchResult = this.videoUrl.matchAll(regex)
      for (const match of matchResult) {
        const videoId = match[2]
        this.component.videoUrlResource = videoId
        this.component.videoPlatform = 'twitch'
      }
    },
  },
}
