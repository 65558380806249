import { AlignmentComponent } from '../enums/AlignmentComponent.js'
import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'

export class Image extends GeneralComponent {
  redirectTo = '';
  alternativeText = '';
  path = '';
  alignment = AlignmentComponent.Center;
  paddingTop = 10;
  paddingLeft = 20;
  paddingRight = 20;
  paddingBottom = 10;
  width = 100;

  constructor () {
    super(TypeComponent.Image)
  }

  getStructure () {
    return this
  }
}
