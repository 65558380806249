export class ContactsFileUploadResponse {
  success = false
  originalFilename = ''
  filePath = ''
  fileName = ''
  filePreview = []
  guessedMap = {}
  externalIdColumn = null
  totalRows = 0
  delimiter = ''
  skipHeader = 0
  headers= []
  copyCsv = false
  containsDuplicates = false

  constructor (response) {
    if (response) {
      this.success = response.success
      this.originalFilename = response.originalFilename
      this.filePath = response.filePath
      this.fileName = response.fileName
      this.filePreview = response.filePreview
      this.guessedMap = response.guessedMap
      this.externalIdColumn = response.externalIdColumn
      this.totalRows = response.totalRows
      this.delimiter = response.delimiter
      this.skipHeader = response.skipHeader
      this.headers = response.headers
      this.copyCsv = response.copyCsv
      this.containsDuplicates = response.containsDuplicates
    }
  }
}
