import ImageComponent from '../image/ImageComponent.vue'
import TextComponent from '../text/TextComponent.vue'
import ButtonComponent from '../button/ButtonComponent.vue'

export default {
  props: {
    component: { type: Object },
  },
  name: 'ProductComponent',
  components: { ImageComponent, TextComponent, ButtonComponent },
}

