import { ChannelType } from './ChannelType'
import { Channel } from './Channel'

export class ChannelSms extends Channel {
  id = null
  type = ChannelType.SMS_ID
  sender = ''
  encoding = 'GSM'
  message = ''
  certified = 0
  certified2way = 0
  flash = 0
  bookmark = 0
  templateId = null
  templateStructure = null
  attachments = []
  clickable = false

  encodingTypes = [
    'GSM',
    'GSM-PT',
    'UTF-16',
  ];

  constructor (data) {
    super()
    if (data) {
      this.id = data.id
      this.sender = data.sender
      this.encoding = data.encoding
      this.message = data.message
      this.certified = parseInt(data.certified)
      this.certified2way = parseInt(data.certified_2way)
      this.flash = parseInt(data.flash)
      this.bookmark = parseInt(data.bookmark)
      this.templateId = data.template_id
      this.templateStructure = data.template_structure ? JSON.parse(data.template_structure) : null
      this.attachments = data.attachments ? JSON.parse(data.attachments) : []
    }
  }
}
