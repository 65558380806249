export class UserInboxQuickResponses {
  id = null
  userId = null
  responseShort = ''
  responseFull = ''
  createdAt = ''
  updatedAt = ''

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.responseShort = data.response_short
    this.responseFull = data.response_full
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }
}
