export class Agent {
  id = null
  name = null
  typeId = null
  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.name = data.name
    this.typeId = data.typeId
  }
}
