import HelpText from '@/components/Layout/HelpText/HelpText.vue'

export default {
  components: { HelpText },
  name: 'MultiSelect',
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      },
    },
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemType: {
      type: String,
      default: function () {
        return this.$t('elementos')
      },
    },
    headers: {
      type: Object,
    },
    keyValue: {
      type: String,
      required: true,
    },
    onlyKeys: {
      type: Boolean,
    },
    getAllItems: {
      type: Function,
    },
  },
  data () {
    return {
      tableHeaders: [],
      allItems: [],
      selectedItems: [],
      isLoading: false,
      searchAll: '',
      searchSelected: '',
    }
  },
  watch: {
    items: {
      handler () {
        this.allItems = this.items
        this.isLoading = false
      },
      deep: true,
    },
    value: {
      handler () {
        this.selectedItems = this.value
      },
      deep: true,
    },
    selectedItems: {
      handler () {
        this.$emit('updateValues', this.selectedItems)
      },
      deep: true,
    },
  },
  mounted () {
    this.allItems = this.items
    this.selectedItems = this.value
    this.setHeaders()
  },
  methods: {
    titleCase (string) {
      return string[0].toUpperCase() + string.slice(1).toLowerCase()
    },
    onIntersect (entries, observer, isIntersecting) {
      const hasItems = this.allItems.length > 0
      const notAllItemsSeen = (this.allItems.length + this.selectedItems.length) < this.totalItems
      if (isIntersecting && hasItems && notAllItemsSeen) {
        this.isLoading = true
        this.$emit('loadMoreData')
      }
    },
    addItem (item) {
      this.selectedItems = [item, ...this.selectedItems]
      this.allItems.splice(this.allItems.indexOf(this.allItems.find(i => i[this.keyValue] === item[this.keyValue])), 1)
    },
    removeItem (item) {
      this.allItems = [item, ...this.allItems]
      this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems.find(i => i[this.keyValue] === item[this.keyValue])), 1)
    },
    async addAll () {
      if (this.getAllItems && (this.allItems.length + this.selectedItems.length) < this.totalItems) {
        await this.getAllItems()
        this.allItems = [...this.allItems.filter(i => !this.keys(this.selectedItems).includes(i[this.keyValue]))]
      }
      if (this.selectedItems.length < this.totalItems) {
        this.selectedItems = [...this.selectedItems, ...this.allItems]
      }
      this.allItems = []
    },
    removeAll () {
      this.allItems = [...this.allItems, ...this.selectedItems]
      this.selectedItems = []
    },
    setHeaders () {
      if (this.headers) {
        this.tableHeaders = Object.keys(this.headers).map(k => {
          return {
            ...this.headers[k],
            text: this.titleCase(this.headers[k].header),
            value: k,
          }
        })
      } else {
        if (this.items.length) {
          this.tableHeaders = Object.keys(this.items[0]).map(k => ({ text: this.titleCase(k), value: k }))
        }
      }
    },
    keys (arr) {
      return arr.map(i => i[this.keyValue])
    },
  },
}
