export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    async handleInput ($event) {
      this.$emit('input', $event.target.value)
    },
  },
}
