export default class ComponentStatusStore {
  sendLater
  advancedOptions
  shippingMode

  constructor (sendLater, advancedOptions, shippingMode) {
    this.sendLater = sendLater
    this.advancedOptions = advancedOptions
    this.shippingMode = shippingMode
  }
}
