import PromptService from '@/services/prompt.service'
import AiService from '@/services/ai/ai.service.js'
import EventBus from '@/util/EventBus'
import { ChannelType } from '@/models/ChannelType.js'
import AiProfileAssistant from '@/components/AiAssistant/AiProfileAssistant.vue'
import getEnv from '@/util/env'

export default {
  name: 'AiVoiceAssistant',
  components: {
    AiProfileAssistant,
  },
  props: { },
  data: function () {
    return {
      selected: [],
      prompt: '',
      ideas: [],
      latestPrompts: [],
      loadingCompletions: false,
      loadMoreCompletions: false,
      canLoadMore: false,
      drawerCtrl: {
        show: false,
      },
      aiAssistantCtrl: {
        show: false,
      },
    }
  },
  methods: {
    downloadGuide () {
      const locale = this.$i18n.locale === 'es' ? 'es' : 'en'
      const urlAiGuide = getEnv('VUE_APP_API_URL') + 'docs/help/' + locale + '/guia-nia.pdf'
      window.open(urlAiGuide, '_blank')
    },
    sendIdea (idea) {
      EventBus.$emit('AiVoiceSmsAssistant:sendIdea', idea)
      this.closeDrawer()
    },

    closeDrawer () {
      this.drawerCtrl.show = false
    },

    getVoiceSmsCompletions () {
      this.loadingCompletions = true
      AiService.getVoiceSmsCompletions({ prompt: this.prompt }).then((ideas) => {
        if (ideas === false) {
          EventBus.$emit('showAlert', 'warning', this.$t('Has llegado al límite de peticiones diarias. Vuelve a intentarlo mañana.').toString())
        } else {
          ideas.forEach(idea => {
            this.ideas.unshift(idea)
          })
        }
        this.savePrompt()
        this.loadingCompletions = false
      })
    },

    savePrompt () {
      PromptService.savePrompt({ prompt: this.prompt, channel: ChannelType.AI_VOICE_SMS_ID }).then(() => {
        this.getLatestPrompts()
      })
    },

    getLatestPrompts () {
      PromptService.getLatestPrompts({ channel: ChannelType.AI_VOICE_SMS_ID }).then((prompts) => {
        this.latestPrompts = prompts
      })
    },
  },
  computed: {

  },
  created () {

  },
  mounted () {
    this.getLatestPrompts()
    EventBus.$on('AiVoiceSmsAssistant:open', () => {
      this.drawerCtrl.show = true
    })
  },
  destroyed () {

  },
}
