export default {
  name: 'CampaignModalExit',
  props: {
    showCampaignExit: {
      type: Boolean,
      required: true,
      default: false,
    },
    campaignName: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: function () {
    return {
      loadingSaveCampaign: false,
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    emitNoSave () {
      this.$emit('noSave')
    },
    emitSave () {
      this.$emit('save')
    },
  },
}
