var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingTop: _vm.component.paddingTop + 'px',
    paddingLeft: _vm.component.paddingLeft + 'px',
    paddingRight: _vm.component.paddingRight + 'px',
    paddingBottom: _vm.component.paddingBottom + 'px',
  })},[_c('div',{style:({
      textAlign: _vm.component.alignment,
    })},[(_vm.component.titleCode)?_c('div',{staticClass:"mb-2"},[_c('p',{style:({ color: _vm.component.titleColor })},[_vm._v(" "+_vm._s(_vm.component.title)+" ")])]):_vm._e(),_c('img',{staticClass:"img-barcode",style:({ width: _vm.component.width + '%'}),attrs:{"src":_vm.component.img,"alt":"Code"}}),(_vm.component.publicCode)?_c('div',{staticClass:"mt-2"},[_c('p',{style:({ color: _vm.component.colorCode })},[_vm._v(" "+_vm._s(_vm.component.code)+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }