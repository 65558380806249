import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import getEnv from '@/util/env'

export default {
  name: 'TemplateProperties',
  components: { ButtonMultimedia, ColorPicker },
  props: {
    landing: { type: Object },
  },
  data () {
    return {
      loadingPreview: true,
      imageLoadError: false,
      typeBackgroundSelected: {
        color: 'color',
        image: 'image',
      },
    }
  },
  methods: {
    setThumbnail (url) {
      this.landing.structure.backgroundPath = getEnv('VUE_APP_ASSETS_URL') + url
    },
    showLoading () {
      this.loadingPreview = true
      this.imageLoadError = false
    },
    hideLoading () {
      this.loadingPreview = false
      this.imageLoadError = false
    },
    showError () {
      this.loadingPreview = false
      this.imageLoadError = true
    },
    removeImageUrl () {
      this.landing.structure.backgroundPath = ''
    },
  },
}
