import { TypeComponent } from '../enums/TypeComponent.js'
import { GeneralComponent } from './GeneralComponent'

export class Separator extends GeneralComponent {
  showLine = true;
  paddingTop = 2;
  paddingBottom = 2;

  constructor () {
    super(TypeComponent.Separator)
  }

  getStructure () {
    return this
  }
}
