import EventBus from '@/util/EventBus.js'

export default {
  name: 'CampaignPreview',
  props: ['formInvalid', 'landing', 'landingTemplate', 'fields', 'campaign'],
  methods: {
    showOverlayEditor () {
        EventBus.$emit('smsCampaginShowingLanding', true)
        EventBus.$emit('showOverlayEditor')
    },
    showOverlayPreview () {
        EventBus.$emit('showOverlayPreview')
    },
    sendTestEvent () {
      EventBus.$emit('send-test-event')
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    getHtmlIframeEncoded () {
      return 'data:text/html;charset=utf-8,' + encodeURIComponent(this.landingTemplate.htmlContent)
    },
  },
}
