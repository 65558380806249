export class CsvFileConfig {
  databaseFile = ''
  originalFilename = ''
  filePath = ''
  countryIso = ''
  delimiter = ';'
  primaryColumn = 0
  skipHeader = 0
  externalIdColumn = 0

  constructor (file) {
    if (file) {
      this.databaseFile = file.database_file.split('/')[file.database_file.split('/').length - 1]
      this.originalFilename = file.original_filename
      this.filePath = file.database_file
      this.countryIso = file.country_iso
      this.delimiter = file.delimiter
      this.primaryColumn = file.primary_column
      this.skipHeader = file.skip_header
      this.externalIdColumn = (file.external_id_column === null) ? file.primary_column : file.external_id_column
    }
  }

  setCountry (countryIso) {
    this.countryIso = countryIso
  }
}
