import { render, staticRenderFns } from "./HtmlProperties.vue?vue&type=template&id=411fabf2&scoped=true&"
import script from "./HtmlProperties.vue.js?vue&type=script&lang=js&"
export * from "./HtmlProperties.vue.js?vue&type=script&lang=js&"
import style1 from "./HtmlProperties.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411fabf2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
