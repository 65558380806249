export const FontTypes = {
  Arial: 'Arial',
  CourierNew: 'Courier New',
  Georgia: 'Georgia',
  Tahoma: 'Tahoma',
  TimesNewRoman: 'Times New Roman',
  TrebuchetMS: 'Trebuchet MS',
  Verdana: 'Verdana',
  Helvetica: 'Helvetica',
}
