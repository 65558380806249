var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-component position-relative",style:({
    paddingTop: _vm.container.paddingTop + 'px',
    paddingBottom: _vm.container.paddingBottom + 'px',
  }),on:{"click":function($event){$event.stopPropagation();return _vm.viewContainerProperties($event)}}},[(_vm.isRemovable)?_c('v-btn',{staticClass:"fixed-remove-button mr-n1",attrs:{"icon":"","x-small":"","color":"red","title":_vm.$t('Borrar bloque')},on:{"click":function($event){$event.stopPropagation();return _vm.removeContainer($event)}}},[_c('v-icon',{staticStyle:{"background-color":"white","border-radius":"50%"}},[_vm._v(" fas fa-times-circle ")])],1):_vm._e(),(_vm.isDuplicable)?_c('span',{staticClass:"fas fa-clone fa-lg duplicate-button",attrs:{"title":_vm.$t('Duplicar bloque')},on:{"click":function($event){$event.stopPropagation();return _vm.duplicateContainer($event)}}}):_vm._e(),_c('div',{class:{
      'selected-component':
        _vm.containerIndex ==
        _vm.landing.editorHelper.componentSelected.containerIndex &&
        _vm.landing.editorHelper.componentSelected.slotIndex == -1,
    }},[_c('table',{style:(_vm.getbackgroundType())},[_c('tr',_vm._l((_vm.container.slots),function(slot,i){return _c('td',{key:i,staticClass:"position-relative",class:[
            _vm.landing.editorHelper.tabSelected != 'components'
              ? 'show-border-guide'
              : '' ],style:({ width: _vm.calculateWidthColumns(i) + '%' })},[_vm._l((slot),function(component){return _c('draw-component',{key:component.id,attrs:{"landing":_vm.landing,"container-index":_vm.containerIndex,"slot-index":i,"cancel-slots":_vm.cancelSlots}})}),(slot.length == 0)?_c('div',{staticClass:"empty-container",on:{"dragover":function($event){$event.preventDefault();return _vm.componentDragOver($event)},"dragleave":function($event){$event.preventDefault();return _vm.componentDragLeave($event)},"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.componentDrop(i, $event)}}},[_c('p',{staticClass:"empty-component-message",class:{ 'dropzone-active': _vm.activeDropzone }},[_vm._v(" "+_vm._s(_vm.$t("Soltar contenido aquí"))+" ")])]):_vm._e()],2)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }