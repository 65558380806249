export default class TextOnly {
  name
  body
  footer
  characters
  maxCharacters
  chunks

  constructor () {
    this.name = ''
    this.body = ''
    this.footer = ''
    this.characters = 0
    this.maxCharacters = 1024
    this.chunks = 1
  }

  loadData (data) {
    if (data) {
      this.name = data.name
      this.body = data.body
      this.footer = data.footer
    }
  }
}
