import Key from '@/components/Template/Voice/Key/Key.vue'
import { AvailableKeys } from '@/models/voice/AvailableKeys'
import { Menu } from '@/models/voice/Menu'
import { MenuOption } from '@/models/voice/MenuOption'
import EventBus from '@/util/EventBus.js'

export default {
  props: ['template', 'preview'],
  name: 'PreviewPhone',
  components: {
    Key,
  },
  data: function () {
    return {
      availableKeys: new AvailableKeys(),
      currentMenuOption: new MenuOption(),
      menu: new Menu(),
    }
  },
  methods: {
    sendTestEvent () {
      EventBus.$emit('send-test-event')
    },
  },
  created () {
    if (this.template && this.template.content) {
      this.menu.setData(JSON.parse(this.template.content))
      this.menu.menuOptions.forEach(menuOption => {
        this.availableKeys.removeKey(menuOption.key)
      })
    }
  },
  mounted () {
  },
}
