export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      changeRecipientsSource: this.value,
    }
  },
  watch: {
    value (nv) {
      this.changeRecipientsSource = nv
    },
    changeRecipientsSource (nv) {
      this.$emit('input', this.changeRecipientsSource)
    },
  },
  methods: {
    closeDialog () {
      this.$emit('cancel')
      this.changeRecipientsSource = false
    },
    cancelChangeRecipientsSource () {
      this.$emit('cancel')
      this.changeRecipientsSource = false
    },
    confirmChangeRecipientsSource () {
      this.$emit('confirm')
      this.changeRecipientsSource = false
    },
  },
}
